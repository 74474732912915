import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { DownloadService } from 'src/app/api/services/download.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';

@Component({
  selector: 'app-accordion-detail',
  templateUrl: './accordion-detail.component.html',
  styleUrls: ['./accordion-detail.component.scss'],
})
export class AccordionDetailComponent implements OnInit {
  isAccordionOpen: any;
  @Input() indexFromParent: any;
  @Input() data;

  accordions: boolean[];
  groups = [];
  dataMap;

  constructor(public downloadService: DownloadService) { }

  ngOnInit(): void {
    let list = this.data.map((d) => {
      return {
        ...d,
        year: d.DataIns ? moment(d.DataIns).format('yyyy') : '',
      };
    });
    //Individuiamo i raggruppamenti
    // se esce un doppio 2023 mettere groups vuoto
    let groups = [
      moment(Date()).format('yyyy'),
      moment(Date()).add(-1, 'years').format('yyyy'),
      moment(Date()).add(-2, 'years').format('yyyy'),
      moment(Date()).add(-3, 'years').format('yyyy')];
    list.forEach((el) => {
      if (!groups.includes(el.year)) {
        groups.push(el.year);
      }
    });
    //Creiamo l'oggetto che ha per chiave la discriminante del gruppo
    //e per valore tutti gli elementi che appartengono a quel gruppo
    let map = {};
    groups.forEach((value) => {
      map[value] = list.filter((el) => el.year === value);
    });

    this.groups = groups;

    this.accordions = new Array(
      this.groups.length
    ).fill(false);

    this.dataMap = map;

  }


  downloadFile(element) {
    const postType = DocumentPostType.GENERICS;
    const postID = element.ID_Generico;
    const parentCodeDoc = element.CodicePadreDocumento;
    const url = parentCodeDoc
      ? this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      parentCodeDoc +
      '/' +
      postID
      : this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;

    this.downloadService.downloadBlob(
      element.NomeDocumentoFile ? element.NomeDocumentoFile : element.Filename,
      url
    );
  }
}
