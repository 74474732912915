import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-head',
  templateUrl: './main-head.component.html',
  styleUrls: ['./main-head.component.scss'],
})
export class MainHeadComponent implements OnInit, OnChanges {
  // TODO: app-detail-head has showActions to integrate here

  searchText = '';
  tempSearchText = '';
  years = [];
  yearsLength = 10;
  selectedYear = '';
  selectedTempYear = '';

  @Input() isPermalink = false;
  @Input() mainTitle = '';
  @Input() textPlaceholderSearch = '';
  @Input() rightListPanelText = '';
  @Input() rightAllPanelText = '';
  @Input() isSearchRequired = false;
  @Input() isSearchFilterRequired = false;
  @Input() breadcrumbItems: [];
  @Input() bodyList: [];
  @Input() routerLinkText = '';
  @Input() redirectText = '';
  @Input() isRightPanelVisible = false;
  @Input() typology = '';
  @Input() showNumber = false;

  @Output() externalSearchEvent = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.setYears();
  }
  ngOnChanges() {}

  setYears() {
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear.toString());
    for (let i = 1; i < this.yearsLength; i++) {
      this.years.push((currentYear - i).toString());
    }
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
  navigateTo(routes) {
    this.router.navigate(routes);
  }

  search(event) {
    this.searchText = event;
    this.externalSearchEvent.emit(this.searchText);
  }

  goToDetail(data) {
    if (data.ID !== 1 && data.ID !== 158) {
      this.router.navigateByUrl('/company/transparent-administration', {
        state: data,
      });
    } else if (data.ID === 1) {
      this.router.navigate(['/resolutions/delibere'], {
        queryParams: { years: true, type: 0 },
        skipLocationChange: true,
      });
    } else {
      this.router.navigate(['/resolutions/determine'], {
        queryParams: { years: true, type: 1 },
        skipLocationChange: true,
      });
    }
  }

  resetFilter() {
    this.searchText = '';
    this.tempSearchText = '';
    this.selectedTempYear = '';
    this.selectedYear = '';
    this.doSearch();
  }

  // * Works properly only for Transparent Administration Detail *
  doSearch() {
    this.searchText = this.tempSearchText.trim();
    this.selectedYear = this.selectedTempYear;
    this.externalSearchEvent.emit({
      text: this.searchText,
      year: this.selectedYear,
    });
  }
}
