import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewsService } from 'src/app/api/services';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PermalinksService } from 'src/app/api/services/permalinks.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  searchText = '';
  constructor(
    private permalinksService: PermalinksService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService
  ) {}

  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NOTICE_BOARDS.NEWS', ''),
  ];

  elementPerPage = window.screen.width > 1200 ? 15 : 5;
  currentPage = 1;
  count: number;
  pagesNumber: number;
  loaderPage = false;
  limitPagination = true;
  newsList = [];
  searchNewsList = [];
  evidencedNotices = [];
  headNewsList = [];

  ngOnInit(): void {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.getNewsWithPermalinks(this.currentPage, this.elementPerPage, {
      post_status: 'publish',
    }).subscribe(
      (res) => {
        this.count = res.body.rowCount;
        this.limitPagination = !(this.count < this.elementPerPage);
        this.pagesNumber =
          Math.floor(this.count / this.elementPerPage) +
          (this.count % this.elementPerPage === 0 ? 0 : 1);
        res.body.rows.forEach((resource) => {
          resource.post_image_url =
            PermalinksService.attachmentPermalinkPath +
            'attachments/' +
            resource.post_featured_media;
        });
        this.newsList = res.body.rows;
        this.searchNewsList = res.body.rows;
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.getNewsWithPermalinks(1, 3, {
      only_preferred: true,
      post_status: 'publish',
    }).subscribe(
      (res) => {
        res.body.rows.forEach((resource) => {
          resource.post_image_url =
            PermalinksService.attachmentPermalinkPath +
            'attachments/' +
            resource.post_featured_media;
        });
        this.evidencedNotices = res.body.rows;
        this.headNewsList = this.evidencedNotices;
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
  }

  onPageSelect(page: number) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.currentPage = page;
    this.getNewsWithPermalinks(this.currentPage, this.elementPerPage, {
      post_status: 'publish',
    }).subscribe(
      (res) => {
        this.newsList = res.body.rows;
        res.body.rows.forEach((resource) => {
          resource.post_image_url =
            PermalinksService.attachmentPermalinkPath +
            'attachments/' +
            resource.post_featured_media;
        });
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
  }

  updateList(value: number) {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.currentPage = value;
    this.onPageSelect(this.currentPage);
  }

  getNewsWithPermalinks(
    page?,
    limit?,
    body?: {
      only_preferred?: boolean;
      categories?: string[];
      post_status?: string;
    }
  ) {
    return this.permalinksService.postPermanentLinksSearch$Response(
      'posts',
      { page: page, limit: limit },
      body
    );
  }

  searchExt(event) {
    this.searchText = event;
    this.filterItem(event);
  }

  filterItem(value) {
    this.currentPage = 1;
    this.evidencedNotices = [];
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.getNewsWithPermalinks(null, null, {
      post_status: 'publish',
    }).subscribe(
      (res) => {
        this.count = res.body.rowCount;
        this.limitPagination = !(this.count < this.elementPerPage);
        this.pagesNumber =
          Math.floor(this.count / this.elementPerPage) +
          (this.count % this.elementPerPage === 0 ? 0 : 1);
        res.body.rows.forEach((resource) => {
          resource.post_image_url =
            PermalinksService.attachmentPermalinkPath +
            'attachments/' +
            resource.post_featured_media;
        });
        this.newsList = res.body.rows;
        this.searchNewsList = res.body.rows;
        this.loaderPage = false;

        // * Filter Search
        if (value) {
          this.newsList = Object.assign([], this.searchNewsList).filter(
            (item) =>
              item.post_title.toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        }
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
  }
}
