<input
  type="file"
  hidden
  class="file-input"
  [accept]="getAcceptedFile()"
  (change)="onChange(fileInput.files[0], fileInput)"
  #fileInput
/>

<button
  [disabled]="n_uploaded_files >= 2"
  (click)="fileInput.click()"
  class="btn btn-primary"
>
  {{ buttonText | translate }}
</button>
<p class="subtitle label-accepted-files" [class.error-type]="typeError">
  {{
    singleImageUpload
      ? "File accettati: PNG, JPEG, o JPG"
      : "File accettati: PNG, JPEG, JPG, PDF, CSV, XLSX"
  }}
</p>

<div class="col-126">
  <!--start card-->
  <div class="card-wrapper">
    <div class="card-wrapper" *ngFor="let img of imageUrl; let i = index">
      <a class="card card-img no-after">
        <div class="img-responsive-wrapper">
          <div class="img-responsive">
            <figure class="img-wrapper">
              <img class="uploaded-img" [src]="img" alt="upload" />
            </figure>
          </div>
        </div>
        <div class="card-body">
          <div class="head-tags">
            <span class="data">
              {{ fileName[i] || "" }}
              <svg-icon-sprite
                style="cursor: pointer"
                class="icon-danger"
                (click)="removeImage(img)"
                classes="icon"
                src="../../../assets/sprite.svg#it-delete"
              >
              </svg-icon-sprite>
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
