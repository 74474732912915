import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-URP-body-cell',
  templateUrl: './URP_body_cell.component.html',
  styleUrls: ['./URP_body_cell.component.scss'],
})
export class URPBodyCellComponent implements OnInit {
  constructor() {}
  imagePath;
  @Input() data: any;
  @Input() title: string;
  @Input() phoneNumber: string;
  @Input() email: string;

  ngOnInit(): void {}
  onImageError() {
    this.imagePath = '../../../assets/Unknown_person.jpg';
  }
}
