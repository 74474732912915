import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input() textPlaceholder = '';
  @Input() visible = false;
  @Input() selectedTempYear = '';
  @Input() tempSearchText = '';
  @Input() tempSearchNumber = '';
  @Input() showNumber = false;
  @Input() showYear = false;
  @Input() years = [];
  @Output() doSearch = new EventEmitter();
  @Output() resetFilter = new EventEmitter();
  @Output() selectedTempYearChange = new EventEmitter();
  @Output() tempSearchTextChange = new EventEmitter();
  @Output() tempSearchNumberChange = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    document.getElementById('search-filter').addEventListener('input', (e: any) => {
      if (e.target.id === 'input-search-filter' && e.target.value.trim().length === 0) {
        this.search();
      }
    });
  }

  search() {
    this.selectedTempYearChange.emit(this.selectedTempYear);
    this.tempSearchTextChange.emit(this.tempSearchText);
    if (this.showNumber) {
      this.tempSearchNumberChange.emit(this.tempSearchNumber.toString());
    }
    this.doSearch.emit();
  }

  reset() {
    this.selectedTempYear = '';
    this.tempSearchText = '';
    this.tempSearchNumber = '';
    this.selectedTempYearChange.emit(this.selectedTempYear);
    this.tempSearchTextChange.emit(this.tempSearchText);
    this.tempSearchNumberChange.emit(this.tempSearchNumber);
    this.resetFilter.emit();
  }


}
