import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentsService } from 'src/app/api/services';
import { CompanyService } from 'src/app/api/services/company.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { departmentResponseType1 } from 'src/app/shared/messageType';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss', '../../shared/sharedStyle.scss'],
})
export class CompanyComponent implements OnInit {
  loaderPage = false;
  constructor(
    private cs: CompanyService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService
  ) {}

  companyResponse: any[];

  cardsArray: {
    role: string;
    name: string;
    email: string;
    pec: string;
    phone_number: string;
  }[] = [
    {
      role: 'Direttore Generale',
      name: 'Dr. Ferdinando Russo',
      email: 'direzione.generale@policliniconapoli.it',
      pec: 'policlinico.napoli@pec.it',
      phone_number: '+081 5667115',
    },
    {
      role: 'Direttore Sanitario',
      name: 'Dr. Pasquale Di Girolamo Faraone',
      email: 'direzione.sanitaria@policliniconapoli.it',
      pec: '',
      phone_number: '+081 5666945',
    },
    {
      role: 'Direttore Amministrativo',
      name: 'Dr. Salvatore Guetta',
      email: 'direzione.amministrativa@policliniconapoli.it',
      pec: '',
      phone_number: '+081 5666905',
    },
  ];

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.cs.companyManagementSearch({ published: '1' }).subscribe(
      (res) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.companyResponse = res.rows;
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: function () {}.bind(this),
        });
      }
    );
  }
}
