export enum DocumentPostType {
  RESOLUTIONS = 'resolutions',
  RISK_MANAGER = 'risk_manager',
  GENERICS = 'generics',
  PRIVACY = 'privacy',
  MANUALE_QUALITA = 'manual_qa',
  ATTI_NOT = 'atti_not',
  CIO_COM = 'cio_com',
  PROCAZIENDALI = 'prod_az',
  RLS = 'rls',
  COM_ETIC = 'com_etic',
  CAM_PANCREAS = 'cam_pancreas',
  NOTICES = 'notices',
  WHISTLEBLOWING = 'whistleblowing',
}
