/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { resolutionResponseType1 } from 'src/app/shared/resolutionType';


@Injectable({
  providedIn: 'root',
})
export class ResolutionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postSearchResolutionsDetails
   */
  static readonly PostSearchResolutionsDetailsPath = '/api/v1/resolutions/_search';
  static readonly PostSearchTitleResolutionsDetailsPath = '/api/v1/resolutions/title_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchResolutionsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchResolutionsDetails$Response(body?: {
    type?: number;
    published?: string;
    filter: string;
    order: string;
  }, params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<{ 'id'?: number, 'numero'?: number, 'dataemissione'?: string, 'titolo'?: string, 'descrizione'?: string, 'relatori'?: string, 'file1'?: string, 'datainizio'?: string, 'datafine'?: string, 'stato'?: number, 'internetview'?: number, 'filename'?: string, 'tipo'?: string, 'file2'?: string }> }>> {

    const rb = new RequestBuilder(this.rootUrl, ResolutionsService.PostSearchResolutionsDetailsPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});

    }
    if (body) {
      rb.body(body, 'application/json');
    }



    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<{ 'id'?: number, 'numero'?: number, 'dataemissione'?: string, 'titolo'?: string, 'descrizione'?: string, 'relatori'?: string, 'file1'?: string, 'datainizio'?: string, 'datafine'?: string, 'stato'?: number, 'internetview'?: number, 'filename'?: string, 'tipo'?: string, 'file2'?: string, 'ID_Delibera': string }> }>;
      })
    );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchResolutionsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchResolutionsDetails(body?: {
    type?: number;
    published?: string;
    filter: string;
    order: string;
    datainizio?: string,
    datafine?: string,
    titolo?: string,
  }, params?: {
    page?: number;
    limit?: number;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }> {

    return this.postSearchResolutionsDetails$Response(body, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> })
    );
  }


  postSearchTitleResolutionsDetails$Response(body?: {
    type?: number,
    published?: string;
    titolo?: string;
  }, params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<{ 'id'?: number, 'numero'?: number, 'dataemissione'?: string, 'titolo'?: string, 'descrizione'?: string, 'relatori'?: string, 'file1'?: string, 'datainizio'?: string, 'datafine'?: string, 'stato'?: number, 'internetview'?: number, 'filename'?: string, 'tipo'?: string, 'file2'?: string }> }>> {

    const rb = new RequestBuilder(this.rootUrl, ResolutionsService.PostSearchTitleResolutionsDetailsPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});

    }
    if (body) {
      rb.body(body, 'application/json');
    }



    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<{ 'id'?: number, 'numero'?: number, 'dataemissione'?: string, 'titolo'?: string, 'descrizione'?: string, 'relatori'?: string, 'file1'?: string, 'datainizio'?: string, 'datafine'?: string, 'stato'?: number, 'internetview'?: number, 'filename'?: string, 'tipo'?: string, 'file2'?: string, 'ID_Delibera': string }> }>;
      })
    );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchResolutionsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchTitleResolutionsDetails(body?: {
    type?: number;
    published?: string;
    titolo: string;
  }, params?: {
    page?: number;
    limit?: number;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }> {

    return this.postSearchTitleResolutionsDetails$Response(body, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> })
    );
  }

  /**
   * Path part for operation getResolutionsDetailsById
   */
  static readonly GetResolutionsDetailsByIdPath = '/api/v1/resolutions/{resolution_id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResolutionsDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolutionsDetailsById$Response(params: {

    /**
     * Internal ID for identification.
     */
    resolution_id: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }>> {

    const rb = new RequestBuilder(this.rootUrl, ResolutionsService.GetResolutionsDetailsByIdPath, 'get');
    if (params) {
      rb.path('resolution_id', params.resolution_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }>;
      })
    );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResolutionsDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResolutionsDetailsById(params: {

    /**
     * Internal ID for identification.
     */
    resolution_id: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }> {

    return this.getResolutionsDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<resolutionResponseType1> })
    );
  }

}
