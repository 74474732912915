import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter, } from 'rxjs/operators';
import { puntoRitiroType } from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root'
})
export class PuntiRitiroService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation PostPuntoRitiroPath
   */
  static readonly PostPuntoRitiroPath = '/api/v1/punti_ritiro';

  /**

  */
  PostPuntoRitiroDetails$Response(): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<puntoRitiroType> }>> {

    const rb = new RequestBuilder(this.rootUrl, PuntiRitiroService.PostPuntoRitiroPath, 'post');

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<puntoRitiroType> }>;
      }),

    );
  }

  /**

   */
  PostPuntoRitiroDetails(): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<puntoRitiroType> }> {

    return this.PostPuntoRitiroDetails$Response().pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<puntoRitiroType> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<puntoRitiroType> })
    );
  }


  /**
 * Path part for operation PostEmailSendPath
 */
  static readonly PostEmailSendPath = '/api/v1/email_send';

  /**
 
  */
  PostEmailSend$Response(body): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, PuntiRitiroService.PostEmailSendPath, 'post');
    if (body) {
      rb.body(body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  PostEmailSend(body): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostEmailSend$Response(body).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }
}


