<div class="ranking_Card divisor">
  <div class="ranking_Card_Top">
    <img style="width: 1.9375em;" src="../../../assets/file.svg" alt="file">
    <p *ngIf="data" role="region" aria-label="NOTICES.VALID_FROM" class="category-name">{{"NOTICES.VALID_FROM" | translate}}
      {{data.iniziovalidita.substr(8,2)}}-{{data.iniziovalidita.substr(5,2)}}-{{data.iniziovalidita.substr(0,4)}}</p>
  </div>
  <p *ngIf="data" role="region" aria-label="data.titolo" class="title">{{data.titolo}}</p>
  <p *ngIf="data" role="region" aria-label="{data.testolungo" class="abstract">{{data.testolungo}}</p>
  <a *ngIf="data" role="navigation" aria-label="NOTICES.GO_TO_PAGE" [routerLink]="[ routeLinkText,this.data.ID]" class="btn leggi-di-piu">{{"NOTICES.GO_TO_PAGE" |
    translate}}</a>
  <div class="divider_Bottom"></div>
</div>