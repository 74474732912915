import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loader: boolean = false;
  loadingStatus = new BehaviorSubject(this._loader);

  constructor() {
  }

  getloader(): boolean {
    return this._loader;
  }

  setloader(value) {
    this._loader = value;
    this.loadingStatus.next(value);
  }

}
