<div class="transparent_administration_evidence_Card">
    <p class="category">{{ "" | uppercase }}</p> <!-- TODO: CATEGORIA-->
    <div class="bg">

        <div class="position">
            <img src="assets\ic-bookmark.svg" alt="bookmark">
        </div>
    </div>
    <!-- <p class="title">{{ data.ORG_DES }}</p> -->
    <p class="title">{{ data.Nome }}</p>
    <a (click)="goToDetail()" class="btn vai-alla-pagina">{{"BUTTON.READ_MORE" |
        translate}}</a>
</div>
<div class="bottom-line">

</div>