import { Component, Input, OnInit } from '@angular/core';
import { DepartmentsService } from 'src/app/api/services';
import { departmentResponseType1 } from 'src/app/shared/messageType';
import { IS_MOBILE } from 'src/app/shared/models/constants';

@Component({
  selector: 'app-home-departments',
  templateUrl: './home_departments.component.html',
  styleUrls: ['./home_departments.component.scss']
})
export class HomeDepartmentsComponent implements OnInit {
  departmentService: any;

  constructor() { }

  @Input() departmentsList: departmentResponseType1[] = []
  mobile = IS_MOBILE;

  ngOnInit(): void {

  }

}
