import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PermalinksService } from 'src/app/api/services/permalinks.service';
import { PermalinksResponseType } from 'src/app/shared/messageType';
import { concatMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/api/services/loader.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news_detail.component.html',
  styleUrls: ['./news_detail.component.scss', '../../../shared/sharedStyle.scss'],
})
export class NewsDetailComponent implements OnInit {
  permalink: string;
  breadcrumbItems: BreadcrumbItem[];
  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private router: Router,
    private permalinkService: PermalinksService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      if (param && param.get('permalink')) {
        this.route.url.subscribe((value) => {
          if (value && value[0]) {
            this.permalink = '/' + value[0].path + '/' + param.get('permalink');
          }
        });
      }
    });
  }

  detailInformation: PermalinksResponseType;
  loaderPage = false;

  ngOnInit(): void {
    this.breadcrumbItems = [
      new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
      new BreadcrumbItem('NAVBAR_TAB.NEWS', '/news'),
    ];

    this.loadNews();
  }

  loadNews() {
    if (this.permalink) {
      this.loaderPage = true;
      this.loaderService.setloader(this.loaderPage);
      this.permalinkService
        .postPermanentLinksResolve$Response({ permalink: this.permalink })
        .pipe(
          concatMap((res) =>
            this.permalinkService.getPermalinkPostByTypeAndId$Response({
              post_type: 'posts',
              post_id: res.body.rows[0].post_id,
            })
          )
        )
        .subscribe(
          (res) => {
            this.detailInformation = res.body.rows[0];
            this.detailInformation.post_image_url =
              PermalinksService.attachmentPermalinkPath +
              'attachments/' +
              this.detailInformation.post_featured_media;
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.breadcrumbItems.push(
              new BreadcrumbItem(this.detailInformation.post_title, '')
            );
          },
          (err) => {
            if (err.status !== 404) {
              console.error(err);
            }
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.router.navigateByUrl('/news');
          }
        );
    }
  }
}
