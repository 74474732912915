<!-- HEAD -->
<app-main-head
  [breadcrumbItems]="breadcrumbItems"
  [isSearchRequired]="false"
  [mainTitle]="daiInfo.TITLE || daiInfo.ORG_DES"
  [isRightPanelVisible]="false"
>
</app-main-head>
<!-- END HEAD -->

<div class="shared_detail_body" *ngIf="detailInformation">
  <div class="shared_index_column">
    <nav class="">
      <div>
        <div class="menu-wrapper">
          <div class="link-list-wrapper">
            <p class="shared_index_column_title">
              {{ "DEPARTMENTS_DETAIL.PAGE_INDEX" | translate }}
            </p>
            <ul class="tertiary link-list">
              <li class="nav-item">
                <div>
                  <a
                    *ngFor="let element of detailInformation.sections"
                    class="nav-link"
                    (click)="redirectTo(element.title)"
                  >
                    <p class="shared_index_column_element">
                      {{ element.title }}
                    </p>
                  </a>

                  <a
                    *ngFor="let element of detailInformation.referenceSections"
                    class="nav-link"
                    (click)="redirectTo(element.title)"
                  >
                    <p class="shared_index_column_element">
                      {{ element.title }}
                    </p>
                  </a>

                  <a
                    *ngIf="
                      detailInformation.afferentLabs &&
                      detailInformation.afferentLabs.length > 0
                    "
                    class="nav-link"
                    (click)="redirectTo('afferentLabs')"
                  >
                    <p class="shared_index_column_element">
                      Laboratori afferenti
                    </p>
                  </a>
                  <a
                    *ngIf="
                      detailInformation.afferentDiagnostic &&
                      detailInformation.afferentDiagnostic.length > 0
                    "
                    class="nav-link"
                    (click)="redirectTo('afferentDiagnostic')"
                  >
                    <p class="shared_index_column_element">
                      Diagnostiche afferenti
                    </p>
                  </a>
                  <a
                    class="nav-link"
                    (click)="
                      redirectTo('Informazioni di contatto per l’utenza')
                    "
                  >
                    <p class="shared_index_column_element">
                      Informazioni di contatto per l’utenza
                    </p>
                  </a>
                  <a class="nav-link" (click)="redirectTo('Segreteria')">
                    <p class="shared_index_column_element">Segreteria</p>
                  </a>
                  <a class="nav-link" (click)="redirectTo('Mission')">
                    <p class="shared_index_column_element">Mission</p>
                  </a>
                  <a class="nav-link" (click)="redirectTo('Performance')">
                    <p class="shared_index_column_element">Prestazioni</p>
                  </a>
                  <a
                    *ngIf="
                      detailInformation.pharmacologicalAdvice &&
                      detailInformation.pharmacologicalAdvice.length > 0
                    "
                    class="nav-link"
                    (click)="redirectTo('Consulenza farmacologica')"
                  >
                    <p class="shared_index_column_element">
                      Consulenza farmacologica
                    </p>
                  </a>
                  <a
                    class="nav-link"
                    (click)="redirectTo('Elenco Dirigenti Medici')"
                  >
                    <p class="shared_index_column_element">
                      Elenco Dirigenti Medici
                    </p>
                  </a>
                  <a
                    class="nav-link"
                    *ngIf="
                      detailInformation.healthManagers &&
                      detailInformation.healthManagers.length > 0
                    "
                    (click)="redirectTo('Elenco Dirigenti Sanitari')"
                  >
                    <p class="shared_index_column_element">
                      Elenco Dirigenti Sanitari
                    </p>
                  </a>
                  <a
                    class="nav-link"
                    *ngIf="
                      detailInformation.labTech &&
                      detailInformation.labTech.length > 0
                    "
                    (click)="redirectTo('labTech')"
                  >
                    <p class="shared_index_column_element">
                      Tecnici di laboratorio
                    </p>
                  </a>

                  <a
                    *ngFor="let element of detailInformation.otherRoles"
                    class="nav-link"
                    (click)="redirectTo(element.title)"
                  >
                    <p class="shared_index_column_element">
                      {{ element.title }}
                    </p>
                  </a>

                  <a
                    class="nav-link"
                    (click)="
                      redirectTo(
                        'Breve descrizione su attività di Ricerca e Didattica'
                      )
                    "
                  >
                    <p class="shared_index_column_element">
                      Breve descrizione su attività di Ricerca e Didattica
                    </p>
                  </a>
                </div>
                <!--
                  <a class="nav-link" (click)='redirectTo("unita")'>
                    <p class="shared_index_column_element">{{"DEPARTMENTS_DETAIL.STRUCTURES" | translate}} </p>
                  </a>
                -->
                <ul class="link-list">
                  <li class="nav-link">
                    <a class="nav-link">
                      <p class="shared_index_column_element"></p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <!-- <p class="shared_index_column_title">title</p>
          <p class="shared_index_column_element">element</p>    -->
  </div>

  <div class="shared_information_column" role="region" aria-label="articolo">
    <!-- DAI DIAGNOSTICA PER IMMAGINI, AREA CRITICA E TERAPEUTICA: UOC Farmacologia Clinica e Farmacovigilanza  -->

    <div>
      <p
        class="shared_text"
        *ngFor="
          let text of [
            'La struttura è certificata ai sensi delle UNIENISO 9001: 2015.',
            'Numero Certificato: 707SGQ06 del 04/08/2022, scadenza 05/03/2025.',
            'Settori: IAF 35-38.'
          ]
        "
      >
        {{ text }}
      </p>

      <div class="contact_Section">
        <div *ngFor="let element of detailInformation.sections">
          <p id="{{ element.title }}" class="paragraph_title_department">
            {{ element.title }}
          </p>
          <div class="shared_information_cards_container">
            <app-departments-detail-person
              [data]="element"
              [email]="element.PER_EMA"
            >
            </app-departments-detail-person>
          </div>
        </div>

        <p
          *ngIf="
            detailInformation.referenceSections &&
            detailInformation.referenceSections.length > 0
          "
          id="Referente delle sezioni"
          class="paragraph_title_department"
        >
          Referente delle sezioni
        </p>
        <div *ngFor="let element of detailInformation.referenceSections">
          <div class="shared_information_cards_container">
            <app-departments-detail-person
              [data]="element"
              [email]="element.PER_EMA"
            >
            </app-departments-detail-person>
          </div>
        </div>

        <p
          *ngIf="
            detailInformation.afferentLabs &&
            detailInformation.afferentLabs.length > 0
          "
          id="afferentLabs"
          class="paragraph_title_department"
        >
          Laboratori afferenti
        </p>
        <div class="shared_information_cards_container">
          <app-info-card
            *ngFor="let lab of detailInformation.afferentLabs"
            [title]="lab"
            [minHeight]="'180px'"
          >
          </app-info-card>
        </div>

        <p
          id="afferentDiagnostic"
          *ngIf="
            detailInformation.afferentDiagnostic &&
            detailInformation.afferentDiagnostic.length > 0
          "
          class="paragraph_title_department"
        >
          Diagnostiche afferenti
        </p>
        <div
          class="container-fluid book-container"
          *ngFor="let aff of detailInformation.afferentDiagnostic"
          style="margin: 2.5% 0"
        >
          <div class="card card-bg">
            <div class="card-body">
              <p id="{{ aff.title }}" class="paragraph_title_department">
                {{ aff.title }}
              </p>

              <a
                href="{{ aff.linkPlace }}"
                target="_blank"
                class="title-with-icon"
                style="display: flex"
              >
                <h5
                  class="card-title"
                  style="
                    display: flex;
                    flex-direction: column-reverse;
                    font-size: 1em;
                  "
                >
                  {{ aff.place }}
                </h5>
              </a>
              <p class="card-text mt-10" *ngIf="aff.tel && aff.tel.length > 0">
                <b>Telefoni:</b>
              </p>
              <div class="mt-10" *ngFor="let tel of aff.tel">
                <a class="phone_number" href="tel:{{ tel.trim() }}"
                  >Tel: {{ tel }}</a
                >
              </div>
              <br />
              <p class="card-text mt-10">
                <b>Referente: </b>
              </p>
              <ul>
                <li>
                  <p class="card-text">{{ aff.referent }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 4) -->
        <p
          id="Informazioni di contatto per l’utenza"
          *ngIf="
            detailInformation.contacts && detailInformation.contacts.length > 0
          "
          class="paragraph_title_department"
        >
          Informazioni di contatto per l’utenza
        </p>
        <div
          class="container-fluid book-container"
          *ngFor="let cont of detailInformation.contacts"
          style="margin: 2.5% 0"
        >
          <div class="card card-bg">
            <div class="card-body">
              <a
                href="{{ cont.linkPlace }}"
                target="_blank"
                class="title-with-icon"
                style="display: flex"
              >
                <h5
                  class="card-title"
                  style="display: flex; flex-direction: column-reverse"
                >
                  {{ cont.place }}
                </h5>
              </a>
              <p
                class="card-text mt-10"
                *ngIf="cont.email && cont.email.length > 0"
              >
                <b>E-mail:</b>
              </p>
              <div class="mt-10" *ngFor="let mail of cont.email">
                <a href="{{ mail }}" class="email">{{ mail }}</a>
              </div>
              <p
                class="card-text mt-10"
                *ngIf="cont.tel && cont.tel.length > 0"
              >
                <b>Telefoni:</b>
              </p>
              <div class="mt-10" *ngFor="let tel of cont.tel">
                <a class="phone_number" href="tel:{{ tel.trim() }}"
                  >Tel: {{ tel }}</a
                >
              </div>

              <p
                class="card-text mt-10"
                *ngIf="cont.fax && cont.fax.length > 0"
              >
                <b>Fax:</b>
              </p>
              <div class="mt-10" *ngFor="let fax of cont.fax">
                <a class="phone_number" href="tel:{{ fax.trim() }}"
                  >Tel: {{ fax }}</a
                >
              </div>

              <div class="mt-10">
                <p class="card-text mt-10" *ngIf="cont.acceptance">
                  <b>Orari di accettazione:</b>
                </p>
                <ul *ngIf="cont.acceptance">
                  <li role="list">
                    <p class="card-text">{{ cont.acceptance }}</p>
                  </li>
                </ul>
                <p
                  class="card-text mt-10"
                  *ngIf="cont.cupReservation && cont.cupReservation.length > 0"
                >
                  <b>Orari di prenotazione al CUP: </b>
                </p>
                <ul>
                  <li *ngFor="let cup of cont.cupReservation">
                    <p class="card-text">{{ cup }}</p>
                  </li>
                </ul>

                <p
                  class="card-text mt-10"
                  *ngIf="cont.cupOpen && cont.cupOpen.length > 0"
                >
                  <b>Orari di apertura al CUP: </b>
                </p>
                <ul>
                  <li *ngFor="let cup of cont.cupOpen">
                    <p class="card-text">{{ cup }}</p>
                  </li>
                </ul>

                <p
                  class="card-text mt-10"
                  *ngIf="
                    cont.hoursAmbulatory && cont.hoursAmbulatory.length > 0
                  "
                >
                  <b>Orari ambulatorio: </b>
                </p>
                <ul>
                  <li *ngFor="let amb of cont.hoursAmbulatory">
                    <p class="card-text">{{ amb }}</p>
                  </li>
                </ul>

                <p *ngFor="let info of cont.infoList" class="card-text">
                  {{ info }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- 5) -->
        <p
          id="Segreteria"
          *ngIf="detailInformation.secretary"
          class="paragraph_title_department"
        >
          Segreteria
        </p>

        <div
          *ngIf="detailInformation.secretary"
          class="shared_information_cards_container"
        >
          <div class="card card-bg">
            <div class="card-body">
              <p class="shared_text">
                {{ detailInformation.secretary.short_text }}
              </p>
              <div class="mt-10">
                <p
                  *ngIf="
                    detailInformation.secretary.hoursGuests &&
                    detailInformation.secretary.hoursGuests.length > 0
                  "
                  class="card-text mt-10"
                >
                  <b>Apertura al pubblico:</b>
                </p>
                <ul>
                  <li
                    *ngFor="let hour of detailInformation.secretary.hoursGuests"
                  >
                    <p class="card-text">{{ hour }}</p>
                  </li>
                </ul>
                <p
                  *ngIf="
                    detailInformation.secretary.hoursReport &&
                    detailInformation.secretary.hoursReport.length > 0
                  "
                  class="card-text mt-10"
                >
                  <b>Rilascio referti: </b>
                </p>
                <ul>
                  <li
                    *ngFor="let hour of detailInformation.secretary.hoursReport"
                  >
                    <p class="card-text">{{ hour }}</p>
                  </li>
                </ul>
                <p
                  *ngIf="detailInformation.secretary.close"
                  class="card-text mt-10"
                >
                  <b>{{ detailInformation.secretary.close }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- MISSIONI -->
        <p id="Mission" class="paragraph_title_department">Mission</p>
        <p class="shared_text">
          {{ detailInformation.mission.short_desc }}
        </p>
        <ul
          *ngIf="
            detailInformation.mission.mission_list &&
            detailInformation.mission.mission_list.length > 0
          "
        >
          <li *ngFor="let mission of detailInformation.mission.mission_list">
            <p class="card-text">
              <b> {{ mission.title }} </b> {{ mission.subtitle }}
            </p>
          </li>
        </ul>
        <div class="shared_information_cards_container">
          <app-info-card
            *ngFor="let mission of detailInformation.mission.mission_services"
            [title]="mission"
            [minHeight]="'180px'"
          >
          </app-info-card>
        </div>

        <!-- 7) -->
        <p
          id="Performance"
          *ngIf="detailInformation.performance"
          class="paragraph_title_department"
        >
          {{ "DEPARTMENTS_DETAIL.PERFORMANCE" | translate }}
        </p>
        <p class="shared_text">
          {{
            detailInformation.performance.short_text
              ? detailInformation.performance.short_text
              : ""
          }}
        </p>
        <div
          class="shared_information_cards_container"
          *ngIf="
            detailInformation.performance &&
            detailInformation.performance.perf &&
            detailInformation.performance.perf.length > 0
          "
        >
          <ng-container *ngFor="let perf of detailInformation.performance.perf">
            <p class="performance-text">
              {{ perf && perf.title ? perf.title : "" }}
            </p>

            <ng-container *ngIf="perf.isTabular">
              <div
                class="card card-bg"
                style="margin: 0.5%"
                *ngFor="let pf of perf.list"
              >
                <!-- -->
                <div class="card-body mt-10 card-bg">
                  <b>
                    <p class="card-text">Tipo di tumore:</p>
                  </b>
                  <p class="card-text">{{ pf.cancer }}</p>
                  <b>
                    <p class="card-text">Mutazione:</p>
                  </b>
                  <p class="card-text">{{ pf.mutation }}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!perf.isTabular">
              <app-info-card
                *ngFor="let pf of perf.list"
                [title]="pf"
                [minHeight]="'100px'"
              >
              </app-info-card>
            </ng-container>
          </ng-container>
        </div>
        <p
          class="shared_text"
          style="margin-bottom: 0.5em"
          *ngFor="let item of detailInformation.performance.information"
        >
          {{ item }}
        </p>

        <p
          *ngIf="
            detailInformation.pharmacologicalAdvice &&
            detailInformation.pharmacologicalAdvice.length > 0
          "
          id="Consulenza farmacologica"
          class="paragraph_title_department"
        >
          Consulenza farmacologica
        </p>
        <div class="row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3">
          <div
            class="card card-bg"
            style="margin: 0.5%"
            *ngFor="let pharm of detailInformation.pharmacologicalAdvice"
          >
            <!-- -->
            <div class="card-body mt-10 card-bg">
              <h5 class="card-title">{{ pharm.name }}</h5>
              <p class="card-subtitle" style="margin: 0.5% 0">
                {{ pharm.description }}
              </p>
              <b>
                <p class="card-text">Frequenza:</p>
              </b>
              <p class="card-text">{{ pharm.frequency }}</p>
              <b>
                <p class="card-text">Strumentazione:</p>
              </b>
              <p class="card-text">{{ pharm.instrument }}</p>
            </div>
          </div>

          <!-- -->
        </div>

        <!-- 8) -->
        <p id="Elenco Dirigenti Medici" class="paragraph_title_department">
          Elenco Dirigenti Medici
        </p>
        <div class="shared_information_cards_container">
          <app-departments-detail-person
            *ngFor="let med of detailInformation.medicalDirectors"
            [data]="med"
            [email]="med.PER_EMA"
          ></app-departments-detail-person>
        </div>
        <!-- 9) -->
        <p
          *ngIf="
            detailInformation.healthManagers &&
            detailInformation.healthManagers.length > 0
          "
          id="Elenco Dirigenti Sanitari"
          class="paragraph_title_department"
        >
          Elenco Dirigenti Sanitari
        </p>
        <div class="shared_information_cards_container">
          <app-departments-detail-person
            *ngFor="let manager of detailInformation.healthManagers"
            [data]="manager"
            [email]="manager.PER_EMA"
          >
          </app-departments-detail-person>
        </div>

        <p
          *ngIf="
            detailInformation.labTech && detailInformation.labTech.length > 0
          "
          id="labTech"
          class="paragraph_title_department"
        >
          Tecnici di laboratorio
        </p>
        <div class="shared_information_cards_container">
          <app-departments-detail-person
            *ngFor="let labTech of detailInformation.labTech"
            [data]="labTech"
            [email]="labTech.PER_EMA"
          >
          </app-departments-detail-person>
        </div>

        <ng-container
          *ngIf="
            detailInformation.otherRoles &&
            detailInformation.otherRoles.length > 0
          "
        >
          <div *ngFor="let role of detailInformation.otherRoles">
            <p id="{{ role.title }}" class="paragraph_title_department">
              {{ role.title }}
            </p>
            <div class="shared_information_cards_container">
              <app-departments-detail-person
                *ngFor="let ppl of role.people"
                [data]="ppl"
                [email]="ppl.PER_EMA"
              >
              </app-departments-detail-person>
            </div>
          </div>
        </ng-container>

        <p
          *ngIf="
            detailInformation.labTech && detailInformation.labTech.length > 0
          "
          id="labTech"
          class="paragraph_title_department"
        >
          Tecnici di laboratorio
        </p>
        <div class="shared_information_cards_container">
          <app-departments-detail-person
            *ngFor="let labTech of detailInformation.labTech"
            [data]="labTech"
            [email]="labTech.PER_EMA"
          >
          </app-departments-detail-person>
        </div>

        <!-- 10) -->
        <p
          id="Breve descrizione su attività di Ricerca e Didattica"
          class="paragraph_title_department"
        >
          Breve descrizione su attività di Ricerca e Didattica
        </p>
        <p
          class="shared_text"
          style="margin-bottom: 0.5em"
          *ngIf="detailInformation.researchActivities.short_text"
        >
          {{ detailInformation.researchActivities.short_text }}
        </p>
        <div
          class="shared_information_cards_container"
          *ngIf="
            detailInformation.researchActivities &&
            detailInformation.researchActivities.list &&
            detailInformation.researchActivities.list.length > 0
          "
        >
          <app-info-card
            *ngFor="let elem of detailInformation.researchActivities.list"
            [title]="elem"
            [minHeight]="'200px'"
          >
          </app-info-card>
        </div>
      </div>

      <ng-container *ngIf="detailInformation && detailInformation.lastUpdate">
        <b>
          <p>Dati aggiornati al:</p>
        </b>
        <p class="last_Update">
          {{ detailInformation.lastUpdate }}
        </p>
      </ng-container>
    </div>
    <!-- DAI DIAGNOSTICA PER IMMAGINI, AREA CRITICA E TERAPEUTICA: UOC Farmacologia Clinica e Farmacovigilanza  -->
  </div>
</div>
