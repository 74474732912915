import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from 'src/app/api/services/download.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';

@Component({
  selector: 'app-ethics-committee-detail-body',
  templateUrl: './ethics-committee-detail-body.component.html',
  styleUrls: ['./ethics-committee-detail-body.component.scss', '../../shared/sharedStyle.scss']
})
export class EthicsCommitteeDetailBodyComponent implements OnInit {

  @Input() parentData: any;
  @Input() detailInformation: any[];
  @Input() subDetailInformation: any[];
  constructor(
    private downloadService: DownloadService,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  public downloadFile(doc) {
    const postType = DocumentPostType.COM_ETIC;
    const postID = doc.ID;
    const fileName = doc.NomeFile;
    const url = this.downloadService.rootUrl +
      DownloadService.PostDownload1Path + postType +
      DownloadService.PostDownload2Path + postType + '/' + postID;
    this.downloadService.downloadBlob(fileName, url);
  }

  goToDetail(data) {
    const currentUrl = '/azienda/comitato-etico/' + data.ID;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {

      this.router.navigateByUrl(currentUrl.toString(), {
        state: data
      });
    });

  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }

}
