import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class GenericDualChoiceModalService {
    private _modalData: {
        show: boolean,
        title: string,
        bodyText: string,
        action1Title: string,
        action2Title: string,
        callback1Function: Function,
        callback2Function: Function
    } = { show: false, title: '', bodyText: '', action1Title: '', action2Title: '', callback1Function: undefined, callback2Function: undefined };
    modalStatus = new BehaviorSubject(this._modalData);

    constructor() {
    }

    getModal(): { show: boolean, title: string, bodyText: string, action1Title: string, action2Title: string, callback1Function: Function, callback2Function: Function } {
        return this._modalData;
    }

    setModal(value) {
        this._modalData = value;
        this.modalStatus.next(value);
    }

}