<div id="accordion" *ngIf="dataMap">
  <!-- la card si deve ripetere per anno -->
  <div class="accordion-item" *ngFor="let year of groups; index as i">
    <div class="card-header" id="heading">
      <h5 class="mb-0 button-cont">
        <button
          class="btn btn-link btn-accordion"
          (click)="accordions[i] = !accordions[i]"
          [ngClass]="{
            btnAccordion: accordions[i]
          }"
        >
          <p class="year-text">{{ year || "Altri anni" }}</p>
          <div class="showHideText">
            {{
              accordions[i]
                ? ("TRANSPARENT_ADMINSTRATION.COLLAPSE" | translate)
                : ("TRANSPARENT_ADMINSTRATION.EXPAND" | translate)
            }}
            <div
              [ngClass]="{
                isOpen: accordions[i]
              }"
            >
              <svg-icon-sprite
                class="arrow-accordion"
                src="../../../assets/sprite.svg#it-expand"
              >
              </svg-icon-sprite>
            </div>
          </div>
        </button>
      </h5>
    </div>

    <div
      [ngStyle]="{
        visibility: accordions[i] ? 'visible' : 'hidden',
        height: !accordions[i] ? '0' : '100%'
      }"
    >
      <div style="margin: 0.5em 0">
        <app-table-info
          [data]="dataMap[year]"
          [postTypeData]="'generics'"
          [showDate]="false"
          (downloadHandler)="downloadFile($event)"
        >
        </app-table-info>
        <p
          *ngIf="!(dataMap[year] && dataMap[year].length > 0)"
          style="text-align: center; margin: 2%"
        >
          {{ "MESSAGE.NO_RESULTS" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
