import { AuthGuard } from './auth/auth.guard';
import { AlboComponent } from './components/albo_pretorio/albo_pretorio.component';
import { ProcAziendDetailBodyComponent } from './components/proc-aziend-detail-body/proc-aziend-detail-body.component';
import { URPComponent } from './pages/URP/URP.component';
import { AttiNotificaComponent } from './pages/atti-notifica/atti-notifica.component';
import { CompanyDocumentalDetailComponent } from './pages/back-office/company-documental/company-documental-detail/company-documental-detail.component';
import { BookingAndContactsComponent } from './pages/booking-and-contacts/booking-and-contacts.component';
import { CampaniaPancreasComponent } from './pages/campania-pancreas/campania-pancreas.component';
import { CioComponent } from './pages/cio/cio.component';
import { CompanyDetailsComponent } from './pages/company/company-details/company-details.component';
import { CompanyReadMoreComponent } from './pages/company/company-read-more/company-read-more.component';
import { CompanyComponent } from './pages/company/company.component';
import { DepartmentsReadMoreComponent } from './pages/departments/departments-read-more/departments-read-more.component';
import { DepartmentsComponent } from './pages/departments/departments.component';
import { DepartmentsDetailComponent } from './pages/departments/departments_detail/departments_detail.component';
import { EthicsCommitteeDetailComponent } from './pages/ethics-committee/ethics-committee-detail/ethics-committee-detail.component';
import { EthicsCommitteeComponent } from './pages/ethics-committee/ethics-committee.component';
import { HomeComponent } from './pages/home/home.component';
import { LegalNotesComponent } from './pages/legal-notes/legal-notes.component';
import { LoginComponent } from './pages/login/login.component';
import { ManualeQualitaComponent } from './pages/manuale-qualita/manuale-qualita.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news/news_detail/news_detail.component';
import { NoticesComponent } from './pages/notices/notices.component';
import { NoticesDetailComponent } from './pages/notices/notices_detail/notices_detail.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ProcAziendDetailComponent } from './pages/proc-aziend/proc-aziend-detail/proc-aziend-detail.component';
import { ProcedureAziendaliComponent } from './pages/proc-aziend/proc-aziend.component';
import { RegulationAndPublicProtecionComponent } from './pages/regulation-and-public-protecion/regulation-and-public-protecion.component';
import { RiskDetailComponent } from './pages/risk/risk-detail/risk-detail.component';
import { RiskComponent } from './pages/risk/risk.component';
import { RlsComponent } from './pages/rls/rls.component';
import { TendersDetailComponent } from './pages/tenders/tenders-detail/tenders-detail.component';
import { TendersComponent } from './pages/tenders/tenders.component';
import { TransparentAdministrationDetailComponent } from './pages/transparent-administration/transparent-administration-detail/transparent-administration-detail.component';
import { WithdrawalMedicalRecordsComponent } from './pages/withdrawal-medical-records/withdrawal-medical-records.component';
import { WorkInProgressComponent } from './pages/work-in-progress/work-in-progress.component';

// File Relativo alle rotte
export const _routes = [
  { path: 'home', component: HomeComponent },

  { path: 'company-read-more/:title/:id', component: CompanyReadMoreComponent },
  { path: 'company-details/:id', component: CompanyDetailsComponent },
  { path: 'company', component: CompanyComponent },
  { path: 'azienda', component: CompanyComponent },
  { path: 'dipartimenti', component: DepartmentsComponent },
  {
    path: 'departments/dettaglio',
    redirectTo: 'departments',
    pathMatch: 'full',
  },
  { path: 'departments/dettaglio/:id', component: DepartmentsDetailComponent },
  { path: 'departments/details/info', component: DepartmentsReadMoreComponent },
  { path: 'concorsi-e-avvisi', component: NoticesComponent },
  { path: 'atti-di-notifica', component: AttiNotificaComponent },
  { path: 'bandi-e-gare', component: TendersComponent },

  { path: 'concorsi-e-avvisi/:id', component: NoticesDetailComponent },
  {
    path: 'bandi-e-gare/dettaglio',
    redirectTo: 'bandi-e-gare',
    pathMatch: 'full',
  },
  { path: 'bandi-e-gare/:id', component: TendersDetailComponent },
  { path: 'resolutions/delibere', component: AlboComponent },
  { path: 'resolutions/determine', component: AlboComponent },

  { path: 'prenotazione-e-contatti', component: BookingAndContactsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:permalink', component: NewsDetailComponent },
  { path: 'campania_pancreas', component: CampaniaPancreasComponent },
  { path: 'azienda/urp', component: URPComponent },
  {
    path: 'azienda/urp/regolamento-e-pubblica-tutela',
    component: RegulationAndPublicProtecionComponent,
  },

  {
    path: 'management/user-management',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/back-office/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
  },
  {
    path: 'management/news-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/back-office/news-documental/news.module').then(
        (m) => m.NewsModule
      ),
  },
  {
    path: 'management/ethics-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/ethics-documental/ethics-documental.module'
      ).then((m) => m.EthicsDocumentalModule),
  },
  {
    path: 'management/risk-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/back-office/risk-documental/risk-documental.module').then(
        (m) => m.RiskDocumentalModule
      ),
  },
  {
    path: 'management/department-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/department-documental/department-documental.module'
      ).then((m) => m.DepartmentDocumentalModule),
  },
  {
    path: 'management/company-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/company-documental/company-documental.module'
      ).then((m) => m.CompanyDocumentalModule),
  },
  {
    path: 'management/procedure-aziendali-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/procaziend-documental/procaziend-documental.module'
      ).then((m) => m.ProcAziendDocumentalModule),
  },
  {
    path: 'management/rls-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/rls-documental/rls-documental.module'
      ).then((m) => m.RlsDocumentalModule),
  },
  {
    path: 'management/manuale-qualita-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/manuale-qualita-documental/manuale-qualita-documental.module'
      ).then((m) => m.ManualeQualitaDocumentalModule),
  },
  {
    path: 'management/home-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/back-office/home-documental/home-documental.module').then(
        (m) => m.HomeDocumentalModule
      ),
  },

  {
    path: 'management/transparent-administration-documental',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/back-office/transparent-administration-documental/transparent-administration-documental.module'
      ).then((m) => m.TransparentAdministrationDocumentalModule),
  },
  {
    path: 'management/documents',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/back-office/documents/documents.module').then(
        (m) => m.DocumentsModule
      ),
  },
  {
    path: 'ritiro-cartella-clinica',
    component: WithdrawalMedicalRecordsComponent,
  },
  {
    path: 'company/transparent-administration',
    component: TransparentAdministrationDetailComponent,
  },
  {
    path: 'management/company-documental/structures',
    component: CompanyDocumentalDetailComponent,
  },
  {
    path: 'azienda/amministrazione-trasparente',
    loadChildren: () =>
      import(
        './pages/transparent-administration/transparent-administration.module'
      ).then((m) => m.TransparentAdministrationModule),
  },
  { path: 'azienda/privacy', component: PrivacyComponent },
  { path: 'azienda/manuale-qualita', component: ManualeQualitaComponent },
  {
    path: 'azienda/risk-management',
    component: RiskComponent,
  },
  {
    path: 'azienda/risk-management/cio-comitato-infezioni-ospedaliere',
    component: CioComponent,
  },
  { path: 'azienda/risk-management/:id', component: RiskDetailComponent },

  { path: 'azienda/comitato-etico', component: EthicsCommitteeComponent },
  { path: 'azienda/procedure-aziendali', component: ProcedureAziendaliComponent },
  { path: 'azienda/rls', component: RlsComponent },
  {
    path: 'azienda/comitato-etico/:id',
    component: EthicsCommitteeDetailComponent,
  },
  {
    path: 'azienda/procedure-aziendali/:id',
    component: ProcAziendDetailComponent,
  },
  { path: 'azienda/note-legali', component: LegalNotesComponent },
  { path: 'privacy-cookie-policy', component: PrivacyPolicyComponent },
  { path: 'management/login', component: LoginComponent },
  { path: 'wip', component: WorkInProgressComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];
