<div
  class="banner"
  [ngClass]="{
    company_head: page === 'company' || page === 'wip',
    campania_pancreas_head: page === 'cam_pancreas',
    com_etic_head: page === 'com_etic'
  }"
>
  <p role="region" aria-label="title" class="shared_section_title">
    {{ title | translate }}
  </p>
  <!-- <p
    *ngIf="page === 'com_etic'"
    class="shared_section_text"
    role="region"
    aria-label="descrizione"
  >
    {{ "ETHICS_COMMITEE.DESC" | translate }}
  </p> -->
  <p
    [ngClass]="{
      shared_section_text: page !== 'com_etic',
      shared_section_text_com_etic: page === 'com_etic'
    }"
    role="region"
    aria-label="sottosezione"
    [ngStyle]="{ display: !isShowMoreActive ? '-webkit-box' : 'block' }"
  >
    {{ subtitle | translate }}
  </p>
  <a
    *ngIf="page === 'com_etic' && !isShowMoreActive"
    class="leggi-di-piu banner-btn-read-more"
    role="region"
    aria-label="leggi-di-piu"
    (click)="isShowMoreActive = !isShowMoreActive"
  >
    {{ "BUTTON.READ_MORE" | translate }} <span> </span>
  </a>
</div>
