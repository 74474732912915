import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PuntiRitiroService } from 'src/app/api/services/punti-ritiro.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { puntoRitiroType } from 'src/app/shared/messageType';

@Component({
  selector: 'app-withdrawal-medical-records',
  templateUrl: './withdrawal-medical-records.component.html',
  styleUrls: [
    './withdrawal-medical-records.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class WithdrawalMedicalRecordsComponent implements OnInit {
  constructor(
    public router: Router,
    public prs: PuntiRitiroService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService
  ) {}

  @Output() department = new EventEmitter();
  detailInfo: puntoRitiroType[];
  currentStep = 1;
  totalSteps = 3;
  loaderPage = false;
  ngOnInit(): void {
    this.prs.PostPuntoRitiroDetails().subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.detailInfo = res.rows;
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {}.bind(this),
          });
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: function () {
            this.router.navigate(['home']);
          }.bind(this),
        });
      }
    );
  }

  backToHome() {
    this.router.navigate(['home']);
  }
}
