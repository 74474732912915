import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ResolutionsService } from '../../api/services/resolutions.service';
import { resolutionResponseType1 } from 'src/app/shared/resolutionType';
import * as moment from 'moment';
import { DownloadService } from 'src/app/api/services/download.service';
import { GenericInfoModalService } from '../generic-info-modal/generic-info-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { IS_MOBILE } from 'src/app/shared/models/constants';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';

declare var $: any;
@Component({
  selector: 'albo_post',
  templateUrl: './albo_pretorio_post.component.html',
  styleUrls: ['./albo_pretorio_post.component.css'],
})
export default class AlboPostComponent implements OnInit, OnChanges {
  @Input() type = 0; // 0 = Delibere;  1 = Determine
  @Input() addDate = 'd'; // 0 = Delibere;  1 = Determine

  typologyName =
    this.type == 0 ? TypeOperationEnum.DELIBERA : TypeOperationEnum.DETERMINA;
  mobile = IS_MOBILE;
  moment = moment;
  pageLimit = !this.mobile ? 10 : 5;
  currentPage = 1;
  limitPagination = true;
  loaderPage = false;

  @Input() filters: any;

  addID = 'A';


  yearsRoute = undefined;
  // translateLoaded: boolean = false;

  constructor(
    public resolutionService: ResolutionsService,
    private downloadService: DownloadService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) { }

  resolutionServiceList: resolutionResponseType1[] = [];
  resolutionServiceListForSearch: resolutionResponseType1[] = [];
  totalNumberOfPages: number;
  numberOfResults = 0;

  selectedDetail: resolutionResponseType1;

  ngOnChanges(changes: SimpleChanges) {
    this.yearsRoute = this.route.snapshot.queryParams.years;
    if (changes.filters.firstChange == false) {
      let isEmpty =
        Object.values(this.filters).filter((value) => value).length == 0;
      if (isEmpty && this.yearsRoute == undefined) {
        this.postSearchResolutionsDetailsDay();
      } else {
        this.filterItem(this.filters);
      }
    }
  }

  filterItem(value: any) {
    this.currentPage = 1;
    let body = {};
    if (this.yearsRoute && value.year.length > 0) {
      let datainizio = moment(new Date(value.year)).format('YYYY-MM-DD');
      let datafine = moment(new Date(value.year, 11, 31)).format('YYYY-MM-DD');
      let currentYear = moment().format('YYYY');
      if (currentYear !== value.year) {
        let date = moment(new Date(value.year));
        datainizio = date.startOf('year').format('YYYY-MM-DD');
        datafine = date.endOf('year').format('YYYY-MM-DD');
      }
      body = { ...body, datainizio: datainizio };
      body = { ...body, datafine: datafine };
    }
    if (value.title) {
      body = { ...body, titolo: value.title };
    }
    if (value.number) {
      body = { ...body, numero: value.number };
    }
    this.postSearchResolutionsDetailsYears(body);
  }

  ngOnInit(): void {
    this.yearsRoute = this.route.snapshot.queryParams.years;

    this.currentPage = 1;
    if (this.yearsRoute) {
      this.postSearchResolutionsDetailsYears();
    } else if (this.yearsRoute == undefined) {
      this.postSearchResolutionsDetailsDay();
    }
  }

  getStartDate(element) {
    return moment(element.datainizio, 'YYYY-MM-DD').format('DD MMMM YYYY')
  }

  postSearchResolutionsDetailsDay(body?: { titolo?: string; numero?: string }) {
    this.loaderPage = true;

    this.resolutionService
      .postSearchResolutionsDetails(
        {
          ...body,
          type: this.type,
          published: '1',
          filter: 'DataInizio',
          order: 'DESC',
          datainizio: moment(new Date()).subtract(15, 'd').format('YYYY-MM-DD'),
        },

        { page: this.currentPage, limit: this.pageLimit }
      )
      .subscribe(
        (res) => {
          this.limitPagination = !(res.rowCount < this.pageLimit);
          this.resolutionServiceList = res.rows;
          this.numberOfResults = res.rowCount;
          this.totalNumberOfPages =
            Math.floor(this.numberOfResults / this.pageLimit) +
            (this.numberOfResults % this.pageLimit === 0 ? 0 : 1);
          this.loaderPage = false;
        },
        (err) => {
          this.loaderPage = false;

          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: {},
          });
        }
      );
  }

  postSearchResolutionsDetailsYears(body?: {
    datainizio?: string;
    datafine?: string;
    titolo?: string;
  }) {
    this.loaderPage = true;
    this.resolutionService
      .postSearchResolutionsDetails(
        {
          ...body,
          type: this.type,
          published: '1',
          filter: 'DataInizio',
          order: 'DESC',
        },
        { page: this.currentPage, limit: this.pageLimit }
      )
      .subscribe(
        (res) => {
          this.limitPagination = !(res.rowCount < this.pageLimit);
          this.resolutionServiceList = res.rows;
          this.numberOfResults = res.rowCount;
          this.totalNumberOfPages =
            Math.floor(this.numberOfResults / this.pageLimit) +
            (this.numberOfResults % this.pageLimit === 0 ? 0 : 1);
          this.loaderPage = false;
        },
        (err) => {
          this.loaderPage = false;

          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: {},
          });
        }
      );
  }

  updateList(value: number) {
    this.currentPage = value;
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    let body = {};
    if (this.filters.title) {
      body = { ...body, titolo: this.filters.title };
    }
    if (this.filters.number) {
      body = { ...body, numero: this.filters.number };
    }
    if (this.yearsRoute && this.filters.year.length > 0) {
      // let datainizio = moment(new Date(this.filters.year, 11, 31)).format('YYYY-MM-DD');
      // let datafine = moment(new Date(this.filters.year)).format('YYYY-MM-DD');
      body = {
        ...body,
        datainizio: moment(new Date(this.filters.year)).format('YYYY-MM-DD'),
      };
      body = {
        ...body,
        datafine: moment(new Date(this.filters.year, 11, 31)).format(
          'YYYY-MM-DD'
        ),
      };
    }
    // se il query params indica che siamo in albo pretorio deve ristuirmi solo quelle degli ultimi 15 giorni
    if (this.yearsRoute == undefined) {
      this.postSearchResolutionsDetailsDay();
    }
    if (this.yearsRoute) {
      this.postSearchResolutionsDetailsYears(body);
    } else {
      if (this.filters.title !== '') {
        this.postSearchResolutionsDetailsYears(body);
      }
    }
  }

  downloadFile(element: resolutionResponseType1) {
    this.loaderPage = true;
    const postType = DocumentPostType.RESOLUTIONS;
    const postID = element?.id ? element.id.toString() : '';
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(element?.filename, url);
    this.loaderPage = false;
  }

  showDetail(element: resolutionResponseType1) {
    this.selectedDetail = element;
    $('#alboPretorioModal').modal('show').scrollTop(0);
  }

  closeModal() {
    this.selectedDetail = undefined;
    $('#alboPretorioModal').modal('hide').scrollTop(0);
  }
}
