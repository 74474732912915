import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CompanyService } from 'src/app/api/services/company.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericDualChoiceModalService } from 'src/app/components/generic-dual-choice-modal/generic-dual-choice-modal.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentsService } from 'src/app/pages/back-office/documents/documents.service';
import { ModalModeType } from 'src/app/shared/enums/modalModeType';
import { RoleUserEnum } from 'src/app/shared/enums/roleUsersEnum';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';
declare var $: any;
@Component({
  selector: 'app-company-documental-detail',
  templateUrl: './company-documental-detail.component.html',
  styleUrls: ['../../../../shared/boStyle.scss'],
})
export class CompanyDocumentalDetailComponent implements OnInit {
  pageLimit = window.screen.width > 1200 ? 10 : 5;
  currentPage = 1;
  totalNumberOfPages: number;
  role: RoleUserEnum;
  loaderPage = false;
  showBodyModal = false;
  dataSource: any[];
  selectedRow: any;
  limitPagination = true;
  countTotal: number;
  isReadOnly = false;
  documentForm: FormGroup;

  filterOrder: 'ASC';
  filters: any = {};

  listStatus = [
    { value: '1', title: this.translate.instant('USER_MANAGEMENT.PUBLISHED') },
    {
      value: '0',
      title: this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
    },
  ];

  documentsTypes = [];

  modalMode; // edit | readonly | create
  documentToShow;

  typologyName = TypeOperationEnum.STRUTTURE;

  constructor(
    public router: Router,
    private translate: TranslateService,
    public cs: CompanyService,
    public fb: FormBuilder,
    public documentService: DocumentsService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private dualChoiceModal: GenericDualChoiceModalService
  ) {
    this.documentForm = fb.group({
      type: ['', Validators.required],
      attributes: fb.array([], Validators.required),
    });
  }

  get documentType() {
    return this.documentForm.controls.type;
  }
  get attributes(): FormArray {
    return <FormArray>this.documentForm.get('attributes');
  }

  directionObj;

  ngOnInit(): void {

    this.documentService.setRole((_role) => {
      this.role = _role;
      this.directionObj = history.state;
      if (!this.directionObj.ID) {
        this.router.navigateByUrl('/management/company-documental');
        return;
      }
      this.generateStructureService();
      this.documentType.valueChanges.subscribe((value) => {
        while (this.attributes.length !== 0) {
          this.attributes.removeAt(0);
        }
        this.generateControlsForStructures();
      });
    });
  }

  generateStructureService() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var body: any = {};
    this.filters && this.filters.published
      ? (body.published = this.filters.published)
      : null;
    this.filters && this.filters.titolo
      ? (body.Titolo = this.filters.titolo)
      : null;
    body.filter =
      this.filters && this.filters.filter
        ? this.filters.filter
        : (this.filters.filter = 'Titolo');
    body.order =
      this.filters && this.filters.order ? this.filters.order : 'ASC';
    this.cs
      .companyStructureSearch(
        { ID_Direzione: this.directionObj.ID },
        { page: this.currentPage, limit: this.pageLimit }
      )
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.countTotal = res.rowCount;
            this.limitPagination = !(res.rowCount <= this.pageLimit);
            this.dataSource = res.rows;
            this.totalNumberOfPages =
              this.documentService.setTotalNumberOfPagesDataSource(
                this.countTotal,
                this.pageLimit
              );
          } else {
            this.resetPaginationAndDataSource();
          }
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.documentService.checkTokenExpired(err);
        }
      );
  }

  ngAfterViewInit(): void {
    $('#companyDocumentalDetailModal').on('shown.bs.modal', (e) => {
      this.documentService.setRole((_role) => {
        this.role = _role;
        this.documentToShow = e.relatedTarget.documentToShow;
        this.selectedRow = e.relatedTarget.element;
        this.showBodyModal = true;
        if (this.documentToShow) {
          this.initFormStructures();
        }
      });
    });
  }

  initFormStructures() {
    this.documentService.initFormStructures(
      this.selectedRow,
      this.documentForm,
      this.attributes
    );
  }

  generateControlsForStructures() {
    this.attributes.push(
      this.documentService.createSubForm(this.role, this.fb, this.isReadOnly, [
        {
          key: 'description',
          title: this.translate.instant('COMPANY_DOCUMENTAL.TITLE_1'),
          controlType: 'text',
          required: true,
          disabled: false,
        },
        {
          key: 'publish',
          title: this.translate.instant('COMPANY_DOCUMENTAL.PUBLISH'),
          controlType: 'checkbox',
          required: false,
          disabled: false,
        },
      ])
    );
  }

  handleBack() {
    this.router.navigateByUrl('management/company-documental');
  }
  // * TABLE HANDLERS
  editModalFromTable(event) {
    this.isReadOnly = event.readOnly;
    this.showBodyModal = event.showBodyModal;
    this.editModal(event.document, event.element);
  }

  editModal(document, element) {
    this.modalMode = ModalModeType.EDIT;
    this.documentToShow = document;
    $('#companyDocumentalDetailModal').modal('toggle', {
      documentToShow: this.documentToShow,
      element,
    });
  }

  editDocumentHandler(value) {
    this.editModalHandler(
      value.documentDTO,
      value.selectedRow,
      value.editModalType
    );
  }

  async editModalHandler(infoData, element, modalMode) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var requestBody: any = {};
    requestBody.ID = element.ID;
    requestBody.ID_Direzione = element.ID_Direzione;
    requestBody.Nome_Struttura = infoData.description;
    requestBody.Luogo_Struttura = '';
    requestBody.published =
      this.role === RoleUserEnum.AUTHOR ? '0' : infoData.publish ? '1' : '0';
    if (element.ID) {
      this.documentService.callStructureUpdate(
        requestBody,
        modalMode,
        this.generateStructureService.bind(this)
      );
    } else {
      this.documentService.callStructureInsert(
        requestBody,
        modalMode,
        this.generateStructureService.bind(this)
      );
    }
  }

  didChangeStatusFromTable(event) {
    this.documentService.changeStatusStructure(
      event.document.ID.toString(),
      '0',
      this.generateStructureService.bind(this)
    );
  }
  didPressDeleteFromTable(event) {
    this.didPressDelete(event.element);
  }
  didPressDelete(element: any) {
    var aBodyText =
      'Sei sicuro di voler eliminare ' + element.Nome_Struttura + '?';
    this.dualChoiceModal.setModal({
      show: true,
      title: this.translate.instant('DOCUMENTS.DELETE'),
      bodyText: aBodyText,
      action1Title: this.translate.instant('BUTTON.CONFIRM'),
      action2Title: this.translate.instant('BUTTON.CANCEL'),
      callback1Function: function () {
        this.deleteDocument(element);
      }.bind(this),
      callback2Function: undefined,
    });
  }

  async deleteDocument(element) {
    this.cs.structureDelete({ ID: element.ID }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant(
              'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_DELETE_TITLE'
            ),
            bodyText: this.translate.instant(
              'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_DELETE_BODY'
            ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              this.generateStructureService();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => { },
          });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.documentService.checkTokenExpired(err);
      }
    );
  }
  // ? Fine eliminazione

  buttonAddHandler(event) {
    this.isReadOnly = event.isReadOnly;
    this.showBodyModal = event.showBodyModal;
    this.onModalButtonClicked(event.document);
  }

  onModalButtonClicked(document) {
    this.showBodyModal = true;

    this.documentToShow = document;
    this.modalMode = ModalModeType.CREATE;

    $('#companyDocumentalDetailModal').modal('toggle', {
      documentToShow: this.documentToShow,
      element: undefined,
    });
  }

  /**
   * RESET PAGINATION AND DATA SOURCE
   */
  resetPaginationAndDataSource() {
    this.countTotal = 0;
    this.limitPagination = true;
    this.dataSource = [];
    this.totalNumberOfPages = 0;
  }

  // modal handlers
  createDocumentHandler(event) {
    this.createDocument(
      event.documentDTO,
      event.selectedRow,
      event.createModalType
    );
  }

  async createDocument(infoData, element, modalMode) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var requestBody: any = {};
    requestBody = {
      Nome_Struttura: infoData.description,
      ID_Direzione: this.directionObj.ID,
      published:
        this.role === RoleUserEnum.AUTHOR ? '0' : infoData.publish ? '1' : '0',
      Luogo_Struttura: '',
    };
    this.documentService.callStructureInsert(
      requestBody,
      modalMode,
      this.generateStructureService.bind(this)
    );
  }

  deleteDocumentHandler(event) {
    /* unused */
  }

  updateList(value: number) {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.currentPage = value;
    this.onChangePage();
  }

  onChangePage() {
    this.filterOrder = 'ASC';
    /*this.filterTitle = '';
    this.filterStatus = null;*/
    this.resetPaginationAndDataSource();
    this.generateStructureService();
  }
}
