import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter, } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrivacyService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly PostSearchPath = '/api/v1/privacy/_search';
  static readonly PostInsertPath = '/api/v1/privacy/_insert';
  static readonly PutUpdatePath = '/api/v1/privacy/_update';
  static readonly PostDeletePath = '/api/v1/privacy/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/privacy/_update_publish';
  static readonly PostPrivacySearchFoldersPath = '/api/v1/privacy/_search_folders';

  /*
  * SEARCH
  */
  postSearchPrivacy$Response(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, PrivacyService.PostSearchPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }

  postSearchPrivacy(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postSearchPrivacy$Response(body, params).pipe(
      map((r: StrictHttpResponse<{
        'rowCount'?: number, 'message'?: string | null,
        'rows'?: Array<any>
      }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
  * INSERT
  */
  postInsertPrivacy$Response(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyService.PostInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  postInsertPrivacy(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postInsertPrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
  * UPDATE
  */
  putUpdatePrivacy$Response(params?: {
    privacy_id: string;
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {
    const rb = new RequestBuilder(this.rootUrl, PrivacyService.PutUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  putUpdatePrivacy(params?: {
    privacy_id: string;
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.putUpdatePrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }


  /*
 * DELETE
 */
  DeletePrivacy$Response(params?: {
    privacy_id: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, PrivacyService.PostDeletePath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  DeletePrivacy(params?: {
    privacy_id: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeletePrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
* UPDATE PUBLISH
*/
  UpdatePublishPrivacy$Response(params?: {
    privacy_id: string,
    published: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, PrivacyService.PutUpdatePublishPath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  UpdatePublishPrivacy(params?: {
    privacy_id: string,
    published: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.UpdatePublishPrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
  * SEARCH FOLDERS
  */
  postSearchFoldersPrivacy$Response():
    Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(
      this.rootUrl,
      PrivacyService.PostPrivacySearchFoldersPath,
      'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  postSearchFoldersPrivacy():
    Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postSearchFoldersPrivacy$Response().pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) =>
        r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

}
