import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentsService } from 'src/app/api/services';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-departments-read-more',
  templateUrl: './departments-read-more.component.html',
  styleUrls: [
    './departments-read-more.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsReadMoreComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[];
  daiInfo;
  detailInformation = [];

  constructor(
    private router: Router,
    private departmentService: DepartmentsService
  ) { }

  loaderPage = false;

  ngOnInit(): void {
    this.departmentService
      .readMoreSearch({ ID_Struttura: history.state.ID })
      .subscribe((res) => {
        res.rows.forEach((element) => {
          this.detailInformation = JSON.parse(element.Json_info);
        });
      });

    this.daiInfo = history.state;
    if (!this.daiInfo.ID) {
      this.router.navigateByUrl('/dipartimenti');
    }
    this.breadcrumbItems = [
      new BreadcrumbItem('DEPARTMENTS_DETAIL.HOME', '/home'),
      new BreadcrumbItem('DEPARTMENTS_DETAIL.DEPARTMENTS', '/dipartimenti'),
      new BreadcrumbItem(
        this.daiInfo.departments_detail.Titolo,
        '/departments/dettaglio/' + this.daiInfo.departments_detail.ID
      ),
      new BreadcrumbItem(this.daiInfo.Nome_Struttura, ''),
    ];
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
