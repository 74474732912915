<div class="cell" *ngIf="data.type == 'simpleCard'">
  <div class="simple-card-text">
    {{ data.text }}
  </div>
</div>
<div class="cell" *ngIf="data.type == 'number'">
  <p class="title">{{ data.description }}</p>
  <div class="phoneNumber">
    <a [href]="'tel:' + '+39' + data.phone" class="phone_number">{{
      data.phone
    }}</a>

    <div>{{ data.text }}</div>
  </div>
</div>
<div class="cell" *ngIf="data.type == 'simpleCardLink'">
  <a href="{{ data.path }}" class="simple-card-link">
    <div class="simple-card-text">
      {{ data.text }}
    </div>
  </a>
</div>
<div class="cell" *ngIf="data.type == 'mail'">
  <div class="title">
    {{ data.text }}
  </div>
  <div class="phoneNumber">
    <a href="mailto:{{ data.mailAddress }}" class="phone_number">{{
      data.mailAddress
    }}</a>
  </div>
</div>
<div class="cell" *ngIf="data.type == 'person'">
  <div class="person">
    <img [src]="imagePath" alt="person" (error)="onImageError()" />
    <div class="information">
      <p class="role">{{ data.description }}</p>
      <p class="text">
        {{ data.title + " " }}{{ data.name + " " }}{{ data.surname }}
      </p>
      <a href="tel:{{ phone }}" class="phone_number">{{ data.phone }}</a>
    </div>
  </div>
</div>
<div class="cell" *ngIf="data.type == 'reception'">
  <div class="title">{{ data.title }}</div>
  <div>
    {{ data.text }}
  </div>
</div>
