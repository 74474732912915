<!-- HEAD -->
<app-main-head [breadcrumbItems]="breadcrumbItems" [isSearchRequired]="false"
  [mainTitle]="'NAVBAR_TAB.ACTS_OF_NOTIFICATION'" [isRightPanelVisible]="false">
</app-main-head>
<!-- END HEAD -->

<div class="wrapper center">
  <p class="no_result_wrapper" *ngIf="detailInfo?.length == 0">
    {{ "ALERT_MESSAGE.NO_RESULTS_FOUND" | translate }}
  </p>
  <!-- Versione Mobile -->
  <div *ngIf="mobile">
    <app-main-accordion-table [dataSource]="detailInfo" [typology]="typologyName" [hasAttachment]="true"
      [hasDetail]="false" (downloadHandler)="downloadFile($event)">
    </app-main-accordion-table>
  </div>

  <!-- versione Desktop -->
  <div *ngIf="!mobile && detailInfo && detailInfo.length> 0" class="table">
    <!-- ALL -->
    <app-main-all-body [typology]="postType" [mainTitle]="''" [bodyList]="detailInfo">
    </app-main-all-body>
    <!-- END ALL -->
  </div>
</div>

<app-paginator [loader]="loaderPage" [list]="detailInfo" [isIntoPaginationLimit]="limitPagination"
  [currentPage]="currentPage" [totalNumberOfPages]="totalNumberOfPages" (didClickedOnPage)="updateList($event)">
</app-paginator>