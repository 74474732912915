import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ReadingTimePipe } from './pipes/reading-time.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { QuillModule } from 'ngx-quill';
import { GenericDualChoiceModalComponent } from '../components/generic-dual-choice-modal/generic-dual-choice-modal.component';
import { GenericInfoModalComponent } from '../components/generic-info-modal/generic-info-modal.component';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { EditorComponent } from '../pages/back-office/news-documental/editor/editor.component';
import { MessageTableComponent } from '../pages/back-office/news-documental/message-table/message-table.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LogoContainerComponent } from './logo-container/logo-container.component';
import { ModalDocumentalComponent } from '../components/modal-documental/modal-documental.component';
import { MainHeadComponent } from '../components/main-head/main-head.component';
import { TransparentAdministrationDetailBodyComponent } from '../pages/transparent-administration/transparent-administration-detail-body/transparent-administration-detail-body.component';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { InfoCardComponent } from '../components/info-card/info-card.component';
import { TableInfoComponent } from '../components/table-info/table-info.component';
import { AccordionDetailComponent } from '../components/accordion-detail/accordion-detail.component';
import { SimpleModalDocumentalComponent } from '../components/simple-modal-documental/simple-modal-documental.component';
import { ButtonAddDocumentalComponent } from '../components/button-add-documental/button-add-documental.component';
import { TableDocumentsComponent } from '../components/table-documents/table-documents.component';
import { CellInfoComponent } from '../components/cell-info/cell-info.component';

@NgModule({
  declarations: [
    ReadingTimePipe,
    FileUploadComponent,
    GenericDualChoiceModalComponent,
    GenericInfoModalComponent,
    TableFilterPipe,
    EditorComponent,
    MessageTableComponent,
    PaginatorComponent,
    // AlboComponent,
    BreadcrumbComponent,
    LogoContainerComponent,
    TableDocumentsComponent,
    TableInfoComponent,
    MainHeadComponent,
    InfoCardComponent,
    TransparentAdministrationDetailBodyComponent,
    ModalDocumentalComponent,
    SimpleModalDocumentalComponent,
    AccordionDetailComponent,
    ButtonAddDocumentalComponent,
    CellInfoComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconSpriteModule,

    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
  ],
  exports: [
    TranslateModule,
    IconSpriteModule,
    MainHeadComponent,
    InfoCardComponent,
    AccordionDetailComponent,
    TableInfoComponent,
    ReadingTimePipe,
    FormsModule,
    ReactiveFormsModule,
    TransparentAdministrationDetailBodyComponent,
    FileUploadComponent,
    QuillModule,
    TableFilterPipe,
    EditorComponent,
    MessageTableComponent,
    GenericDualChoiceModalComponent,
    GenericInfoModalComponent,
    PaginatorComponent,
    BreadcrumbComponent,
    LogoContainerComponent,
    TableDocumentsComponent,
    ModalDocumentalComponent,
    SimpleModalDocumentalComponent,
    ButtonAddDocumentalComponent,
    CellInfoComponent,
  ],
})
export class SharedModule { }
