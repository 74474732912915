<div *ngIf="visible" style="max-width: none !important">
    <div class="row align-items-center mt-3em">
        <div class="form-group mb-0 col-lg-3 spacing-input ">
            <label class="active">{{'BAR.TITLE' | translate}}</label>
            <input id="input-search-filter" type="search" [(ngModel)]="tempSearchText" class="autocomplete"
                placeholder='Cerca contenuti in "{{textPlaceholder | translate}}"'>
            <span class="autocomplete-icon" aria-hidden="true">
            </span>
        </div>

        <div class="bootstrap-select-wrapper col-lg-3 spacing-button" *ngIf="showYear">
            <label>{{'BAR.LABEL_YEAR' | translate}}</label>
            <select class="typeSelect" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedTempYear">
                <option selected="" value="">{{'BAR.LABEL_YEAR_SELECT' | translate}}</option>
                <option *ngFor="let year of years" [value]="year">
                    {{year}}
                </option>
            </select>
        </div>
        <div class="bootstrap-select-wrapper col-lg-2 spacing-button" *ngIf="showNumber">
            <label>{{'BAR.NUMBER' | translate}}</label>
            <input type="number" [(ngModel)]="tempSearchNumber" class="typeSelect min-w-100" aria-label="number">
        </div>
        <div class="m-1em">
            <button (click)="search()" class="btn btn-primary m-2">{{"USER_MANAGEMENT.SEARCH"
                | translate}}</button>
            <button (click)="reset()" class="btn btn-outline-primary m-2"
                type="button">{{"USER_MANAGEMENT.USER_FILTER_RESET"|
                translate}}
            </button>
        </div>
    </div>
</div>