<!-- TABLE -->
<table class="table" *ngIf="dataSource && dataSource.length > 0">
  <thead class="header">
    <tr>
      <th *ngFor="let header of initHeaderTable()" scope="col" [ngStyle]="{ width: header.width }">
        {{ header.value }}
        <ng-container *ngIf="hasSort">
          <img *ngIf="filterOrder === 'DESC'" style="width: 1.5625em; margin-left: 0.9375em"
            (click)="searchBtnPressed('ASC')" [src]="'../../../assets/sort-down.svg'" class="icon icon-white position"
            alt="sort-down" />
          <!-- Ordine descrescente -->
          <img (click)="searchBtnPressed('DESC')" *ngIf="filterOrder === 'ASC'"
            style="width: 1.5625em; margin-left: 0.9375em" [src]="'../../../assets/sort-up.svg'"
            class="icon icon-white position" alt="sort-up" />
        </ng-container>
      </th>
    </tr>
  </thead>

  <tbody *ngFor="let element of dataSource">
    <tr>
      <td *ngFor="let item of generateRows(element)" (click)="item.handlerFunction ? item.handlerFunction() : null"
        [class.links]="item.isAlink">
        {{ item.value }}
      </td>
      <!-- DELIBERA & DETERMINA -->
      <ng-container *ngIf="typologyName === 'DELIBERA' || typologyName === 'DETERMINA'">
        <td style="word-break: break-word" (click)="readDocument(typologyName, element, true, false)">
          {{ element.titolo }}
        </td>
        <td>{{ getNameTitle(documentTypeName) }}</td>
        <td>
          {{ element.published === "1" ? "PUBBLICATO" : "NON PUBBLICATO" }}
        </td>
      </ng-container>

      <td>
        <div class="dropdown">
          <button [disabled]="
              !(
                checkRolePermission() ||
                (!otherSubCategoryEnable && element.published !== '1')
              )
            " class="btn btn-dropdown dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <svg-icon-sprite src="../../../assets/sprite.svg#it-expand" classes="icon-expand icon icon-sm icon-primary">
            </svg-icon-sprite>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="link-list-wrapper" style="padding: 0 5%">
              <ul class="link-list">
                <li *ngIf="element.published !== '1' &&  element.post_status !== 'publish'">
                  <div (click)="editDocument(typologyName, element, false, false)"
                    style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.DROPDOWN_EDIT" | translate }}
                    </p>
                  </div>
                </li>

                <li *ngIf="
                    (element.published === '1' ||  element.post_status === 'publish') &&
                    checkRolePermission()
                  ">
                  <div (click)="changeStatus(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-tool" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.CHANGE_STATUS" | translate }}
                    </p>
                  </div>
                </li>

                <li *ngIf="
                    typologyName == 'DIPARTIMENTI' && element.published !== '1'
                  ">
                  <div (click)="didPressUOC(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.UOC_MANAGEMENT" | translate }}
                    </p>
                  </div>
                </li>
                <li *ngIf="
                    typologyName == 'DIPARTIMENTI' && element.published !== '1'
                  ">
                  <div (click)="didPressUOS(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.UOS_MANAGEMENT" | translate }}
                    </p>
                  </div>
                </li>
                <li *ngIf="
                    typologyName == 'DIPARTIMENTI' && element.published !== '1'
                  ">
                  <div (click)="didPressUOSD(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.UOSD_MANAGEMENT" | translate }}
                    </p>
                  </div>
                </li>
                <li *ngIf="
                    typologyName == 'DIPARTIMENTI' && element.published !== '1'
                  ">
                  <div (click)="didPressPrograms(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "DOCUMENTS_TABLE.PROGRAMS_MANAGEMENT" | translate }}
                    </p>
                  </div>
                </li>
                <li *ngIf="
                    typologyName == 'DIREZIONE' && element.published !== '1'
                  ">
                  <div (click)="didPressCompanyStructures(element)" style="display: flex; margin-top: 5%">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-pencil" classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      Gestisci Strutture
                    </p>
                  </div>
                </li>
                <li *ngIf="
                    (element.published !== '1' && 
                    element.post_status !== 'publish')
                      &&
                    checkRolePermission() &&
                    typologyName !== 'USER_MANAGEMENT'
                  ">
                  <div (click)="didPressDelete(element, typologyName)" style="display: flex; margin-top: 5%"
                    style="display: flex; margin-top: 5%">
                    <svg-icon-sprite class="icon-danger" src="../../../assets/sprite.svg#it-delete"
                      classes="icon icon-sm icon">
                    </svg-icon-sprite>
                    <p style="padding: 1%; cursor: pointer">
                      {{ "USER_MANAGEMENT.DELETE" | translate }}
                    </p>
                  </div>
                </li>
                <ng-container *ngIf="typologyName === 'USER_MANAGEMENT'">
                  <li>
                    <div (click)="didPressReset(element.email)" style="display: flex; margin-top: 5%">
                      <p style="
                          padding: 1%;
                          cursor: pointer;
                          margin-left: 1.5625em;
                          color: red;
                          font-weight: 500;
                        ">
                        {{ "USER_MANAGEMENT.RESET_PASSWORD" | translate }}
                      </p>
                    </div>
                  </li>
                  <li *ngIf="!isDisabledUser(element)">
                    <div (click)="disableUser(element)" style="display: flex; margin-top: 5%">
                      <p style="
                          padding: 1%;
                          cursor: pointer;
                          margin-left: 1.5625em;
                          color: #17324d;
                          font-weight: 500;
                        ">
                        {{ "USER_MANAGEMENT.DISABLE_USER" | translate }}
                      </p>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>

  <tfoot></tfoot>
</table>