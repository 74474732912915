import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NoticesService } from 'src/app/api/services';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';
import { noticesDetailResponse } from 'src/app/shared/messageType';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { IS_MOBILE } from 'src/app/shared/models/constants';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit {
  loaderPage = false;
  searchText = '';
  typologyName = TypeOperationEnum.CONCORSI;
  constructor(
    private ns: NoticesService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private router: Router
  ) {}

  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NOTICES.NOTICES', ''),
  ];

  noticesList: noticesDetailResponse[] = [];
  searchNoticesList: noticesDetailResponse[] = [];
  evidencedNotices: noticesDetailResponse[] = [];
  headNoticesList: noticesDetailResponse[] = [];
  pageLimit = window.screen.width > 1200 ? 30 : 5;
  currentPage = 1;
  limitPagination = true;
  totalNumberOfPages: number;
  numberOfResults = 0;
  mobile = IS_MOBILE;
  addID = 'A';

  ngOnInit(): void {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.getNotices();
  }

  getNotices() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    let params: any = {
      published: '1',
      filter: 'entity.iniziovalidita',
      order: 'DESC',
      tipobando: '0',
    };
    let paginator = {
      page: this.currentPage,
      limit: this.pageLimit,
    };

    this.ns.postSearchNoticesDetails(params, paginator).subscribe(
      (res) => {
        this.noticesList = res.rows;

        this.limitPagination = !(res.rowCount < this.pageLimit);
        this.numberOfResults = res.rowCount;
        this.totalNumberOfPages =
          Math.floor(this.numberOfResults / this.pageLimit) +
          (this.numberOfResults % this.pageLimit === 0 ? 0 : 1);
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
  }

  updateList(value: number) {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.currentPage = value;
    // se non é presente nulla nella barra di ricerca mostra tutti i risultati altrimenti fa la ricerca
    if ((!this.searchText && this.searchText != '') || this.searchText == '') {
      this.getNotices();
    } else {
      this.getTendersByName(this.searchText);
    }
  }
  // prende l`evento che arriva
  searchExt(event) {
    //ogni volta che facciamo la ricerca ritorna sulla prima pagina
    this.currentPage = 1;
    this.searchText = event;
    this.filterItem(event);
  }

  filterItem(value) {
    this.currentPage = 1;

    this.loaderService.setloader(this.loaderPage);
    if (!value) {
      this.getNotices();
    } else {
      this.getTendersByName(value);
    }
  }
  //ricerca per nome
  getTendersByName(titolo) {
    this.loaderPage = true;
    this.ns
      .postSearchNoticesDetails(
        {
          published: '1',
          filter: 'entity.titolo',
          order: 'DESC',
          tipobando: '0',
          titolo: titolo,
        },
        {
          page: this.currentPage,
          limit: this.pageLimit,
        }
      )
      .subscribe(
        (res) => {
          if (res) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
          this.noticesList = res.rows;
          this.numberOfResults = res.rowCount;
          this.totalNumberOfPages =
            Math.floor(this.numberOfResults / this.pageLimit) +
            (this.numberOfResults % this.pageLimit === 0 ? 0 : 1);
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: {},
          });
        }
      );
  }
  showDetail(event) {
    let id = event.ID;
    this.router.navigate(['/concorsi-e-avvisi/', id]);
  }
}
