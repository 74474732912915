import { Component, Input, OnInit } from '@angular/core';
import { DepartmentsService } from 'src/app/api/services';
import { departmentEmailsResponse } from 'src/app/shared/messageType';

@Component({
  selector: 'app-departments-detail-person',
  templateUrl: './departments_detail_person.component.html',
  styleUrls: [
    './departments_detail_person.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsDetailPersonComponent implements OnInit {
  @Input() data;
  @Input() email: departmentEmailsResponse;
  @Input() showNumber = false;
  imagePath: string;
  defaultImagePath: string = '../../../assets/Unknown_person.jpg';

  constructor(private departmentsService: DepartmentsService) {}

  ngOnInit(): void {
    if (this.data && this.data.ID) {
      this.departmentsService.departmentGetPhoto(this.data.ID).subscribe(
        (response: Blob) => {
          console.log(response)
          if (response) {
          console.log(response)
            const reader = new FileReader();
            reader.onload = () => {
              this.imagePath = reader.result as string;
            };
            reader.readAsDataURL(response);
          } else {
            this.imagePath = this.defaultImagePath;
          }
        },
        (error) => {
          console.error('Error loading image:', error);
          this.imagePath = this.defaultImagePath;
        }
      );
    } else {
      console.warn('data.ID is undefined, setting default image path');
      this.imagePath = this.defaultImagePath;
    }
  }

  onImageError() {
    console.log('Error loading image, setting default image path');
    this.imagePath = this.defaultImagePath;
  }
}
