import { Location } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  OnChanges
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/api/services/loader.service';
import { TransparentAdministrationService } from 'src/app/api/services/transparent-administration.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';

import { transparentAdministrationDetailResponseType1 } from 'src/app/shared/messageType';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-transparent-administration-detail',
  templateUrl: './transparent-administration-detail.component.html',
  styleUrls: ['./transparent-administration-detail.component.scss'],
})
export class TransparentAdministrationDetailComponent
  implements OnInit, OnChanges, OnDestroy {
  scrollTop = 0;
  scrolling = false;
  loaderPage = false;
  searchText = '';

  constructor(
    private tas: TransparentAdministrationService,
    private location: Location,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private router: Router
  ) { }

  parentData: any;
  detailInformation: transparentAdministrationDetailResponseType1[];
  detailInformationSearch: transparentAdministrationDetailResponseType1[];
  isSearchFilterRequired = false;

  ngOnChanges() {
    this.parentData = history.state;
  }

  ngOnInit(): void {
    this.parentData = history.state;
    this.location.replaceState(
      'azienda/amministrazione-trasparente/' + (this.parentData.CodicePadre.toLowerCase() ?? ''),
      '',
      history.state
    );
    this.retrieveTransparentAdministrationDetail(null);
  }

  retrieveTransparentAdministrationDetail(filter: any) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    const params = {
      CodicePadre: this.parentData.CodicePadre ?? '',
      NomeFile: this.parentData.NomeFile ?? '',
      published: '1',
      filter: 'Custom',
      order: 'ASC',
      DataIns:
        filter && filter.year && filter.year !== ''
          ? moment([filter.year]).endOf('year').format('YYYY-MM-DD')
          : '',
    };

    this.tas.postTransparentAdministrationDetails(params).subscribe(
      (res) => {
        if (res) {
          this.detailInformation = res.rowCount > 0 ? res.rows : [];

          this.detailInformationSearch = res.rowCount > 0 ? res.rows : [];
          if (!filter) {
            this.isSearchFilterRequired = res.rowCount > 0;
          }
          if (filter && filter.text && filter.text !== '') {
            this.detailInformationSearch.forEach((element) => {
              element.Files = Object.assign([], element.Files).filter(
                (item) =>
                  item.NomeDocumento.toLowerCase().indexOf(
                    filter.text.toLowerCase()
                  ) > -1
              );
            });
            this.detailInformation = this.detailInformationSearch;
            setTimeout(() => {
              this.loaderPage = false;
              this.loaderService.setloader(this.loaderPage);
            }, 1000);
          }

        } else {
          this.detailInformation = [];
          this.detailInformationSearch = [];
        }
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: function () {
            this.router.navigateByUrl('/azienda/amministrazione-trasparente');
          }.bind(this),
        });
      }
    );
  }

  ngOnDestroy() { }

  searchExt(event) {
    this.searchText = event;
    this.filterItem(event);
  }

  filterItem(value) {
    this.loaderPage = true;

    if (value) {
      this.retrieveTransparentAdministrationDetail(value);
    }
  }
}
