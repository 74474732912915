import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  @ViewChild('inputSearch') inputSearch: ElementRef;
  @Input() textPlaceholder: any;
  @Output() searchEvent = new EventEmitter<any>();

  searchText = '';
  errorMessage = null;
  constructor(
    private translate: TranslateService
  ) { }

  ngAfterViewInit(): void {
    this.errorMessage = null;
    this.inputSearch.nativeElement.addEventListener('input', (e: any) => {
      if (e.target.value.trim().length === 0) {
        this.searchEvent.emit(this.searchText.trim());
      }
    });
  }

  ngOnInit(): void {

  }

  search(event) {
    this.errorMessage = null;
    if ((this.searchText.trim().length > 2 || this.searchText.trim() === '')) {
      this.searchEvent.emit(this.searchText.trim());
    }
    else {
      this.errorMessage = this.translate.instant('ALERT_MESSAGE.SEARCH_ERROR_CHAR');
    }
  }

  ngOnDestroy(): void {

  }

}
