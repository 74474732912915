<div class="back-button" (click)="handleBack()">
  <b>
    <svg-icon-sprite classes="icon icon-white" src="../../../assets/sprite.svg#it-arrow-left">
    </svg-icon-sprite>
  </b>
  <span> {{ "BUTTON.BACK" | translate }} </span>
</div>
<app-logo-container></app-logo-container>
<!-- Titolo Pagina -->
<h2 class="title">{{ "Gestione strutture" | translate }}</h2>
<!-- TABELLA -->
<div class="main-container">
  <div class="d-flex flex-column" style="width: 100%; padding: 0 1.5625em">
    <div class="table-container">
      <app-table-documents [documentTypeName]="typologyName" *ngIf="dataSource && dataSource.length > 0" [role]="role"
        [typologyName]="typologyName" [dataSource]="dataSource" [filterOrder]="filterOrder"
        (companyStructuresHandler)="companyStructuresHandler($event)" (actionHandler)="searchBtnPressed($event)"
        [otherCategoryEnable]="false" [otherSubCategoryEnable]="false" (deleteHandler)="didPressDeleteFromTable($event)"
        (changeStatusHandler)="didChangeStatusFromTable($event)" (editHandler)="editModalFromTable($event)">
      </app-table-documents>
      <div>
        <app-paginator [loader]="loaderPage" [list]="dataSource" [isIntoPaginationLimit]="limitPagination"
          [currentPage]="currentPage" [totalNumberOfPages]="totalNumberOfPages" (didClickedOnPage)="updateList($event)">
        </app-paginator>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<app-simple-modal-documental [idModalName]="'companyDocumentalDetailModal'" [documentForm]="documentForm"
  [modalMode]="modalMode" [isReadOnly]="isReadOnly" [showBodyModal]="showBodyModal" [documentsTypes]="documentsTypes"
  [documentToShow]="documentToShow" [selectedRow]="selectedRow" [selectedTypeDocument]="{
    nome_pubblicazione: 'STRUTTURE'
  }" [hasUpload]="false" (createDocumentHandler)="createDocumentHandler($event)"
  [mainTitleModal]="modalMode === 'create' ? 'COMPANY_DOCUMENTAL.ADD_MODAL_STRUCTURE_TITLE' : 'COMPANY_DOCUMENTAL.EDIT_MODAL_STRUCTURE_TITLE'"
  [confirmText]="modalMode === 'create' ? 'COMPANY_DOCUMENTAL.ADD_MODAL_STRUCTURE_TITLE' : 'COMPANY_DOCUMENTAL.EDIT_MODAL_STRUCTURE_TITLE'"
  (editDocumentHandler)="editDocumentHandler($event)"
  (deleteFileHandler)="deleteDocumentHandler($event)"></app-simple-modal-documental>

<!-- ADD  -->
<app-button-add-documental (actionHandler)="buttonAddHandler($event)"
  [buttonText]="'COMPANY_DOCUMENTAL.ADD_MODAL_STRUCTURE_TITLE'" [typeDocumental]="{nome_pubblicazione: 'STRUTTURE'}"
  [typeName]="'STRUTTURE'">
</app-button-add-documental>