<div class="detail_location">
    <div class="information">
        <p class="nome">{{details.Titolo}}</p>
        <p class="nome">{{details.ORG_DES}} 
            </p>
        <p >{{details.Luogo_Dipartimento}} 
        </p>
            
    </div>
</div>
