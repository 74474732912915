<div class="department_Card">
  <div class="bg">
    <img src="../../../assets/Dipartimenti.svg" alt="department_Card" />
  </div>
  <p *ngIf="data" role="region" aria-label="data.titolo" class="title">
    {{ data.Titolo ? data.Titolo : data.ORG_DES }}
  </p>
  <a *ngIf="data" tabindex="0" role="navigation" aria-label="leggi-di-piu"
    [routerLink]="['/departments/dettaglio', this.data.ID]" class="btn vai-alla-pagina">{{ "BUTTON.READ_MORE" |
    translate }}</a>
</div>