import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accessibility-button',
  templateUrl: './accessibility-button.component.html',
  styleUrls: ['./accessibility-button.component.scss'],
})
export class AccessibilityButtonComponent implements OnInit {
  isAccessibilityModalOpen = false;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.onClickAnim();
  }

  onClickAnim() {
    if (this.isAccessibilityModalOpen) {
      this.isAccessibilityModalOpen = false;
      document.getElementById('main-wrapper').style.right = '0px';
    } else {
      this.isAccessibilityModalOpen = true;
      document.getElementById('main-wrapper').style.right = '-195px';
    }
  }

  setIncreasedFontSize() {
    if (document.body.classList.contains('f-12')) {
      document.body.classList.remove('f-12');
      document.body.classList.add('f-13');
      return;
    }
    if (document.body.classList.contains('f-13')) {
      document.body.classList.remove('f-13');
      document.body.classList.add('f-14');
      return;
    }
    if (document.body.classList.contains('f-14')) {
      document.body.classList.remove('f-14');
      document.body.classList.add('f-15');
      return;
    }
    if (document.body.classList.contains('f-15')) {
      document.body.classList.remove('f-15');
      document.body.classList.add('f-16');
      return;
    }
    if (document.body.classList.contains('f-16')) {
      document.body.classList.remove('f-16');
      document.body.classList.add('f-17');
      return;
    }
    if (document.body.classList.contains('f-17')) {
      document.body.classList.remove('f-17');
      document.body.classList.add('f-18');
      return;
    }
  }

  setDecreasedFontSize() {
    if (document.body.classList.contains('f-18')) {
      document.body.classList.remove('f-18');
      document.body.classList.add('f-17');
      return;
    }
    if (document.body.classList.contains('f-17')) {
      document.body.classList.remove('f-17');
      document.body.classList.add('f-16');
      return;
    }
    if (document.body.classList.contains('f-16')) {
      document.body.classList.remove('f-16');
      document.body.classList.add('f-15');
      return;
    }
    if (document.body.classList.contains('f-15')) {
      document.body.classList.remove('f-15');
      document.body.classList.add('f-14');
      return;
    }
    if (document.body.classList.contains('f-14')) {
      document.body.classList.remove('f-14');
      document.body.classList.add('f-13');
      return;
    }
    if (document.body.classList.contains('f-13')) {
      document.body.classList.remove('f-13');
      document.body.classList.add('f-12');
      return;
    }
  }

  checkAccessibility(name) {
    if (name === 'f-zoom-in' || name === 'f-zoom-out') {
      if (document.body.classList.contains('f-15')) {
        return false;
      } else if (
        name === 'f-zoom-in' &&
        (document.body.classList.contains('f-16') ||
          document.body.classList.contains('f-17') ||
          document.body.classList.contains('f-18'))
      ) {
        return true;
      } else if (
        name === 'f-zoom-out' &&
        (document.body.classList.contains('f-14') ||
          document.body.classList.contains('f-13') ||
          document.body.classList.contains('f-12'))
      ) {
        return true;
      }
    }

    return document.body.classList.contains(name);
  }

  setReadableFont() {
    document.body.classList.contains('readable-font')
      ? document.body.classList.remove('readable-font')
      : document.body.classList.add('readable-font');
  }

  setGrayscale() {
    document.body.classList.contains('grayscale')
      ? document.body.classList.remove('grayscale')
      : document.body.classList.add('grayscale');
  }

  setNegativeContrast() {
    document.body.classList.contains('negative-contrast')
      ? document.body.classList.remove('negative-contrast')
      : document.body.classList.add('negative-contrast');
  }

  setHighContrast() {
    document.body.classList.contains('high-contrast-accessibility')
      ? document.body.classList.remove('high-contrast-accessibility')
      : document.body.classList.add('high-contrast-accessibility');
  }

  setLightBackground() {
    document.body.classList.contains('light-background-accessibility')
      ? document.body.classList.remove('light-background-accessibility')
      : document.body.classList.add('light-background-accessibility');
  }

  setLinksUnderlined() {
    document.body.classList.contains('links-underline')
      ? document.body.classList.remove('links-underline')
      : document.body.classList.add('links-underline');
  }

  restoreAccessibility() {
    document.body.classList.remove('light-background-accessibility');
    document.body.classList.remove('readable-font');
    document.body.classList.remove('links-underline');
    document.body.classList.remove('high-contrast-accessibility');
    document.body.classList.remove('grayscale');
    document.body.classList.remove('negative-contrast');
    document.body.classList.remove('f-12');
    document.body.classList.remove('f-13');
    document.body.classList.remove('f-14');
    document.body.classList.remove('f-15');
    document.body.classList.remove('f-16');
    document.body.classList.remove('f-17');
    document.body.classList.remove('f-18');
    document.body.classList.add('f-15');
  }
}
