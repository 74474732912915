export class BreadcrumbItem {
  name: string;
  link: string;
  disabled?: boolean;

  constructor(aName: string, aLink: string, disabled?: boolean) {
    this.name = aName;
    this.link = aLink;
    this.disabled = disabled;
  }
}
