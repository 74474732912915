import { Component, Input, OnInit } from '@angular/core';
import { HeadPageData } from 'src/app/shared/models/HeadPageData';




@Component({
  selector: 'app-page-head',
  templateUrl: './page_head.component.html',
  styleUrls: ['./page_head.component.scss']
})

export class PageHeadComponent implements OnInit {

  @Input() data: HeadPageData;
  constructor() { }

  ngOnInit(): void {
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }
}
