<!-- HEAD -->
<app-main-head
  [isSearchRequired]="true"
  [breadcrumbItems]="breadcrumbItems"
  [bodyList]="headNewsList"
  [mainTitle]="'NOTICE_BOARDS.NEWS'"
  [textPlaceholderSearch]="'NOTICE_BOARDS.NEWS'"
  [rightListPanelText]="'NOTICE_BOARDS.NEWS_IN_EVIDENCE'"
  [rightAllPanelText]="'NOTICE_BOARDS.ALL_NEWS'"
  [redirectText]="'all-news'"
  (externalSearchEvent)="searchExt($event)"
  [isPermalink]="true"
  [isRightPanelVisible]="true"
  [typology]="'news'"
>
</app-main-head>
<!-- END HEAD -->
<div class="wrapper">
  <!-- IN EVIDENCE -->
  <app-in-evidence-body
    [bodyList]="evidencedNotices"
    [titleText]="'NOTICE_BOARDS.NEWS_IN_EVIDENCE'"
    *ngIf="
      ((!searchText && searchText != '') || searchText == '') && !loaderPage
    "
    [typology]="'news'"
  ></app-in-evidence-body>
  <!-- END IN EVIDENCE -->

  <!-- ALL -->
  <app-main-all-body
    *ngIf="!loaderPage"
    id="all-news"
    [typology]="'news'"
    [mainTitle]="'NOTICE_BOARDS.ALL_NEWS'"
    [bodyList]="newsList"
  >
  </app-main-all-body>
  <!-- END ALL -->
</div>

<div
  *ngIf="((!searchText && searchText != '') || searchText == '') && !loaderPage"
>
  <app-paginator
    [loader]="loaderPage"
    [list]="newsList"
    [isIntoPaginationLimit]="limitPagination"
    [currentPage]="currentPage"
    [totalNumberOfPages]="pagesNumber"
    (didClickedOnPage)="updateList($event)"
  >
  </app-paginator>
</div>
