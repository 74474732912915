import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CampaniaPancreasType } from 'src/app/shared/messageType';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root'
})
export class CampaniaPancreasService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly PostSearchPath = '/api/v1/cam_pancreas/_search';
  static readonly PostInsertPath = '/api/v1/cam_pancreas/_insert';
  static readonly PutUpdatePath = '/api/v1/cam_pancreas/_update';
  static readonly PostDeletePath = '/api/v1/cam_pancreas/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/cam_pancreas/_update_publish';

  /*
  * SEARCH
  */
  postSearchCampaniaPancreas$Response(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>> {

    const rb = new RequestBuilder(this.rootUrl, CampaniaPancreasService.PostSearchPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>;
      })
    );

  }

  postSearchCampaniaPancreas(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }> {

    return this.postSearchCampaniaPancreas$Response(body, params).pipe(
      map((r: StrictHttpResponse<{
        'rowCount'?: number, 'message'?: string | null,
        'rows'?: Array<CampaniaPancreasType>
      }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> })
    );
  }

  /*
  * INSERT
  */
  postInsertCampaniaPancreas$Response(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>> {

    const rb = new RequestBuilder(this.rootUrl, CampaniaPancreasService.PostInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>;
      })
    );

  }
  postInsertCampaniaPancreas(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }> {

    return this.postInsertCampaniaPancreas$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> })
    );
  }

  /*
* UPDATE 
*/
  updateCampaniaPancreas$Response(params?: {
    cam_id: string
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, CampaniaPancreasService.PutUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  updateCampaniaPancreas(params?: {
    cam_id: string
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }> {

    return this.updateCampaniaPancreas$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<CampaniaPancreasType> })
    );
  }

  /*
 * DELETE
 */
  DeleteCampaniaPancreas$Response(params?: {
    cam_id: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, CampaniaPancreasService.PostDeletePath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  DeleteCampaniaPancreas(params?: {
    cam_id: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteCampaniaPancreas$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
* UPDATE PUBLISH
*/
  UpdatePublishCampaniaPancreas$Response(params?: {
    cam_id: string,
    published: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, CampaniaPancreasService.PutUpdatePublishPath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  UpdatePublishCampaniaPancreas(params?: {
    cam_id: string,
    published: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.UpdatePublishCampaniaPancreas$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

}
