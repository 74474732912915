import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttiNotificaService } from 'src/app/api/services/atti-notifica.service';
import { DownloadService } from 'src/app/api/services/download.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { IS_MOBILE } from 'src/app/shared/models/constants';

import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';

@Component({
  selector: 'app-atti-notifica',
  templateUrl: './atti-notifica.component.html',
  styleUrls: ['./atti-notifica.component.scss'],
})
export class AttiNotificaComponent implements OnInit {
  typologyName = TypeOperationEnum.ATTI_DI_NOTIFICA;
  pageLimit = window.screen.width > 1200 ? 10 : 5;
  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.ACTS_OF_NOTIFICATION', ''),
  ];
  mobile = IS_MOBILE;
  addID = 'A';

  postType = DocumentPostType.ATTI_NOT

  constructor(
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private attiNotificaService: AttiNotificaService,
    private downloadService: DownloadService
  ) { }

  currentPage = 1;
  loaderPage = false;
  limitPagination = true;
  totalNumberOfPages: number;
  numberOfResults = 0;
  detailInfo: any[];
  ngOnInit(): void {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';
    this.attiNotificaService
      .postSearchAttiNotifica(
        { ...body },
        { page: this.currentPage, limit: this.pageLimit }
      )
      .subscribe(
        (result) => {
          this.detailInfo = result.rowCount > 0 ? result.rows : [];
          this.loaderPage = false;
          this.limitPagination = !(result.rowCount <= this.pageLimit);
          this.numberOfResults = result.rowCount;
          this.loaderService.setloader(this.loaderPage);
          this.totalNumberOfPages =
            Math.floor(this.numberOfResults / this.pageLimit) +
            (this.numberOfResults % this.pageLimit === 0 ? 0 : 1);
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => { },
          });
        }
      );
  }

  public downloadFile(doc) {

    const postID = doc.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      this.postType +
      DownloadService.PostDownload2Path +
      this.postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(doc.Filename, url);
  }

  public redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
  updateList(value: number) {
    this.currentPage = value;
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.onChangePage();
  }
  onChangePage() {
    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';
    this.attiNotificaService
      .postSearchAttiNotifica(
        { ...body },
        { page: this.currentPage, limit: this.pageLimit }
      )
      .subscribe((result) => {
        this.detailInfo = result.rowCount > 0 ? result.rows : [];
      });
  }
}
