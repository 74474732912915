import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsService } from 'src/app/pages/back-office/documents/documents.service';
import { ModalModeType } from 'src/app/shared/enums/modalModeType';
import { StructureTypeEnum } from 'src/app/shared/enums/structureTypeEnum';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';
import { GenericInfoModalService } from '../generic-info-modal/generic-info-modal.service';
declare var $: any;

interface ImageSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-simple-modal-documental',
  templateUrl: './simple-modal-documental.component.html',
  styleUrls: ['./simple-modal-documental.component.scss'],
})
export class SimpleModalDocumentalComponent implements OnInit, OnChanges {
  @Input() mainTitleModal = 'DOCUMENTS.ADD';
  @Input() confirmText = 'DOCUMENTS.ADD';
  // Titolo Bottone Edit

  @Input() isReadOnly = true;
  @Input() modalMode: ModalModeType;
  @Input() documentForm;

  @Input() showBodyModal = false;
  @Input() selectedTypeDocument: any = null;
  @Input() documentsTypes = [];
  @Input() documentToShow: TypeOperationEnum;
  @Input() selectEditNotices;
  @Input() selectedRow: undefined;
  @Input() privacyList = [];
  @Input() idModalName = 'documentsModal';
  @Input() hasUpload = true;
  @Input() hasBaseSixtyfourUpload = false;

  @Input() showType: boolean = true;

  @Input() structureType: StructureTypeEnum;

  // FILES
  @Input() fileUploading: boolean;
  @Input() filesUploaded = [];

  @Output() createDocumentHandler = new EventEmitter();
  @Output() editDocumentHandler = new EventEmitter();
  @Output() deleteFileHandler = new EventEmitter();

  newFilesUploaded = [];

  showButtonFile = false;

  errorFileString = undefined;

  get documentType() {
    return this.documentForm.controls.type;
  }
  get attributes(): FormArray {
    return <FormArray>this.documentForm.get('attributes');
  }

  constructor(
    private translate: TranslateService,
    private documentService: DocumentsService,
    private _sanitizer: DomSanitizer,
    private _infoModal: GenericInfoModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showBodyModal === true) {
      this.showButtonFile = true;
    }
  }

  ngOnInit(): void {}

  getModalTitle() {
    if (this.modalMode === ModalModeType.CREATE)
      return this.translate.instant(this.mainTitleModal);
    else if (this.isReadOnly) {
      return this.readOnlyCheckType();
    } else return this.translate.instant(this.mainTitleModal);
  }

  //metodo che determina il titolo da visualizzare nella modale quando siamo in modalita' sola lettura
  readOnlyCheckType() {
    switch (this.selectedTypeDocument.nome_pubblicazione) {
      case TypeOperationEnum.HOME_CAROUSEL:
        return this.translate.instant('HOME_DOCUMENTAL.HOME_VIEW_TITLE');
      default:
        return this.translate.instant('DOCUMENTS.READ');
    }
  }

  getTypologyNameFromNameAvailability() {
    if (
      (this.selectedTypeDocument.nome_pubblicazione &&
        this.selectedTypeDocument.nome_pubblicazione ===
          TypeOperationEnum.BANDO) ||
      this.selectedTypeDocument.nome_pubblicazione ===
        TypeOperationEnum.CONCORSI
    ) {
      if (this.modalMode === ModalModeType.EDIT) {
        if (
          this.selectedTypeDocument.nome_pubblicazione ===
            TypeOperationEnum.CONCORSI &&
          this.documentsTypes.filter(
            (e) => e.nome_pubblicazione === TypeOperationEnum.BANDO
          ).length > 0
        ) {
          return ModalModeType.SELECT_NOTICES;
        }
        if (
          this.selectedTypeDocument &&
          this.selectedTypeDocument.nome_pubblicazione ===
            TypeOperationEnum.BANDO &&
          this.documentsTypes.filter(
            (e) => e.nome_pubblicazione === TypeOperationEnum.CONCORSI
          ).length > 0
        ) {
          return ModalModeType.SELECT_NOTICES;
        }
        return ModalModeType.READ_ONLY;
      } else {
        return ModalModeType.READ_ONLY;
      }
    }
    return ModalModeType.READ_ONLY;
  }

  getNameTitle(name) {
    switch (name) {
      case TypeOperationEnum.HOME_CAROUSEL:
        return this.translate.instant('HOME_DOCUMENTAL.TITLE_MODAL');
      case TypeOperationEnum.CAMPANIA_PANCREAS:
        return this.translate.instant('NAVBAR_TAB.PANCREAS');
      case TypeOperationEnum.CIO:
        return this.translate.instant('NAVBAR_TAB.CIO');
      case TypeOperationEnum.COMITATO_ETICO:
        return this.translate.instant('NAVBAR_TAB.ETHICS_COMMITEE');
      case TypeOperationEnum.BANDO:
        return this.translate.instant('HOME_SUBVIEWS.TENDERS');
      case TypeOperationEnum.CONCORSI:
        return this.translate.instant('HOME_SUBVIEWS.NOTICES');
      case TypeOperationEnum.ATTI_DI_NOTIFICA:
        return this.translate.instant('HOME_SUBVIEWS.ACTS_OF_NOTIFICATION');
      case TypeOperationEnum.PRIVACY:
        return this.translate.instant('NAVBAR_TAB.PRIVACY');
        case TypeOperationEnum.PROCAZIENDALI:
        return this.translate.instant('NAVBAR_TAB.PROCEDURE_AZIENDALI');
        case TypeOperationEnum.RLS:
          return this.translate.instant('NAVBAR_TAB.RLS');
          case TypeOperationEnum.MANUALE_QUALITA:
            return this.translate.instant('NAVBAR_TAB.MANUALE_QUALITA');
      case TypeOperationEnum.DIPARTIMENTI_UNITA_OPERATIVE:
        switch (this.structureType) {
          case StructureTypeEnum.UOC:
            return this.translate.instant('DEPARTMENTS.UOC_MANAGEMENT');
          case StructureTypeEnum.UOS:
            return this.translate.instant('DEPARTMENTS.UOS_MANAGEMENT');
          case StructureTypeEnum.UOSD:
            return this.translate.instant('DEPARTMENTS.UOSD_MANAGEMENT');
          case StructureTypeEnum.PROGRAMMI:
            return this.translate.instant('DEPARTMENTS.PROGRAMS_MANAGEMENT');
          default:
            return '';
        }
      default:
        return name;
    }
  }

  onChange(event, control, index) {
    if (
      event.target.files &&
      ((this.filesUploaded &&
        this.filesUploaded.length < 1 &&
        this.documentToShow !== TypeOperationEnum.BANDO &&
        this.documentToShow !== TypeOperationEnum.CONCORSI) ||
        this.documentToShow === TypeOperationEnum.BANDO ||
        this.documentToShow === TypeOperationEnum.CONCORSI)
    ) {
      const arrayFiles = [...event.target.files];
      arrayFiles.forEach((file) => {
        if (
          this.documentService
            .getAcceptedFile(this.documentToShow)
            .indexOf(file.type) >= 0 &&
          this.filesUploaded &&
          !this.filesUploaded.find((elem) => elem.name === file.name)
        ) {
          if (
            this.hasBaseSixtyfourUpload &&
            this.selectedTypeDocument.nome_pubblicazione ===
              TypeOperationEnum.HOME_CAROUSEL
          ) {
            if (!this.documentService.checkFileSize(file, 1)) {
              this.errorFileString = this.translate.instant(
                'ALERT_MESSAGE.SIZE_IMAGE_ERROR',
                { value1: 1 }
              );
              setTimeout(() => {
                this.errorFileString = undefined;
              }, 5000);
              return;
            } else {
              const url = URL.createObjectURL(file);
              const img = new Image();
              img.src = url;
              img.onload = () => {
                const width = img.width;
                const height = img.height;
                const image: ImageSize = { width: width, height: height };
                if (this.isBannerSize(image)) {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const fileRes: string = reader.result as string;
                    const imagePath = this._sanitizer.sanitize(
                      SecurityContext.RESOURCE_URL,
                      this._sanitizer.bypassSecurityTrustResourceUrl(fileRes)
                    );
                    file.imagePath = imagePath;
                    this.filesUploaded.push(file);
                  };
                } else {
                  this.errorFileString = this.translate.instant(
                    'ALERT_MESSAGE.DIMENSION_IMAGE_ERROR'
                  );
                  setTimeout(() => {
                    this.errorFileString = undefined;
                  }, 5000);
                }
              };
            }
          } else {
            this.filesUploaded.push(file);
            if (this.modalMode === ModalModeType.EDIT) {
              this.newFilesUploaded.push(file);
            }
          }
        } else if (!this.filesUploaded) {
          this.filesUploaded = [];
          this.newFilesUploaded = [];
          this.filesUploaded.push(arrayFiles[0]);
        } else {
        }
      });
    }
    if (control.name === 'start_date' && !control.value) {
      (this.attributes.controls[index] as FormGroup).controls.end_date.setValue(
        ''
      );
    }
  }

  getControls(index) {
    const group = this.attributes.controls[index] as FormGroup;
    const array = [];
    for (let key in group.controls) {
      array.push(group.controls[key]);
    }
    return array;
  }

  onChangeSelectNotices(event) {
    if (
      this.getNameTitle(this.documentForm.value.type) ===
      this.getNameTitle(event.target.value)
    ) {
      return;
    }
    this.selectEditNotices = event.target.value;
  }

  isCounterAvailable(control) {
    return (
      !this.isReadOnly &&
      control.type === 'text' &&
      this.documentService.showCounter(control)
    );
  }

  checkAddFilesIsDisabled(filesUploaded, documentToShow) {
    if (documentToShow === TypeOperationEnum.HOME_CAROUSEL) {
      return filesUploaded && filesUploaded.length > 0;
    } else if (
      documentToShow === TypeOperationEnum.BANDO ||
      documentToShow === TypeOperationEnum.CONCORSI
    ) {
      return filesUploaded && filesUploaded.length >= 4;
    } else if (filesUploaded && filesUploaded.length >= 1) {
      return true;
    }
    return false;
  }

  removeFile(element, nome_pubblicazione) {
    if (this.modalMode === ModalModeType.CREATE) {
      this.filesUploaded = this.filesUploaded.filter(
        (elem) => element.name !== elem.name
      );
    } else {
      if (
        this.modalMode === ModalModeType.EDIT &&
        (this.selectedTypeDocument.nome_pubblicazione ===
          TypeOperationEnum.DIPARTIMENTI ||
          this.selectedTypeDocument.nome_pubblicazione ===
            TypeOperationEnum.HOME_CAROUSEL)
      ) {
        this.filesUploaded = [];
      } else {
        this.deleteFileHandler.emit({
          element: element,
          nome_pubblicazione: nome_pubblicazione,
        });
      }
    }
  }

  // * Check Privacy select
  getPrivacyFolderID(event) {
    return event.value;
  }
  isSelectedPrivacyFolder(event, category) {
    return event.value === category.Nome;
  }

  checkDisableFormModal() {
    return (
      this.documentForm.valid &&
      ((this.filesUploaded && this.filesUploaded.length > 0) ||
        this.fileUploading ||
        !this.hasUpload)
    );
  }

  /**
   * CREATE DOCUMENT MODAL ACTIVATION
   */
  onSubmitModalAdd() {
    const documentDTO = {
      type: this.documentForm.value.type,
      ...this.documentForm.value.attributes[0],
      documents: this.filesUploaded,
    };
    if (this.modalMode === ModalModeType.CREATE) {
      const file = this.filesUploaded[0];
      if (file) {
        const reader = new FileReader();
        try {
          // * Base 64 case
          if (this.hasBaseSixtyfourUpload) {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const fileRes = reader.result;
              const createModalType = ModalModeType.CREATE;
              const selectedRow = this.selectedRow;
              const value = {
                documentDTO,
                fileRes,
                selectedRow,
                createModalType,
              };
              this.createDocumentHandler.emit(value);
            };
          } else {
            // file uploaded in string format
            reader.readAsBinaryString(file);
            reader.onload = (event) => {
              return event.target.result;
            };
            reader.onloadend = (event) => {
              const fileRes = event.target.result;
              const createModalType = ModalModeType.CREATE;
              const selectedRow = this.selectedRow;
              const value = {
                documentDTO,
                fileRes,
                selectedRow,
                createModalType,
              };
              this.createDocumentHandler.emit(value);
            };
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        const createModalType = ModalModeType.CREATE;
        const selectedRow = this.selectedRow;
        const value = { documentDTO, undefined, selectedRow, createModalType };
        this.createDocumentHandler.emit(value);
      }
    }
    $('#' + this.idModalName).modal('toggle');
  }

  onSubmitModalEdit() {
    const documentDTO = {
      type: this.documentForm.value.type,
      ...this.documentForm.value.attributes[0],
      documents: this.filesUploaded,
    };
    var file = this.filesUploaded[0];
    if (this.hasBaseSixtyfourUpload) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileRes = reader.result;
        const createModalType = ModalModeType.EDIT;
        const selectedRow = this.selectedRow;
        const value = {
          documentDTO,
          fileRes,
          selectedRow,
          createModalType,
        };
        this.editDocumentHandler.emit(value);
      };
    } else if (this.hasUpload && !this.fileUploading && !file.docfile) {
      if (file) {
        const reader = new FileReader();
        try {
          reader.readAsBinaryString(file);
          reader.onload = (event) => {
            return event.target.result;
          };
          reader.onloadend = (event) => {
            const fileRes = event.target.result
              ? event.target.result
              : file.docfile;
            const editModalType = ModalModeType.EDIT;
            const selectedRow = this.selectedRow;
            const value = { documentDTO, fileRes, selectedRow, editModalType };
            this.editDocumentHandler.emit(value);
          };
        } catch (e) {
          console.log(e);
        }
      } else {
        const editModalType = ModalModeType.EDIT;
        const selectedRow = this.selectedRow;
        const value = { documentDTO, undefined, selectedRow, editModalType };
        this.editDocumentHandler.emit(value);
      }
    } else {
      if (this.hasUpload && file.docfile && file.docfile !== '') {
        const reader = new FileReader();
        reader.readAsBinaryString(file.docfile);
        reader.onload = (event) => {
          return event.target.result;
        };
        reader.onloadend = (event) => {
          const fileRes = event.target.result
            ? event.target.result
            : file.docfile;
          const editModalType = ModalModeType.EDIT;
          const selectedRow = this.selectedRow;
          const value = { documentDTO, fileRes, selectedRow, editModalType };
          this.editDocumentHandler.emit(value);
        };
      } else {
        const editModalType = ModalModeType.EDIT;
        const selectedRow = this.selectedRow;
        const value = { documentDTO, undefined, selectedRow, editModalType };
        this.editDocumentHandler.emit(value);
      }
    }
    $('#' + this.idModalName).modal('toggle');
  }

  checkTrashbinAvailable() {
    return (
      this.selectedTypeDocument.nome_pubblicazione ===
        TypeOperationEnum.CAMPANIA_PANCREAS ||
      this.selectedTypeDocument.nome_pubblicazione === TypeOperationEnum.CIO ||
      this.selectedTypeDocument.nome_pubblicazione ===
        TypeOperationEnum.ATTI_DI_NOTIFICA ||
      this.selectedTypeDocument.nome_pubblicazione === TypeOperationEnum.PRIVACY
    );
  }

  isBannerSize(imageSize: ImageSize) {
    const bannerWidthThreshold = this.documentService.getBannerWidthThreshold();
    const bannerHeightThreshold =
      this.documentService.getBannerHeightThreshold();
    return (
      imageSize.width >= bannerWidthThreshold &&
      imageSize.height >= bannerHeightThreshold &&
      imageSize.width > imageSize.height
    );
  }
}
