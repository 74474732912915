<ng-container *ngIf="checkIfSectionShouldBeVisible()">
  <app-header [navItems]="navItems"></app-header>
</ng-container>

<router-outlet></router-outlet>

<app-generic-info-modal></app-generic-info-modal>
<app-generic-dual-choice-modal></app-generic-dual-choice-modal>

<app-loader></app-loader>

<ng-container *ngIf="checkIfSectionShouldBeVisible()">
  <app-footer
    class="footer"
    [companyNavItems]="companyNavItems"
    [riskManagementNavItems]="riskManagementNavItems"
    [administrationNavItems]="administrationNavItems"
  ></app-footer>
</ng-container>
<svg-icon-sprite src="../../../assets/sprite.svg"></svg-icon-sprite>
