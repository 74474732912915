import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/api/services/download.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { RiskService } from 'src/app/api/services/risk.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';

import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss'],
})
export class RiskComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
    new BreadcrumbItem('NAVBAR_TAB.RISK_MANAGEMENT', ''),
  ];
  constructor(
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private downloadService: DownloadService,
    private riskService: RiskService
  ) {}

  public loaderPage = false;
  public riskList = [];
  public ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.callSearchFoldersRisk();
  }
  callSearchFoldersRisk() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.riskService.postSearchFoldersRisk().subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.riskList = res.rows;
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => {},
        });
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      }
    );
  }

  public downloadFile(doc) {
    const postType = DocumentPostType.RISK_MANAGER;
    const postID = doc.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(doc.Filename, url);
  }

  public redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
