import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { CompanyService } from 'src/app/api/services/company.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  IDStruttura: any;
  breadcrumbItems: BreadcrumbItem[];
  detailInformation: any;

  companyInfo: any;
  previousUrl: string;
  decodeURL: string;
  constructor(
    private route: ActivatedRoute,
    public cs: CompanyService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.IDStruttura = this.route.snapshot.params.id;

    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        this.previousUrl = e[0].urlAfterRedirects;
        this.decodeURL = decodeURIComponent(this.previousUrl);
      });

    this.cs
      .structureMoreInfoSearch({ ID: this.IDStruttura })
      .subscribe((res) => {
        res.rows.forEach((element) => {
          this.detailInformation = JSON.parse(element.Json_info);
          this.breadcrumbItems = [
            new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
            new BreadcrumbItem('Azienda', '/azienda'),
            // new BreadcrumbItem('Strutture', '/company-read-more'),
            new BreadcrumbItem(this.detailInformation?.sections[0].title, ''),
          ];
        });
      });
  }
  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
