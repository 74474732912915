import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class GenericInfoModalService {
    private _modalData: {
        show: boolean,
        type: string,
        title: string,
        bodyText: string,
        actionLabel: string,
        actionFunction: Function,
    } = { show: false, type: 'info', title: '', bodyText: '', actionLabel: '', actionFunction: undefined };
    modalInfoStatus = new BehaviorSubject(this._modalData);

    constructor() {
    }

    getModal(): { show: boolean, type: string, title: string, bodyText: string, actionLabel: string, actionFunction: Function } {
        return this._modalData;
    }

    setModal(value) {
        this._modalData = value;
        this.modalInfoStatus.next(value);
    }

}