<div class="carousel-wrapper">
  <div id="carouselCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li
        *ngFor="let slide of carouselList; let i = index"
        data-target="#carouselCaptions"
        [attr.data-slide-to]="i"
        [class.active]="i == 0"
      ></li>
    </ol>
    <div class="carousel-inner">
      <div
        *ngFor="let slide of carouselList; let i = index"
        class="carousel-item"
        [class.active]="i == 0"
      >
        <a [attr.href]="slide.Link" target="_blank">
          <img
            src="{{ slide.Source }}"
            class="d-block w-100"
            alt="{{ slide.Titolo }}"
          />
          <div class="carousel-caption">
            <h5 class="title">{{ slide.Titolo }}</h5>
          </div>
        </a>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselCaptions"
      role="button"
      data-slide="prev"
    >
      <div class="icon-container">
        <svg-icon-sprite
          classes="icon icon-white"
          src="../../../assets/sprite.svg#it-chevron-left"
        >
        </svg-icon-sprite>
      </div>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselCaptions"
      role="button"
      data-slide="next"
    >
      <div class="icon-container">
        <svg-icon-sprite
          classes="icon icon-white"
          src="../../../assets/sprite.svg#it-chevron-right"
        >
        </svg-icon-sprite>
      </div>
    </a>
  </div>
</div>
