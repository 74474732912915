import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-departments-detail-location',
  templateUrl: './departments_detail_location.component.html',
  styleUrls: [
    './departments_detail_location.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsDetailLocationComponent implements OnInit {
  @Input() details;

  constructor() {}

  ngOnInit(): void {}
}
