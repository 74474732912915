import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss'],
})
export class HomeCarouselComponent implements OnInit {
  @Input() carouselList = [];

  // Modificare se vogliamo cambiare le impostazioni del carousel
  carouselSettings: object = {
    interval: 5000,
    pause: 'hover',
    keyboard: true,
    ride: true,
  };

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.carouselBehaviour();
  }

  // si definisce il comportamento del carousel
  carouselBehaviour() {
    if (this.carouselList) {
      $('.carousel').carousel(this.carouselSettings);
    }
  }
}
