import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';

@Component({
  selector: 'app-main-accordion-table',
  templateUrl: './main-accordion-table.component.html',
  styleUrls: ['./main-accordion-table.component.scss'],
})
export class MainAccordionTableComponent implements OnInit {
  constructor(private translateService: TranslateService) { }

  //
  @Input() dataSource: any;
  @Input() typology: TypeOperationEnum;
  @Input() hasAttachment: boolean = false;
  @Input() hasDetail: boolean = false;
  //
  @Output() downloadHandler = new EventEmitter();
  @Output() detailHandler = new EventEmitter();

  addID = 'A';

  ngOnInit(): void { }

  //
  downloadFile(event) {
    this.downloadHandler.emit(event);
  }
  //
  showDetail(event) {
    this.detailHandler.emit(event);
  }
  //
  createInformation(element) {
    switch (this.typology) {
      case TypeOperationEnum.CONCORSI:
        return [
          {
            title: this.translateService.instant('NOTICES.NAME'),
            value: element.titolo,
          },
          {
            title: this.translateService.instant('NOTICES.VALID_FROM'),
            value: moment(new Date(element.iniziovalidita)).format(
              'DD MMM YYYY'
            ),
          },
        ];
      case TypeOperationEnum.DELIBERA:
      case TypeOperationEnum.DETERMINA:
        return [
          {
            title: this.translateService.instant('ALBO_PRETORIO.OGGETTO'),
            value: element.titolo,
          },
          {
            title: this.translateService.instant('ALBO_PRETORIO.NUMERO'),
            value: element.numero,
          },
          {
            title: this.translateService.instant('ALBO_PRETORIO.START_DATE'),
            value: moment(element.datainizio, 'YYYY-MM-DD').format('DD MMMM YYYY')
          }
        ];
      case TypeOperationEnum.BANDO:
        return [
          {
            title: this.translateService.instant('TENDERS.NAME'),
            value: element.titolo,
          },
          {
            title: this.translateService.instant('TENDERS.VALID_FROM'),
            value: moment(new Date(element.iniziovalidita)).format(
              'DD MMM YYYY'
            ),
          },
        ];
      case TypeOperationEnum.ATTI_DI_NOTIFICA:
        return [
          {
            title: this.translateService.instant('USER_MANAGEMENT.USER_NAME'),
            value: element.Titolo,
          },
        ];
      default:
        return [];
    }
  }
}
