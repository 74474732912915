import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GenericInfoModalService } from './generic-info-modal.service';
declare var $: any;
@Component({
  selector: 'app-generic-info-modal',
  templateUrl: './generic-info-modal.component.html',
  styleUrls: ['./generic-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericInfoModalComponent implements OnInit, OnDestroy {
  show: boolean = false;
  type: string = 'info';
  titleHeader: string;
  bodyText: string;
  actionLabel: string;
  modalInfoSubscription: Subscription;
  actionFunction: Function;

  constructor(
    private modalService: GenericInfoModalService, private translate: TranslateService
  ) { }

  ngOnInit() {
    this.modalInfoSubscription = this.modalService.modalInfoStatus.pipe(debounceTime(200)
    ).subscribe((value) => {
      this.show = value.show;
      this.type = value.type;
      this.titleHeader = value.title;
      this.bodyText = value.bodyText;
      this.actionLabel = value.actionLabel;
      this.actionFunction = value.actionFunction;
      if (this.show) {
        $('#genericInfoModal').modal('show').scrollTop(0);
      }
    });
  }

  doAction1() {
    this.actionFunction ? this.actionFunction() : undefined;
  }


  ngOnDestroy() {
    this.modalInfoSubscription.unsubscribe();
  }
}
