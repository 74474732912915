<div class="detail_person">
  <img [src]="imagePath" alt="person" (error)="onImageError()" />
  <div class="information">
    <p class="role">{{ data.TPN_DES ? data.TPN_DES : "" }}</p>
    <p class="text">
      {{
        data.Nome_Direttore
          ? data.Nome_Direttore
          : data.PER_COG + " " + data.PER_NOM
      }}
    </p>

    <div *ngIf="data.PER_TEL" class="phone_number">
      <a href="tel:{{ data.PER_TEL }}" class="number">{{
        data.PER_TEL ? data.PER_TEL : ""
      }}</a>
    </div>

    <a
      *ngIf="data.Email_Direttore || data.PER_EMA"
      href="mailto:{{
        data.Email_Direttore ? data.Email_Direttore : data.PER_EMA
      }}"
      class="email"
      >{{ data.Email_Direttore ? data.Email_Direttore : data.PER_EMA }}</a
    >
  </div>
</div>
