<!-- Banner Home -->
<div *ngIf="!loaderPage">
  <app-page-head [data]="sharedData.home"></app-page-head>
  <!-- News -->
  <app-home-carousel
    *ngIf="carouselList && carouselList.length > 0"
    [carouselList]="carouselList"
  ></app-home-carousel>
  <!-- News -->
  <app-home-news
    *ngIf="newsList && newsList.length > 0"
    [newsList]="newsList"
  ></app-home-news>
  <!-- Departments -->
  <app-home-departments
    *ngIf="departmentsList && departmentsList.length > 0"
    [departmentsList]="departmentsList"
  ></app-home-departments>

  <app-home-rankings
    [noticesList]="noticesList"
    [tendersList]="tendersList"
  ></app-home-rankings>
</div>
