<div class="it-example-modal">
    <div class="modal" tabindex="-1" role="dialog" id="genericDualChoiceModal" style="top: 35%;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> {{titleHeader}}
                    </h5>
                </div>
                <div class="modal-body">
                    <p> <b> {{bodyText}} </b></p>
                </div>
                <div class="modal-footer">
                    <button (click)="doAction2()" class="btn btn-outline-primary btn-sm" type="button"
                        data-dismiss="modal">{{action2Title}}
                    </button>
                    <button (click)="doAction1()" class="btn btn-primary btn-sm" type="button">{{action1Title}}</button>
                </div>
            </div>
        </div>
    </div>
</div>