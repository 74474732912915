import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-presentation-card',
  templateUrl: './company-presentation-card.component.html',
  styleUrls: ['./company-presentation-card.component.scss']
})
export class CompanyPresentationCardComponent implements OnInit {

  @Input() data;
  constructor() { }

  ngOnInit(): void {
  }

}
