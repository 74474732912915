<div *ngIf="loader === true" class="row loading-img text-center">
    <div class="col-lg-12" style="padding-bottom:13px;">
    </div>
    <div class="col-lg-12">
        <div class="sk-chase" style="width: 100px;">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </div>
</div>

