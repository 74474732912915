import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/api/services/download.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PrivacyService } from 'src/app/api/services/privacy.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss', '../../shared/sharedStyle.scss'],
})
export class PrivacyComponent implements OnInit {
  loaderPage = false;

  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
    new BreadcrumbItem('NAVBAR_TAB.PRIVACY', ''),
  ];

  constructor(
    private privacyService: PrivacyService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private downloadService: DownloadService
  ) {}

  mapPrivacy = new Map();

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';
    this.privacyService.postSearchPrivacy(body).subscribe(
      (result) => {
        if (result.rowCount > 0) {
          result.rows.forEach((element) => {
            const callback = (p) => p.Categoria === element.Categoria;
            this.mapPrivacy.set(
              element.Categoria,
              result.rows.filter(callback)
            );
          });
        } else {
          this.mapPrivacy.clear();
        }
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.mapPrivacy.clear();
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => {},
        });
      }
    );
  }

  public downloadFile(doc) {
    const postType = DocumentPostType.PRIVACY;
    const postID = doc.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(doc.Filename, url);
  }

  public redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
