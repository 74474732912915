<nav class="breadcrumb-container" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li
      *ngFor="
        let elem of breadcrumbList | slice : 0 : breadcrumbList.length - 1
      "
    >
      <a
        [class.disabled]="elem.disabled"
        class="shared_breadcrumbs_link"
        (click)="itemDidPress(elem)"
        tabindex="0"
        >{{ elem.name | translate }}</a
      ><span class="separator">/</span>
    </li>
    <li *ngIf="lastItem" class="breadcrumb-item active" aria-current="page">
      {{ lastItem.name | translate }}
    </li>
  </ol>
</nav>
