import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-notices-card',
  templateUrl: './notices_card.component.html',
  styleUrls: ['./notices_card.component.scss']
})
export class NoticesCardComponent implements OnInit {

  constructor() { }

  @Input() data;
  @Input() typology;
  @Input() routeLinkText = '';

  ngOnInit(): void {
    switch (this.typology) {
      case 'notices':
        this.routeLinkText = '/concorsi-e-avvisi';
        break;
      case 'tenders':
        this.routeLinkText = '/bandi-e-gare';
        break;

      default:
        break;
    }
  }

}
