import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'readingTime'
})
export class ReadingTimePipe implements PipeTransform {
  lang;
  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.getBrowserLang();
    this.translateService.onLangChange.subscribe(
      () => {
        this.lang = this.translateService.getBrowserLang();
      }
    )
  }

  transform(value: string, ...args: unknown[]): unknown {
    const wordsByMinutes = 220;
    const numberOfWords = value.split(" ").filter(function(n) { return n != "" }).length;
    let readingTime = numberOfWords/wordsByMinutes;
    let min = Math.floor(Math.abs(readingTime));
    let sec = Math.floor((Math.abs(readingTime) * 60) % 60);
    const minString = this.lang === 'it' ? (min === 1 ? 'minuto' : 'minuti') : (min === 1 ? 'minute' : 'minutes');
    const secString = this.lang === 'it' ? 'secondi' : 'seconds';
    return min > 1 ? min + ' ' + minString + ' ' + sec + ' ' + secString : sec + ' ' + secString;
  }
}
