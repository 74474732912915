<div [ngClass]="{
    body_all:
      typology !== 'risk_manager_detail' &&
      typology !== 'com_etic_detail' &&
      typology !== 'prod_az_detail' &&
      typology !== 'departments_uoc' &&
      typology !== 'departments_uosd' &&
      typology !== 'programs-departments',
    body_all_extended:
      typology === 'atti_not' ||
      typology === 'risk_manager_detail' ||
      typology === 'com_etic_detail' ||
      typology === 'prod_az_detail' ||
      typology === 'departments_uoc' ||
      typology === 'departments_uosd' ||
      typology === 'programs-departments',
    riskManager: typology === 'risk_manager'
  }" class="body_all">
  <p class="title-text" role="region" aria-label="titolo">
    <span *ngIf="typology === 'transparentAdministration'"></span>{{ mainTitle | translate }}
  </p>
  <div *ngIf="typology === 'notices' || typology === 'tenders'" class="body_grid">
    <app-notices-card [typology]="typology" *ngFor="let element of bodyList" [data]="element"></app-notices-card>
  </div>

  <div class="disposition row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3" *ngIf="typology === 'news'">
    <app-news-card *ngFor="let element of bodyList" [data]="element"></app-news-card>
  </div>

  <div *ngIf="
      typology === 'transparentAdministration' ||
      typology === 'com_etic' ||
      typology === 'prod_az' ||
      typology === 'risk_manager'
    ">
    <div [ngClass]="{
        shared_information_column: typology === 'transparentAdministration'
      }" style="overflow: auto">
      <ng-container *ngFor="let element of bodyList">
        <app-cell-info [data]="element" [title]="element.Nome" [showDetail]="false" [detailText]="'BUTTON.GO_TO_PAGE'"
          (actionFunction)="goToDetail($event)">
        </app-cell-info>
      </ng-container>
    </div>
  </div>

  <div *ngIf="typology === 'atti_not'">
    <app-table-info [data]="bodyList" [postTypeData]="'atti_not'" [showDate]="false"
      (downloadHandler)="downloadFile($event)">
    </app-table-info>
  </div>

  <div *ngIf="typology === 'com_etic_detail'">
    <app-table-info [data]="bodyList" [postTypeData]="'com_etic'" [showDate]="false"
      (downloadHandler)="downloadFile($event)">
    </app-table-info>
  </div>

  <div *ngIf="typology === 'prod_az_detail'">
    <app-table-info [data]="bodyList" [postTypeData]="'prod_az'" [showDate]="false"
      (downloadHandler)="downloadFile($event)">
    </app-table-info>
  </div>

  <div *ngIf="typology === 'risk_manager_detail'">
    <app-table-info [data]="bodyList" [postTypeData]="'risk_manager'" [showDate]="false"
      (downloadHandler)="downloadFile($event)">
    </app-table-info>
  </div>

  <div *ngIf="typology === 'departments_uoc'">
    <div class="disposition-start row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-2">
      <ng-container *ngFor="let element of bodyList">
        <app-info-card [attr.id]="element.TITLE" [data]="element" [title]="element.Nome_Struttura"
          [showDetail]="isDetailEnabled(element)" [subtitle]="element.ID_Direttore" [showSubText]="true"
          [minHeight]="'150px'" [detailText]="'BUTTON.READ_MORE'" (actionFunction)="goToDetail(element)">
        </app-info-card>
      </ng-container>
    </div>
  </div>

  <div *ngIf="typology === 'departments_uosd'">
    <div class="disposition-start row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-2">
      <ng-container *ngFor="let element of bodyList">
        <app-info-card [attr.id]="element.TITLE" [data]="element" [title]="element.Nome_Struttura"
          [showDetail]="isDetailEnabled(element)" [subtitle]="element.ID_Direttore" [showSubText]="true"
          [minHeight]="'150px'" [detailText]="'BUTTON.READ_MORE'" (actionFunction)="goToDetail(element)">
        </app-info-card>
      </ng-container>
    </div>
  </div>

  <div *ngIf="typology === 'programs-departments'">
    <div class="disposition-start row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-2">
      <ng-container *ngFor="let element of bodyList">
        <app-info-card [attr.id]="element.TITLE" [data]="element" [title]="element.TITLE"
          [showDetail]="isDetailEnabled(element)" [subtitle]="element.SUBTITLE" [showSubText]="true"
          [minHeight]="'150px'" [detailText]="'BUTTON.READ_MORE'" (actionFunction)="goToDetail($event)">
        </app-info-card>
      </ng-container>
    </div>
  </div>

  <div class="disposition row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3" *ngIf="typology === 'departments'">
    <app-departments-card *ngFor="let element of bodyList" [data]="element"></app-departments-card>
  </div>

  <div *ngIf="typology === 'company-management'">
    <div class="disposition row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-2">
      <app-company-management-card *ngFor="let element of bodyList" [data]="element"></app-company-management-card>
    </div>
  </div>
</div>

<p *ngIf="!(bodyList && bodyList.length > 0)" style="text-align: center; margin-bottom: 2%">
  {{ "MESSAGE.NO_RESULTS" | translate }}
</p>