import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'albo_pretorio',
  templateUrl: './albo_pretorio.component.html',
  styleUrls: ['./albo_pretorio.component.css', '../../shared/sharedStyle.scss'],
})
export class AlboComponent implements OnInit {
  type = 0;
  contextTitle = '';
  selectedTab = 'albo_pretorio';
  searchText = '';
  tempSearchText = '';

  years = [];
  yearsLength = 10;
  selectedYear = '';
  selectedTempYear = '';

  selectedNumber = '';
  tempSearchNumber = '';

  transparent = false;
  errorMessage: string;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.BAR', ''),
  ];

  ngOnInit(): void {
    let years = this.route.snapshot.queryParams.years == 'true';
    let type = this.route.snapshot.queryParams.type;
    if (years) {
      this.type = parseInt(this.route.snapshot.queryParams.type, 2);
      this.setYears();
      this.transparent = true;
    } else {
      this.type = this.router.url.includes('/resolutions/determine') ? 1 : 0;
    }
    this.contextTitle = this.type === 0 ? 'BAR.RESOLUTIONS' : 'BAR.DEFINE';
    this.breadcrumbItems.push(new BreadcrumbItem(this.contextTitle, ''));
  }

  setYears() {
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear.toString());
    for (let i = 1; i < this.yearsLength; i++) {
      this.years.push((currentYear - i).toString());
    }
  }

  search(event) {
    this.searchText = event;

    // this.doSearch();
  }

  doSearch() {
    this.errorMessage = null;
    this.searchText = this.tempSearchText.trim();
    this.selectedYear = this.selectedTempYear;
    this.selectedNumber = !isNaN(parseInt(this.tempSearchNumber, 10))
      ? this.tempSearchNumber
      : '';
  }

  resetFilter() {
    this.searchText = '';
    this.tempSearchText = '';
    this.tempSearchNumber = '';
    this.selectedTempYear = '';
    this.selectedYear = '';
    this.doSearch();
  }
}
