import { Component, OnInit } from '@angular/core';
import { noticesDetailResponse } from 'src/app/shared/messageType';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { IS_MOBILE } from 'src/app/shared/models/constants';

@Component({
  selector: 'app-regulation-and-public-protecion',
  templateUrl: './regulation-and-public-protecion.component.html',
  styleUrls: ['./regulation-and-public-protecion.component.scss'],
})
export class RegulationAndPublicProtecionComponent implements OnInit {
  constructor() {}
  rppList = [
    {
      id: '1',
      titolo: 'All B - scheda segnalazioni AOU minorenni',
      href: '../../../assets//rpp/AllB-schedasegnalazioniAOUminorenni.pdf',
    },
    {
      id: '2',
      titolo: 'REGOLAMENTO DI PUBBLICA TUTELA AOU',
      href: '../../../assets//rpp/REGOLAMENTO DI PUBBLICA TUTELA AOU.pdf',
    },
    {
      id: '3',
      titolo: 'All A - scheda segnalazioni AOU',
      href: '../../../assets//rpp/All A - scheda segnalazioni AOU.pdf',
    },
  ];
  headNoticesList: noticesDetailResponse[] = [];
  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.URP', '/urp'),
  ];
  mobile = IS_MOBILE;
  addID = 'A';
  ngOnInit(): void {}
}
