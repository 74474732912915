import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';

import { Observable } from 'rxjs';
import { ProcedureType } from 'src/app/shared/messageType';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProcedureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly PostSearchPath = '/api/v1/procedure/_search';
  static readonly PostInsertPath = '/api/v1/procedure/_insert';
  static readonly PutUpdatePath = '/api/v1/procedure/_update';
  static readonly PostDeletePath = '/api/v1/procedure/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/procedure/_update_publish';
  static readonly PostProcAziendSearchFoldersPath ='/api/v1/procedure/_search_folders';
  static readonly PostProcAziendSearchSubFoldersPath ='/api/v1/procedure/_search_sub_folders';
  
  postProcAziendSearch$Response(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<ProcedureType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostSearchPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>;
        })
      );
  }

  postProcAziendSearch(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<ProcedureType>;
  }> {
    return this.postProcAziendSearch$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }
      )
    );
  }

  /*
   * INSERT
   */
  postInsertProcAziend$Response(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<ProcedureType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostInsertPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>;
        })
      );
  }
  postInsertProcAziend(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<ProcedureType>;
  }> {
    return this.postInsertProcAziend$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }
      )
    );
  }

  /*
   * UPDATE
   */
  postUpdateProcAziend$Response(
    params?
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<ProcedureType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PutUpdatePath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>;
        })
      );
  }
  postUpdateProcAziend(
    params?
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<ProcedureType>;
  }> {
    return this.postUpdateProcAziend$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<ProcedureType>;
          }
      )
    );
  }

  /*
   * DELETE
   */
  DeleteProcAziend$Response(params?: {
    prod_az_id: number;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostDeletePath,
      'delete'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  DeleteProcAziend(params?: {
    prod_az_id: number;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.DeleteProcAziend$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /*
   * UPDATE PUBLISH
   */
  UpdatePublishProcAziend$Response(params?: {
    prod_az_id: string;
    published: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PutUpdatePublishPath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  UpdatePublishProcAziend(params?: {
    prod_az_id: string;
    published: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.UpdatePublishProcAziend$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH FOLDERS
   */
  postSearchFoldersProcAziend$Response(): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<ProcedureType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostProcAziendSearchFoldersPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }
  postSearchFoldersProcAziend(): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postSearchFoldersProcAziend$Response().pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH SPECIFIC FOLDERS
   */
  postSearchSpecificFoldersProcAziend$Response(params?: {
    folder_id?: string;
    Sottotipologia?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostInsertPath,
      'post'
    );
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }
  postSearchSpecificFoldersProcAziend(params?: {
    folder_id?: string;
    Sottotipologia?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postSearchSpecificFoldersProcAziend$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH FROM SPECIFIC FOLDER
   */
  postProcAziendSpecificFolderSearch$Response(params?: {
    folder_id?: string;
    Sottotipologia?: string;
    published?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostSearchPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  postProcAziendSpecificFolderSearch(params?: {
    folder_id?: string;
    Sottotipologia?: string;
    published?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postProcAziendSpecificFolderSearch$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  //postEthicsCommiteeSubFolderSearch

  postProcAziendSubFolderSearch$Response(params?: {
    sub_id?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ProcedureService.PostProcAziendSearchSubFoldersPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  postProcAziendSubFolderSearch(params?: {
    sub_id?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postProcAziendSubFolderSearch$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }
}
