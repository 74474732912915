import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NoticesService } from 'src/app/api/services';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { noticesDetailResponse } from 'src/app/shared/messageType';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { LoaderService } from 'src/app/api/services/loader.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tenders-detail',
  templateUrl: './tenders-detail.component.html',
  styleUrls: ['./tenders-detail.component.scss']
})
export class TendersDetailComponent implements OnInit {

  breadcrumbItems: BreadcrumbItem[];

  constructor(
    private route: ActivatedRoute, private ns: NoticesService, private location: Location,
    private infoModal: GenericInfoModalService, private translate: TranslateService, private loaderService: LoaderService) { }
  loaderPage = false;
  notice_Detail_Response: noticesDetailResponse;

  ngOnInit(): void {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.location.replaceState('bandi-e-gare/dettaglio');

    this.ns.getNoticesDetailsById({ notice_id: this.route.snapshot.params.id }).subscribe(res => {
      this.loaderPage = true;
      this.loaderService.setloader(this.loaderPage);
      this.notice_Detail_Response = res.rows[0];
      this.breadcrumbItems = [
        new BreadcrumbItem('TENDERS_DETAIL.HOME', '/home'),
        new BreadcrumbItem('TENDERS_DETAIL.TENDERS', '/bandi-e-gare'),
        new BreadcrumbItem(this.notice_Detail_Response.titolo, '')
      ];

    }, err => {
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
        bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
        actionLabel: this.translate.instant('BUTTON.OK'),
        actionFunction: {}
      });
    });
  }

}
