import { Component, OnInit } from '@angular/core';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-legal-notes',
  templateUrl: './legal-notes.component.html',
  styleUrls: ['./legal-notes.component.scss'],
})
export class LegalNotesComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[];
  constructor() {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.breadcrumbItems = [
      new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
      new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
      new BreadcrumbItem('NAVBAR_TAB.LEGAL_NOTES', ''),
    ];
  }
}
