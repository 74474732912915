import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-rankings',
  templateUrl: './home_rankings.component.html',
  styleUrls: ['./home_rankings.component.scss'],
})
export class HomeRankingsComponent implements OnInit {
  constructor() {}

  @Input() noticesList = [];
  @Input() tendersList = [];

  ngOnInit(): void {}
}
