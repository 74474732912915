import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { DepartmentsService } from 'src/app/api/services';
import {
  departmentAfferentPersonsResponse,
  departmentAmbulatoryResponse,
  departmentEmailsResponse,
  departmentStructuresResponse,
} from 'src/app/shared/messageType';

@Component({
  selector: 'app-departments-detail-body',
  templateUrl: './departments_detail_body.component.html',
  styleUrls: [
    './departments_detail_body.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsDetailBodyComponent implements OnInit {

  @Input() dai_id: string;
  @Input() id: string;

  @Input() departments_detail: any;

  departments_Afferent_persons: departmentAfferentPersonsResponse;
  departments_email: departmentEmailsResponse;
  departments_ambulatories: departmentAmbulatoryResponse[];
  departments_prestations: departmentStructuresResponse[];

  departmentsUoc;
  departmentsUosd;
  fullRes;
  programsDepartments;
  showMore;


  constructor(
    private dipartmentService: DepartmentsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dipartmentService
      .strcturesSearchByIDPath({ ID_Dai: this.id })
      .subscribe((res) => {
        this.fullRes = res;
      });
  }
  filterRes(elem) {
    let data = this.fullRes?.rows
      .map((item) => {
        if (item.Tipologia_Struttura == elem) {
          return item;
        } else {
          return null;
        }
      })
      .filter((x) => x);

    return data?.length > 0 ? data[0] : null;

    /// UOSD
    if (this.departmentsUosd && this.departmentsUosd.length > 0) {
      this.departmentsUosd.forEach((element) => {
        element.dai_id = this.dai_id;
        element.ORG_DES = this.departments_detail.ORG_DES;
      });
    }
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
