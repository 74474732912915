import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CampaniaPancreasService } from 'src/app/api/services/campania-pancreas.service';
import { DownloadService } from 'src/app/api/services/download.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-campania-pancreas',
  templateUrl: './campania-pancreas.component.html',
  styleUrls: [
    './campania-pancreas.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class CampaniaPancreasComponent implements OnInit {
  loaderPage = false;
  campaniaPancreasList: any[] = [];
  constructor(
    private cps: CampaniaPancreasService,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private downloadService: DownloadService,
    public sharedData: SharedData
  ) { }

  ngOnInit(): void {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';
    this.cps.postSearchCampaniaPancreas(body).subscribe(
      (res) => {
        this.campaniaPancreasList = res.rowCount > 0 ? res.rows : [];
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => { },
        });
      }
    );
  }

  downloadFile(data) {
    const postType = DocumentPostType.CAM_PANCREAS;
    const postID = data.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob('Documento_', url);
  }
}
