import { Component, OnInit } from '@angular/core';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-URP',
  templateUrl: './URP.component.html',
  styleUrls: ['./URP.component.scss'],
})
export class URPComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('URP.HOME', '/home'),
    new BreadcrumbItem('URP.COMPANY', '/azienda'),
    new BreadcrumbItem('URP.URP', ''),
  ];

  constructor() {}

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
