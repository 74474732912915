<div *ngIf="data" class="company_management_card">
  <p *ngIf="data" role="title" aria-label="data.Titolo" class="title">
    {{ data.Titolo }}
  </p>

  <a
    *ngIf="data && data.More_info == '1'"
    tabindex="0"
    role="navigation"
    aria-label="leggi-di-piu"
    [routerLink]="['/company-read-more', data.Titolo, data.ID]"
    class="btn vai-alla-pagina"
    >{{ "BUTTON.READ_MORE" | translate }}
  </a>
  <p *ngIf="data.More_info == '0'" class="text" role="region" aria-label="nome">
    Sezione in Aggiornamento
  </p>
</div>
