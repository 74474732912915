<!-- HEAD -->
<app-main-head [isSearchRequired]="true" [breadcrumbItems]="breadcrumbItems" [bodyList]="" [mainTitle]="NomeStruttura"
  [textPlaceholderSearch]="'NOTICES.NOTICES'" [rightListPanelText]="'NOTICES.NOTICES_IN_EVIDENCE'"
  [rightAllPanelText]="'NOTICES.ALL_NOTICES'" [redirectText]="'all-notices'" [routerLinkText]="'/concorsi-e-avvisi'"
  (externalSearchEvent)="searchExt()" [isRightPanelVisible]="false" [typology]="'notices'">
</app-main-head>
<!-- END HEAD -->

<div class="wrapper">
  <!-- versione Mobile -->
  <div *ngIf="mobile" id="accordionDiv1" class="collapse-div">
    <div *ngFor="let struttura of strutture">
      <div class="collapse-header" id="headingA{{ addID }}{{ struttura.ID }}">
        <button data-toggle="collapse" [attr.data-target]="'#' + addID + struttura.ID" aria-expanded="false"
          [attr.aria-controls]="addID + struttura.ID">
          <p class="text_title">{{ struttura.Nome_Struttura }}</p>
        </button>
      </div>
      <div [id]="addID + struttura.ID" class="collapse" role="tabpanel"
        [attr.aria-labelledby]="'#' + addID + struttura.ID" data-parent="#accordionDiv1">
        <div class="collapse-body">
          <div class="struttura_row">
            <p class="struttura_title">
              {{ "NOTICES_DETAIL.NAME" | translate }}:
            </p>
            <p class="struttura_text">{{ struttura.Nome_Struttura }}</p>
          </div>

          <button class="btn btn-primary" [routerLink]="['/concorsi-e-avvisi', struttura.ID]">
            {{ "NOTICES_DETAIL.DETAILS" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- versione Desktop -->
  <div class="center" *ngIf="!mobile">
    <table class="table" *ngIf="!(strutture?.length == 0)">
      <tr class="table-header">
        <th class="colonna1">{{ "NOTICES_DETAIL.NAME" | translate }}</th>

        <th class="colonna2">{{ "NOTICES_DETAIL.DETAILS" | translate }}</th>
      </tr>
      <tbody>
        <tr *ngFor="let struttura of strutture">
          <td [ariaLabel]="struttura.Nome_Struttura">
            {{ struttura.Nome_Struttura }}
          </td>

          <td>
            <button class="btn detail-button" [routerLink]="['/company-details', struttura.ID]">
              {{ "NOTICES_DETAIL.DETAILS" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="no_result_wrapper" *ngIf="strutture?.length == 0">
    {{ "ALERT_MESSAGE.NO_RESULTS_FOUND" | translate }}
  </p>

  <!-- Paginator for notices all-->
</div>