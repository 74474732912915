<!-- HEAD -->
<app-main-head
  [breadcrumbItems]="breadcrumbItems"
  [mainTitle]="'DEPARTMENTS.DEPARTMENTS'"
  [textPlaceholderSearch]="'DEPARTMENTS.DEPARTMENTS'"
  [rightListPanelText]="'DEPARTMENTS.DEPARTMENTS_IN_EVIDENCE'"
  [rightAllPanelText]="'DEPARTMENTS.ALL_DEPARTMENTS'"
  [redirectText]="'all-departments'"
  [typology]="'departments'"
  [isRightPanelVisible]="false"
>
</app-main-head>
<!-- END HEAD -->

<!-- ALL -->
<app-main-all-body
  *ngIf="!loaderPage"
  id="all-departments"
  [typology]="'departments'"
  [mainTitle]="'DEPARTMENTS.ALL_DEPARTMENTS'"
  [bodyList]="departmentsList"
></app-main-all-body>
<!-- END ALL -->
