import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-info',
  templateUrl: './banner-info.component.html',
  styleUrls: ['./banner-info.component.scss']
})
export class BannerInfoComponent implements OnInit {

  isShowMoreActive = false;

  @Input() page = '';
  @Input() title = '';
  @Input() subtitle = '';
  constructor() { }

  ngOnInit(): void {
  }

}
