import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatesService {
  constructor(private datePipe: DatePipe) {}
  //consente di convertire le date prende in ingresso la data e il formato
  transformDate(date, format) {
    return this.datePipe.transform(date, format);
  }
}
