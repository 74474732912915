<div class="main_card" [style.min-height]="minHeight">
  <p
    class="title"
    role="region"
    [attr.aria-label]="title ? title : 'titolo card non presente'"
  >
    {{ title }}
  </p>
  <div>
    <p
      *ngIf="showSubText"
      class="abstract"
      role="region"
      [attr.aria-label]="subtitle ? subtitle : 'descrizione card non presente'"
    >
      {{ subtitle }}
    </p>
  </div>
  <div
    role="region"
    [attr.aria-label]="
      detailText ? (detailText | translate) : 'dettaglio testo non presente'
    "
  >
    <a [hidden]="!showDetail" class="btn leggi-di-piu" (click)="callAction()">
      {{ detailText | translate }}
    </a>
  </div>
</div>
