import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { downloadedFilesResponseType1 } from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root'
})
export class DownloadService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }
  static readonly PostDownload1Path = '/api/v1/cms/';
  static readonly PostDownload2Path = '/download/';

  public downloadBlob(fileName: string, url: string): void {
    const anchor = window.document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }


  Download$Response(params?: {
    post_type?: string;
    post_id?: string;
    post_cod_padre_doc?: string;
  }): Observable<StrictHttpResponse<any>> {
    const urlPath = params.post_cod_padre_doc ? DownloadService.PostDownload1Path +
      params.post_type + DownloadService.PostDownload2Path + params.post_cod_padre_doc + '/' + params.post_id :
      DownloadService.PostDownload1Path + params.post_type + DownloadService.PostDownload2Path + params.post_type + '/' + params.post_id;
    const rb = new RequestBuilder(this.rootUrl, urlPath, 'get');
    return this.http.request(rb.build({ responseType: 'blob' })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      }),

    );
  }

  /**
 
   */
  Download(params?: {
    post_type?: string;
    post_id?: string;
    post_cod_padre_doc?: string;
  }): Observable<any> {

    return this.Download$Response(params).pipe(
      map((r: StrictHttpResponse<any>) =>
        r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> })
    );
  }
}
