<!-- HEAD -->
<app-main-head [breadcrumbItems]="breadcrumbItems" [mainTitle]="'NAVBAR_TAB.RISK_MANAGEMENT'"
  [redirectText]="'all-risk'" [isRightPanelVisible]="false" [typology]="'risk_manager'">
</app-main-head>
<!-- END HEAD -->

<!-- ALL -->
<app-main-all-body id="all-risk" [typology]="'risk_manager'" [mainTitle]="''" [bodyList]="riskList">
</app-main-all-body>
<!-- END ALL -->

<!-- TODO: In main all body rendere solo per risk_manager lo sfondo var(--light-background) -->