<div class="shared_detail_body" style="height: auto">
  <!-- {{ this.parentData | json }} -->
  <div class="shared_information_column">
    <div class="mainAlternativeTitleTextWrapper" *ngIf="!isRecursiveChild">
      <!-- {{ this.parentData | json }}
      <!-- Link se si entra in altri contenuti. -->
      <div *ngIf="this.parentData.ID == 42" class="linksContainer">
        <div class="general-acts-link">
          <p class="PNRR">PNRR:&nbsp;&nbsp;</p>
          <a
            class="read-more-general-acts"
            target="_blank"
            href="https://pnrr.regione.campania.it/missione-6-salute/"
          >
            Missione 6 – Salute- Campania PNRR →</a
          >
        </div>
      </div>
      <!-- Due link se si entra in atti generali. -->
      <div *ngIf="this.parentData.ID == 8" class="linksContainer read-more">
        <div class="general-acts-link">
          <a
            class="read-more-general-acts"
            target="_blank"
            href="https://www.normattiva.it/legislazioneRegionale"
            >Normattiva →
          </a>
        </div>
        <div class="general-acts-link">
          <a
            class="read-more-general-acts"
            target="_blank"
            href="http://www.burc.regione.campania.it/eBurcWeb/publicContent/ultimiBurc/lista.iface"
            >BURC - Bollettino Ufficiale Regione Campania →</a
          >
        </div>
        <div class="general-acts-link">
          <a
            class="read-more-general-acts"
            target="_blank"
            href="https://www.normattiva.it/legislazioneRegionale "
            >Raccolta di Leggi Regionali →
          </a>
        </div>
      </div>
    </div>

    <div
      class="mainSubtitleText"
      *ngIf="showMandatoryInfo(parentData.SubCodPadre)"
      [innerHTML]="getMandatoryText(parentData.SubCodPadre)"
    ></div>
    <!-- se entra in wistleblowing deve mostrare il testo con il link -->
    <div>
      <div
        *ngIf="parentData.SubCodPadre === 'wstl'"
        class="shared_information_column"
        style="overflow: auto"
      >
        <div>
          {{ "WHISTLEBLOWING.TEXT" | translate }}
          <a
            href="https://whistleblowing.policliniconapoli.it/#/"
            target="_blank"
            >{{ "WHISTLEBLOWING.TEXT_LINK" | translate }}</a
          >
          <div class="tableWrapper">
            <app-table-info
              [data]="bodyList"
              [postTypeData]="postType"
              (downloadHandler)="download($event)"
            >
            </app-table-info>
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="parentData.ID == 46">
      <a
      class="read-more"
      role="region"
      href="https://www.aranagenzia.it/contrattazione/comparti.html"
      target="_blank"
    >
      <span class="text-redirect">{{ "BUTTON.GO_TO_COMPARTI" | translate }}</span>
    </a>
    </div>
    <!-- Descrizione e link Tempi costi e indicatori di realizzazione delle opere pubbliche -->
    <div *ngIf="parentData.ID == 280">
      <div class="mainSubtitleText" style="overflow: auto">
        <div>
          {{ "TCOPEREPUBLICHE.TEXT" | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="parentData.ID == 280" class="mainAlternativeTitleTextWrapper">
      <div class="linksContainer read-more">
        <div class="general-acts-link">
          <a
            class="read-more-general-acts"
            target="_blank"
            href="https://openbdap.rgs.mef.gov.it/it/IPU/Analizza#OperePubbliche"
            >{{ "TCOPEREPUBLICHE.TEXT_LINK" | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- hanno un vai al link -->
    <div
      class="button-wrapper"
      *ngIf="showButtonAction(parentData.SubCodPadre)"
    >
      <a
        class="read-more"
        role="region"
        [aria-label]="getRedirectButtonText(parentData.SubCodPadre)"
        (click)="redirectButtonAction(parentData.SubCodPadre)"
      >
        <span class="text-redirect">{{
          getRedirectButtonText(parentData.SubCodPadre)
        }}</span>
      </a>
      <a
        class="read-more"
        role="region"
        href="https://dati.anticorruzione.it/superset/dashboard/appalti/?native_filters_key=JhnJRCIvGS_2hUKSTJN2in19MexXlO3M2MNqojuP0gyz3LziHPfGR9SSlVFWTu1D"
        target="_blank"
        >
        <span class="text-redirect">{{ "BUTTON.GO_TO_NATIONAL_DATA" | translate }}</span>
      </a>
      <a
        class="read-more"
        role="region"
        href="https://dati.mit.gov.it/catalog/dataset/scp"
        target="_blank"
      >
        <span class="text-redirect">{{ "BUTTON.GO_TO_PUBLIC_CONTRACTS" | translate }}</span>
      </a>
    </div>
    <!-- mostrano diversi link -->
    <div class="cells-wrapper" *ngIf="showCells(parentData.SubCodPadre)">
      <div *ngFor="let elem of getLinks(parentData.SubCodPadre)">
        <app-cell-info
          [title]="elem.title"
          [isArrowHidden]="true"
          (actionFunction)="goToLink(elem.link)"
        ></app-cell-info>
      </div>
    </div>

    <div
      *ngIf="detailInformation && detailInformation.length > 0"
      class="body-container"
    >
      <!-- da visualizzare solo se entra nella sezione Organismi Indipendenti di valutazione, nuclei di valutazione -->
      <div *ngIf="isContrilev">
        <div *ngFor="let elem of contrilev; index as i">
          <p class="titleContrlev">
            {{ elem.Nome }}
          </p>
          <app-accordion-detail
            [indexFromParent]="i"
            [data]="elem.Files"
          ></app-accordion-detail>
        </div>
      </div>

      <ng-container *ngFor="let element of detailInformation; let i = index">

        <div class="collapsableContainer" *ngIf="false">
          <!-- In caso di richiesta di mostrare le voci delle sotto-categorie (
            cosa fare: modificare il dato che arriverà in Input di parentData in modo tale 
            che non abbia nomeFile bensì Nome [CodicePadre] )-->
          <div *ngIf="!(element.Files && element.Files.length > 0)">
            <app-cell-info
              [data]="element"
              [title]="element.Nome"
              [showDetail]="false"
              [detailText]="''"
              (actionFunction)="goToDetail($event)"
            >
            </app-cell-info>
          </div>
        </div>

        <ng-container *ngIf="element.Files && element.Files.length > 0">
          
          <div
            style="
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid var(--shadow-box);
            "
          >
            <p
              class="shared_paragraph_title"
              id="{{ element.Nome }}"
              [ngClass]="{ subListText: isRecursiveChild }"
            >
              {{ element.Nome }}
            </p>


            <span
              class="accordion-wrapper"
              (click)="accordions[i] = !accordions[i]"
              *ngIf="true"
            >
              <p style="position: relative; top: 0.3em">
                {{
                  accordions[i]
                    ? ("TRANSPARENT_ADMINSTRATION.COLLAPSE" | translate)
                    : ("TRANSPARENT_ADMINSTRATION.EXPAND" | translate)
                }}
              </p>

              
              <ng-container *ngIf="!accordions[i]">
                <svg-icon-sprite
                class="arrow-accordion"
                src="../../../assets/sprite.svg#it-expand"
                >
              </svg-icon-sprite>
              </ng-container>
              <ng-container *ngIf="accordions[i]">
                <svg-icon-sprite
                  class="arrow-accordion"
                  src="../../../assets/sprite.svg#it-collapse"
                >
                </svg-icon-sprite>
              </ng-container>
            </span>
          </div>
          <div *ngIf="accordions[i] == true">
            <app-table-info
              class="transition-accordion"
              [data]="element.Files"
              [postTypeData]="'generics'"
              [showDate]="false"
              (downloadHandler)="downloadFile($event)"
            >
            </app-table-info>
          </div>
        </ng-container>
      
      </ng-container>


      <!-- STORICO -->
      <div
        class="collapsableContainer"
        *ngIf="
          this.parentData.Nome === 'Personale' &&
          this.storico &&
          this.storico.length > 0 &&
          !isRecursiveChild
        "
      >
        <div class="title-section-class">
          <p class="shared_paragraph_title">
            {{ "Storico" }}
          </p>

          <div class="collapsableRightSide">
            <div
              class="showHideText"
              (click)="historyIsVisible = !historyIsVisible"
            >
              {{ historyIsVisible ? "Riduci" : "Espandi" }}
            </div>
            <div
              (click)="historyIsVisible = !historyIsVisible"
              style="margin: auto 0"
              [ngClass]="{
                isOpen: historyIsVisible
              }"
            >
              <svg-icon-sprite
                class="arrow-accordion"
                src="../../../assets/sprite.svg#it-expand"
              >
              </svg-icon-sprite>
            </div>
          </div>
        </div>

        <!-- *ngIf="historyIsVisible" -->
        <div *ngIf="historyIsVisible">
          <app-transparent-administration-detail-body
            [parentData]="parentData"
            [detailInformation]="storico"
            [isRecursiveChild]="true"
          >
          </app-transparent-administration-detail-body>
        </div>
      </div>
      <!-- -->

      <!-- ultima modifica quando la sezione comprende diverse tabelle -->
      <div
        class="ultimaModifica"
        [class.ultimaModificaInBasso]="
          detailInformation && detailInformation.length > 0
        "
      >
        {{
          this.tas.parentDataList &&
          this.tas.parentDataList.length > 0 &&
          this.tas.parentDataList[0]?.LastUpdate != null
            ? "Ultima modifica: "
            : ""
        }}
        {{
          this.tas.parentDataList &&
            this.tas.parentDataList.length > 0 &&
            this.tas.parentDataList[0]?.LastUpdate | date : "dd/MM/yyyy"
        }}
      </div>
      <div *ngIf="!isRecursiveChild">
        Conteggio visite: {{ this.visitCounter }}
      </div>
    </div>
    <div *ngIf="!(detailInformation && detailInformation.length > 0)">
      <!-- <p
        class="shared_text m-b-5"
        *ngIf="!showMandatoryInfo(parentData.SubCodPadre)"
      >
        {{ "MESSAGE.NO_RESULTS" | translate }}
      </p> -->
    </div>
  </div>
</div>
