<!-- ADD DOC -->
<div class="row justify-content-end" *ngIf="typeDocumental">
    <div class="col-sm-12">
        <div class="d-flex shadow-container" style="justify-content: end">
            <div class="d-flex justify-content-around" style="justify-content: center; align-items: center">
                <button type="button" [disabled]="isDisabled" (click)="buttonDidPress()"
                    class="btn btn-primary btn-sm create-button">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-plus" classes="icon icon-sm icon-white">
                    </svg-icon-sprite>
                    {{ buttonText | translate }}
                </button>
            </div>
        </div>
    </div>
</div>