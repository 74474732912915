<div *ngIf="isLogged" class="auth-banner">
  <app-auth-banner (logout)="doLogout($event)"></app-auth-banner>
</div>
<app-logo-container></app-logo-container>
<div *ngIf="!isLogged" class="text-center">
  <div class="col-12 col-lg-8 body-center">
    <!--start card-->
    <div class="card-wrapper card-space rounded">
      <div class="card card-bg card-big bg-color">
        <div class="padding-login bg-color">
          <h4 class="card-title" style="margin-bottom: 10%">
            {{ "LOG_IN.CARD_TITLE" | translate }}
          </h4>
          <form [formGroup]="credentials" class="p-form" autocomplete="off">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-text">
                  <svg-icon-sprite
                    classes="icon icon-sm"
                    src="../../../assets/sprite.svg#it-user"
                  >
                  </svg-icon-sprite>
                </div>

                <input
                  type="text"
                  formControlName="username"
                  class="form-control"
                  id="username"
                  name="username"
                  placeholder="name@example.com"
                />
                <label class="active" for="username">{{
                  "LOG_IN.USERNAME" | translate
                }}</label>
              </div>
            </div>

            <div class="form-group">
              <div class="input-group">
                <div class="input-group-text">
                  <svg-icon-sprite
                    classes="icon icon-sm"
                    src="../../../assets/sprite.svg#it-locked"
                  >
                  </svg-icon-sprite>
                </div>

                <input
                  formControlName="password"
                  type="password"
                  data-bs-input
                  class="form-control input-password"
                  id="exampleInputPassword"
                  aria-labelledby="infoPassword"
                  placeholder="Password"
                />
                <label class="active" for="userpassword">{{
                  "LOG_IN.PASSWORD" | translate
                }}</label>
                <span
                  class="password-icon"
                  aria-hidden="true"
                  *ngIf="!isResetPassword"
                >
                  <svg-icon-sprite
                    classes="password-icon-visible icon icon-sm"
                    src="../../../assets/sprite.svg#it-password-visible"
                    style="background: transparent"
                  >
                  </svg-icon-sprite>
                  <svg-icon-sprite
                    classes="password-icon-invisible icon icon-sm d-none"
                    src="../../../assets/sprite.svg#it-password-invisible"
                    style="background: transparent"
                  >
                  </svg-icon-sprite>
                </span>
              </div>
            </div>

            <div *ngIf="isResetPassword">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-text">
                    <svg-icon-sprite
                      classes="icon icon-sm"
                      src="../../../assets/sprite.svg#it-locked"
                    >
                    </svg-icon-sprite>
                  </div>

                  <label class="active" for="password">{{
                    "LOG_IN.NEW_PASSWORD" | translate
                  }}</label>
                  <input
                    [type]="hideResetPassword[0] ? 'password' : 'text'"
                    formControlName="resetPassword"
                    class="form-control"
                    id="resetPassword"
                    name="resetPassword"
                    placeholder="Nuova Password"
                  />
                  <span
                    class="password-icon"
                    aria-hidden="true"
                    (click)="hideResetPassword[0] = !hideResetPassword[0]"
                  >
                    <svg
                      *ngIf="hideResetPassword[0]"
                      class="password-icon-visible icon icon-sm"
                    >
                      <use
                        xlink:href="../../../assets/sprite.svg#it-password-visible"
                      ></use>
                    </svg>
                    <svg
                      *ngIf="!hideResetPassword[0]"
                      class="password-icon-invisible icon icon-sm"
                    >
                      <use
                        xlink:href="../../../assets/sprite.svg#it-password-invisible"
                      ></use>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-text">
                    <svg-icon-sprite
                      classes="icon icon-sm"
                      src="../../../assets/sprite.svg#it-locked"
                    >
                    </svg-icon-sprite>
                  </div>

                  <label class="active" for="password">{{
                    "LOG_IN.PASSWORD_CONFIRM" | translate
                  }}</label>
                  <input
                    [type]="hideResetPassword[1] ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    class="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Conferma Password"
                  />
                  <span
                    class="password-icon"
                    aria-hidden="true"
                    (click)="hideResetPassword[1] = !hideResetPassword[1]"
                  >
                    <svg
                      *ngIf="hideResetPassword[1]"
                      class="password-icon-visible icon icon-sm"
                    >
                      <use
                        xlink:href="../../../assets/sprite.svg#it-password-visible"
                      ></use>
                    </svg>
                    <svg
                      *ngIf="!hideResetPassword[1]"
                      class="password-icon-invisible icon icon-sm"
                    >
                      <use
                        xlink:href="../../../assets/sprite.svg#it-password-invisible"
                      ></use>
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="errorMessage" class="callout danger callout-danger">
              <div class="callout-title">
                <svg-icon-sprite
                  src="../../../assets/sprite.svg#it-close-circle"
                >
                </svg-icon-sprite>
                {{ "LOG_IN.WARNING" | translate }}
              </div>
              <p class="text-alert">{{ errorMessage }}</p>
            </div>

            <div class="login-button text-center">
              <button
                *ngIf="!isResetPassword"
                [disabled]="credentials.invalid"
                class="btn btn-primary btn-primary"
                type="submit"
                (click)="didPressLogin()"
                style="padding-left: 10%; padding-right: 10%"
              >
                Login
              </button>
              <button
                *ngIf="isResetPassword"
                [disabled]="!checkReset()"
                class="btn btn-primary btn-primary"
                type="submit"
                (click)="didPressReset()"
                style="padding-left: 10%; padding-right: 10%"
              >
                Salva
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end card-->
  </div>
</div>

<div *ngIf="isLogged && isNotExpiredToken" style="width: 80%">
  <app-management-task></app-management-task>
</div>
<!--end card-->
