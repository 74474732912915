import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter, } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LoginResponse, ResetPasswordResponse } from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  private _logged : boolean = false;
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly PostLoginPath = '/api/v1/management/login';

  get logged() {
    return localStorage.getItem('token') ? true : false;
  }

  set logged(value: boolean){
    this._logged = value;
  }
  /**

  */
  PostLogin$Response(params?: {
    email?: string;
    password?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<LoginResponse> }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.PostLoginPath, 'post');
    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<LoginResponse> }>;
      }),

    );
  }

  /**

   */
  PostLogin(params?: {
    email?: string;
    password?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<LoginResponse> }> {

    return this.PostLogin$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<LoginResponse> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<LoginResponse> })
    );
  }

  //
  static readonly PostResetPasswordPath = '/api/v1/management/reset';

  /**

  */
  PostResetPassword$Response(params?: {
    email?: string;
    password?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.PostResetPasswordPath, 'post');
    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>;
      }),

    );
  }

  /**

   */
  PostResetPassword(params?: {
    email?: string;
    password?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }> {

    return this.PostResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> })
    );
  }


}
