import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EthicsService } from 'src/app/api/services/ethics.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ethics-committee-detail',
  templateUrl: './ethics-committee-detail.component.html',
  styleUrls: ['./ethics-committee-detail.component.scss']
})
export class EthicsCommitteeDetailComponent implements OnInit {

  loaderPage = false;
  searchText = '';
  constructor(
    private route: ActivatedRoute,
    private comitatoEticoService: EthicsService,
    private location: Location,
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService, private translate: TranslateService,
    private router: Router
  ) {
    // .router.getCurrentNavigation().extras.state;
  }

  parentData: any;
  detailInformation = [];
  detailInformationSearch: any[];
  subDetailInformation: any[];

  breadcrumbItems = [];

  ngOnInit(): void {
    this.parentData = history.state;
    if (!this.parentData.ID) {
      this.router.navigateByUrl('/azienda/comitato-etico');
    }
    else {
      this.retrieveEthicsDetail();
      this.breadcrumbItems = [
        new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
        new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
        new BreadcrumbItem('ETHICS_COMMITEE.TITLE', '/azienda/comitato-etico'),
        this.parentData ? new BreadcrumbItem(this.parentData ?
          this.parentData.Nome : '', '') : null
      ];
    }
  }

  retrieveEthicsDetail() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.comitatoEticoService.postEthicsCommiteeSpecificFolderSearch(
      { folder_id: this.parentData.ID.toString(), Sottotipologia: '', published: '1' }
    ).subscribe(res => {
      this.detailInformation = res.rowCount > 0 ? res.rows : [];
      this.detailInformationSearch = res.rowCount > 0 ? res.rows : [];
      if (
        this.parentData.Subdirectory === '1'
      ) {
        this.comitatoEticoService.postEthicsCommiteeSubFolderSearch$Response({
          sub_id: this.parentData.ID.toString()
        }).subscribe(result => {
          if (result && result.body.rowCount > 0) {
            this.subDetailInformation = result.body.rows;
          }
          else {
            this.subDetailInformation = [];
          }
        });
      }
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
    }, err => {
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
        bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
        actionLabel: this.translate.instant('BUTTON.OK'),
        actionFunction: function () {
          this.router.navigateByUrl('/azienda/comitato-etico');
        }.bind(this)
      });
    });
  }

  searchExt(event) {
    this.searchText = event;
    this.filterItem(event);
  }

  filterItem(value) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    if (!value) {
      this.retrieveEthicsDetail();
    } else {
      this.detailInformationSearch = this.detailInformationSearch.filter(
        item => item.Titolo.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      this.detailInformation = this.detailInformationSearch;
      setTimeout(() => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      }, 1000);
    }
  }
}
