import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from 'src/app/api/services/download.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';

@Component({
  selector: 'app-risk-detail-body',
  templateUrl: './risk-detail-body.component.html',
  styleUrls: [
    './risk-detail-body.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class RiskDetailBodyComponent implements OnInit {
  @Input() parentData: any;
  @Input() detailInformation: any[];
  @Input() subDetailInformation: any[];
  constructor(
    private downloadService: DownloadService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public downloadFile(doc) {
    const postType = DocumentPostType.RISK_MANAGER;
    const postID = doc.ID;
    const fileName = doc.NomeFile;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(fileName, url);
  }

  goToDetail(data) {
    const currentUrl = '/azienda/risk-management/' + data.ID;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl.toString(), {
        state: data,
      });
    });
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
