<!-- HEAD -->
<app-main-head [breadcrumbItems]="breadcrumbItems" [isSearchRequired]="false" [mainTitle]="'NAVBAR_TAB.PRIVACY_POLICY'"
  [isRightPanelVisible]="false">
</app-main-head>
<!-- END HEAD -->

<div class="body">
  <div class="content entry">
    <p>A.O.U. AZIENDA OSPEDALIERA UNIVERSITARIA “LUIGI VANVITELLI” informa gli Utenti che la presente Privacy Policy
      relativa al sito web aziendale www.policliniconapoli.it (di seguito, “il Sito”) è redatta in adempimento degli
      obblighi previsti dal Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016 (n. c.
      GDPR), dal d.lgs. n. 196/2003 (c.d. “Codice Privacy”), così come modificato dal D.lgs. n. 101/2018, nonché in
      ottemperanza a quanto previsto dalla Direttiva 2002/58/CE, come aggiornata dalla Direttiva 2009/136/CE e dai
      D.lgs. n. 69/2012 e 70/2012 in materia di cookie.<br>
      In ogni caso, tale informativa non deve ritenersi valida per eventuali altri siti web di terzi consultabili
      tramite link presenti sul sito internet del Titolare, per i quali egli non è in alcun modo responsabile.</p>
    <p><strong>TITOLARE DEL TRATTAMENTO DEI DATI</strong><br>
      A.O.U. AZIENDA OSPEDALIERA UNIVERSITARIA “LUIGI VANVITELLI<br>
      Via Costantinopoli 104 – 80138 Napoli (NA) – Tel.: 0815667115<br>
      Sito istituzionale: www.policliniconapoli.it<br>
      E-mail: <a href="mailto:direzione.generale@policliniconapoli.it">direzione.generale@policliniconapoli.it</a> –
      PEC:<a href="mailto:policlinico.napoli@pec.it"> policlinico.napoli@pec.it</a></p>
    <p><strong>DATA PROTECTION OFFICER</strong><br>
      Recapiti DPO: <a href="mailto:dpo@policliniconapoli.it">dpo@policliniconapoli.it</a></p>
    <p><strong>BASE GIURIDICA DEL TRATTAMENTO</strong><br>
      Il Sito svolge una funzione informativa delle attività svolte dal Titolare e non è prevista raccolta dei dati
      personali dell’Utente.<br>
      Il Sito tratta i dati prevalentemente in base al consenso degli Utenti (art. 6 lett. a).<br>
      Il conferimento del consenso avviene tramite il banner in sovrimpressione, oppure tramite l’uso e la consultazione
      del sito, quale comportamento concludente. Con l’uso o la consultazione del Sito, i Visitatori e gli Utenti
      approvano la presente informativa sul trattamento dei dati personali e acconsentono al trattamento dei loro dati
      personali in relazione alle modalità e alle finalità qui descritte, compreso l’eventuale diffusione a terzi se
      necessaria per l’erogazione di un servizio.<br>
      Tramite i moduli di comunicazione o di richiesta di servizi, vengono raccolti ulteriori consensi relativi alla
      finalità specifica del servizio.</p>
    <p><strong>FINALITÀ DEL TRATTAMENTO</strong><br>
      I dati personali forniti dall’interessato saranno trattati per le seguenti finalità:</p>
    <ul>
      <li>gestione Visitatori ed Utenti del Sito;</li>
      <li>espletamento delle procedure telematiche di navigazione e di connessione gestite attraverso il Sito del
        Titolare del trattamento, nonché lo svolgimento di tutte le attività conseguenti;</li>
      <li>finalità di sicurezza del Sito;</li>
      <li>adempimento di obblighi previsti dalla legge, da un regolamento o dalla normativa comunitaria.</li>
    </ul>
    <p>Per tali finalità, il conferimento dei dati in oggetto è obbligatorio, in quanto la mancata fornitura impedirebbe
      le attività di navigazione e di connessione al Sito.</p>
    <p><strong>MODALITÀ DI TRATTAMENTO</strong><br>
      Il trattamento dei dati personali viene effettuato utilizzando procedure e supporti cartacei, informatici ed
      elettronici.<br>
      I trattamenti connessi ai servizi web del Sito hanno luogo presso la sede del Titolare. I dati personali saranno
      trattati nel rispetto delle misure di sicurezza di cui all’art. 32 del Regolamento Europeo 2016/679.<br>
      Il Titolare del trattamento tratta i dati personali degli Utenti adottando le opportune misure di sicurezza volte
      ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzata dei dati personali.<br>
      Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con
      logiche strettamente correlate alle finalità indicate.<br>
      Oltre al Titolare e ai delegati interni al trattamento dei dati personali, potrebbe avere accesso ai dati il
      personale autorizzato coinvolto nell’organizzazione del Sito, ovvero soggetti esterni (come fornitori di servizi
      tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche,
      se necessario, come Responsabili esterni del trattamento dei dati.<br>
      L’elenco aggiornato dei responsabili potrà sempre essere richiesto al Titolare del trattamento dei dati.</p>
    <p><strong>MODALITÀ DI CONFERIMENTO DEI DATI</strong><br>
      Il conferimento dei dati e quindi il consenso alla raccolta e al trattamento dei dati è facoltativo.<br>
      L’Interessato può negare il consenso e può revocare in qualsiasi momento un consenso già fornito, tramite il
      banner in sovrimpressione, le impostazioni del browser per i cookie o i link “Contatti”.<br>
      Tuttavia, negare il consenso può comportare l’impossibilità di erogare alcuni servizi e l’esperienza di
      navigazione nel sito sarebbe compromessa.</p>
    <p><strong>TIPOLOGIE DI DATI TRATTATI</strong><br>
      Fra i dati personali dell’Utente eventualmente raccolti o pubblicati dal Titolare, in modo autonomo o tramite
      terze parti, è possibile individuare: nome, cognome, indirizzo IP, cookie, dati di utilizzo, username, password ed
      altri dati come da normativa vigente.<br>
      Altri dati personali raccolti potrebbero essere indicati in altre sezioni della presente privacy policy o mediante
      testi informativi visualizzati contestualmente alla raccolta dei dati stessi.<br>
      L’eventuale utilizzo di cookie – o di altri strumenti di tracciamento – da parte del Sito o dei Titolari dei
      servizi terzi utilizzati da questa applicazione, ove non diversamente precisato, ha la finalità di identificare
      l’Utente e registrare le relative preferenze per finalità strettamente legate all’erogazione del servizio
      richiesto dall’Utente.</p>
    <p><strong>DATI RACCOLTI</strong><br>
      <strong>In maniera automatizzata</strong><br>
      Durante la navigazione degli Interessati, possono essere raccolte le seguenti informazioni che vengono conservate
      nei file di log del server del sito:
    </p>
    <ul>
      <li>indirizzo IP;</li>
      <li>tipo di browser;</li>
      <li>parametri del dispositivo usato per connettersi al Sito;</li>
      <li>nome dell’internet service provider;</li>
      <li>data e orario di visita;</li>
      <li>pagina web di provenienza del Visitatore e di uscita;</li>
      <li>eventualmente il numero di clic.</li>
    </ul>
    <p>Questi dati sono utilizzati ai fini di statistica e analisi, in forma esclusivamente aggregata.<br>
      L’indirizzo IP è utilizzato esclusivamente a fini di sicurezza e non è incrociato a nessun altro dato.<br>
      Conferiti volontariamente<br>
      Il Sito può raccogliere altri dati in caso di utilizzo volontario di servizi, da parte degli utenti, quali servizi
      di commenti, di comunicazione, e verranno utilizzati esclusivamente per l’erogazione del servizio richiesto.</p>
    <p><strong>SEDI DEL TRATTAMENTO E MISURE DI SICUREZZA</strong><br>
      I dati rilevati dal Sito generalmente non vengono forniti a terzi, tranne che in casi specifici: legittima
      richiesta da parte dell’autorità giudiziaria e nei soli casi previsti dalla legge; qualora sia necessario per la
      fornitura di uno specifico servizio richiesto dall’interessato; per l’esecuzione di controlli di sicurezza o di
      ottimizzazione del sito.<br>
      I dati raccolti dal Sito sono trattati presso la sede del titolare del trattamento e presso il data-center del web
      hosting, identificato dal Titolare quale responsabile esterno del trattamento dei dati, elaborando i dati per
      conto del titolare, e agisce in conformità delle norme del GDPR.<br>
      Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti e non corretti ed
      accessi non autorizzati agli archivi del Titolare.</p>
    <p><strong>TRASFERIMENTI DATI EXTRA UE</strong><br>
      I dati non saranno trasferiti al di fuori dell’area dell’Unione Europea.<br>
      Il presente sito potrebbe condividere alcuni dei dati raccolti con servizi localizzati al di fuori dell’area
      dell’UE (ad es., Google, social plugin e/o il servizio di Google Analytics). Il trasferimento è autorizzato in
      base a specifiche decisioni dell’UE e del Garante dei dati personali, in particolare la decisione 1250/2016, per
      cui non occorre ulteriore consenso. Le aziende sopramenzionate garantiscono, infatti, la propria adesione al
      Privacy Shield.</p>
    <p><strong>CATEGORIE DI DESTINATARI DEI DATI</strong></p>
    <ul>
      <ul>Il Titolare del trattamento, nello svolgimento delle proprie attività e per erogare i propri servizi, potrebbe
        trasmettere i dati alle seguenti categorie di destinatari:<p></p>
        <ul>
          <li>pubbliche amministrazioni (es., autorità di controllo);</li>
          <li>autorità giudiziaria;</li>
          <li>fornitori di servizi tecnici, hosting provider e cloud service provider.</li>
        </ul>
      </ul>
    </ul>
    <p>I destinatari sono nominati, se necessario, Responsabili del trattamento da parte del Titolare.<br>
      L’elenco aggiornato dei responsabili può essere richiesto al Titolare del trattamento</p>
    <p><strong>PERIODO DI CONSERVAZIONE</strong><br>
      I dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.
      Pertanto:</p>
    <ul>
      <li>i dati personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente
        saranno trattenuti nei termini legali previsti;</li>
      <li>i dati personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti
        sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse
        legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare stesso.
      </li>
    </ul>
    <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i dati personali più a lungo,
      sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i
      dati personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.<br>
      Al termine del periodo di conservazione i dati personali saranno cancellati. Pertanto, allo spirare di tale
      termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei dati non potranno
      più essere esercitati.</p>
    <p><strong>DIRITTI DELL’INTERESSATO</strong><br>
      Ad ogni Interessato, sono riconosciuti i seguenti diritti:</p>
    <p><strong>DIRITTO DI ACCESSO E DIRITTO DI RETTIFICA (ARTT. 15 E 16 GDPR):</strong><br>
      L’Interessato ha il diritto di ottenere dal Titolare del trattamento la conferma che sia o meno in corso un
      trattamento di dati personali che lo riguardano e in tal caso, di ottenere l’accesso ai dati personali e alle
      seguenti informazioni:<br>
      a) le finalità del trattamento;<br>
      b) le categorie di dati personali in questione;<br>
      c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in
      particolare se destinatari di paesi terzi o organizzazioni internazionali;<br>
      d) il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per
      determinare tale periodo;<br>
      e) l’esistenza del diritto dell’Interessato di chiedere al Titolare del trattamento la rettifica o la
      cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di
      opporsi al loro trattamento;<br>
      f) il diritto di proporre reclamo all’Autorità Garante per la Protezione dei Dati Personali;<br>
      g) l’esistenza di un processo decisionale automatizzato, compresa la profilazione e, almeno in tali casi,
      informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale
      trattamento per l’Interessato.</p>
    <p><strong>DIRITTO ALLA CANCELLAZIONE (C.D. “DIRITTO ALL’OBLIO”, ART. 17 GDPR):</strong><br>
      L’Interessato ha il diritto di ottenere dal Titolare del trattamento la cancellazione dei dati personali che lo
      riguardano senza ingiustificato ritardo e il Titolare del trattamento ha l’obbligo di cancellare senza
      ingiustificato ritardo i dati personali, se sussiste uno dei motivi seguenti:<br>
      a) i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti
      trattati;<br>
      b) l’Interessato revoca il consenso su cui si basa il trattamento conformemente all’art. 6, c 1, l. a), o
      all’arti. 9, c. 2, l. a), e se non sussiste altro fondamento giuridico per il trattamento;<br>
      c) l’Interessato si oppone al trattamento ai sensi dell’art. 21, c. 1, e non sussiste alcun motivo legittimo
      prevalente per procedere al trattamento, oppure si oppone al trattamento ai sensi dell’art. 21, c. 2;<br>
      d) i dati personali sono stati trattati illecitamente;<br>
      e) i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell’Unione o
      dello Stato membro cui è soggetto il Titolare del trattamento;<br>
      f) i dati personali sono stati raccolti relativamente all’offerta di servizi della società dell’informazione di
      cui all’art. 8, c. 1 del Reg. UE 2016/679.</p>
    <p><strong>DIRITTO DI LIMITAZIONE DEL TRATTAMENTO (ART. 18 GDPR):</strong><br>
      L’Interessato ha il diritto di ottenere dal Titolare del trattamento la limitazione del trattamento quando ricorre
      una delle seguenti ipotesi:<br>
      a) l’Interessato contesta l’esattezza dei dati personali, per il periodo necessario al Titolare del trattamento
      per verificare l’esattezza di tali dati personali;<br>
      b) il trattamento è illecito e l’Interessato si oppone alla cancellazione dei dati personali e chiede invece che
      ne sia limitato l’utilizzo;<br>
      c) benché il Titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono
      necessari all’Interessato per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria;<br>
      d) l’Interessato si è opposto al trattamento ai sensi dell’articolo 21, paragrafo 1, Reg UE 2016/679 in attesa
      della verifica in merito all’eventuale prevalenza dei motivi legittimi del Titolare del trattamento rispetto a
      quelli dell’Interessato.</p>
    <p><strong>OBBLIGO DI NOTIFICA IN CASO DI RETTIFICA O CANCELLAZIONE DEI DATI PERSONALI O LIMITAZIONE DEL TRATTAMENTO
        (ART. 19 GDPR):</strong><br>
      Il Titolare del trattamento comunica a ciascuno dei destinatari cui sono stati trasmessi i dati personali le
      eventuali rettifiche o cancellazioni o limitazioni del trattamento effettuate a norma dell’art. 16, dell’art. 17,
      c. 1, e dell’art. 18, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare del
      trattamento comunica all’Interessato tali destinatari qualora l’Interessato lo richieda.</p>
    <p><strong>DIRITTO ALLA PORTABILITA’ DEI DATI (ART. 20 GDPR):</strong><br>
      L’Interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo
      automatico i dati personali che lo riguardano forniti a un Titolare del trattamento e ha il diritto di trasmettere
      tali dati a un altro Titolare del trattamento senza impedimenti da parte del Titolare del trattamento cui li ha
      forniti qualora:<br>
      a) il trattamento si basi sul consenso ai sensi dell’art. 6, c. 1, l. a), o dell’art. 9, c. 2, l. a), o su un
      contratto ai sensi dell’art. 6, c. 1, l. b); e<br>
      b) il trattamento sia effettuato con mezzi automatizzati.<br>
      Nell’esercitare i propri diritti relativamente alla portabilità dei dati, l’Interessato ha il diritto di ottenere
      la trasmissione diretta dei dati personali da un Titolare del trattamento all’altro, se tecnicamente
      fattibile.<br>
      L’esercizio di questo diritto lascia impregiudicato l’art. 17. Tale diritto non si applica al trattamento
      necessario per l’esecuzione di un compito di interesse pubblico o connesso all’esercizio di pubblici poteri di cui
      è investito il Titolare del trattamento. Tale diritto non deve ledere i diritti e le libertà altrui.</p>
    <p><strong>DIRITTO DI OPPOSIZIONE (ART. 21 GDPR):</strong><br>
      L’Interessato ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare,
      al trattamento dei dati personali che lo riguardano ai sensi dell’art. 6, c. 1, ll. e) o f), compresa la
      profilazione sulla base di tali disposizioni. Il Titolare del trattamento si astiene dal trattare ulteriormente i
      dati personali salvo che egli dimostri l’esistenza di motivi legittimi cogenti per procedere al trattamento che
      prevalgono sugli interessi, sui diritti e sulle libertà dell’Interessato oppure per l’accertamento, l’esercizio o
      la difesa di un diritto in sede giudiziaria.<br>
      Qualora i dati personali siano trattati per finalità di marketing diretto, l’Interessato ha il diritto di opporsi
      in qualsiasi momento al trattamento dei dati personali che lo riguardano effettuato per tali finalità, compresa la
      profilazione nella misura in cui sia connessa a tale marketing diretto. Qualora l’Interessato si opponga al
      trattamento per finalità di marketing diretto, i dati personali non sono più oggetto di trattamento per tali
      finalità.<br>
      Il diritto di cui ai cc. 1 e 2 è esplicitamente portato all’attenzione dell’Interessato ed è presentato
      chiaramente e separatamente da qualsiasi altra informazione al più tardi al momento della prima comunicazione con
      l’Interessato.<br>
      Nel contesto dell’utilizzo di servizi della società dell’informazione e fatta salva la direttiva 2002/58/CE,
      l’Interessato può esercitare il proprio diritto di opposizione con mezzi automatizzati che utilizzano specifiche
      tecniche.<br>
      Qualora i dati personali siano trattati a fini di ricerca scientifica o storica o a fini statistici a norma
      dell’art. 89, c. 1, l’Interessato, per motivi connessi alla sua situazione particolare, ha il diritto di opporsi
      al trattamento di dati personali che lo riguardano, salvo se il trattamento è necessario per l’esecuzione di un
      compito di interesse pubblico.</p>
    <p><strong>PROCESSO DECISIONALE AUTOMATIZZATO RELATIVO ALLE PERSONE FISICHE, COMPRESA LA PROFILAZIONE (ART. 22
        GDPR):</strong><br>
      L’Interessato ha il diritto di non essere sottoposto a una decisione basata unicamente sul trattamento
      automatizzato, compresa la profilazione, che produca effetti giuridici che lo riguardano o che incida in modo
      analogo significativamente sulla sua persona. Questo non si applica nel caso in cui la decisione:<br>
      a) sia necessaria per la conclusione o l’esecuzione di un contratto tra l’Interessato e un Titolare del
      trattamento;<br>
      b) sia autorizzata dal diritto dell’Unione o dello Stato membro cui è soggetto il Titolare del trattamento, che
      precisa altresì misure adeguate a tutela dei diritti, delle libertà e dei legittimi interessi
      dell’Interessato;<br>
      c) si basi sul consenso esplicito dell’Interessato.<br>
      Nei casi di cui alle lettere a) e c), il Titolare del trattamento attua misure appropriate per tutelare i diritti,
      le libertà e i legittimi interessi dell’Interessato, almeno il diritto di ottenere l’intervento umano da parte del
      Titolare del trattamento, di esprimere la propria opinione e di contestare la decisione.<br>
      Le decisioni di cui sopra non si basano sulle categorie particolari di dati personali di cui all’art. 9, c. 1, a
      meno che non sia d’applicazione l’art. 9, c. 2, ll. a) o g), e non siano in vigore misure adeguate a tutela dei
      diritti, delle libertà e dei legittimi interessi dell’Interessato.<br>
      Per esercitare i suddetti diritti è necessario scrivere all’indirizzo e-mail: dpo@policliniconapoli.it.
      L’Interessato ha, inoltre, sempre il diritto di proporre reclamo all’Autorità Garante per la Protezione dei dati
      Personali, contattabile all’indirizzo garante@gpdp.it o mediante il sito www.gpdp.it</p>
    <p><strong>AGGIORNAMENTI DI QUESTO DOCUMENTO</strong><br>
      La privacy policy del Sito può essere periodicamente aggiornata, per cui si consiglia di consultare questo
      documento ogni volta che si accede al Sito, al fine di essere correttamente informati su come e perché usiamo i
      cookie. Per ulteriori informazioni, è possibile contattare il seguente indirizzo e-mail: dpo@policliniconapoli.it
    </p>
    <p><strong>ACRONIMI E ABBREVIAZIONI</strong><br>
      <strong>DPO:</strong> Data Protection Officer – Responsabile della Protezione dei Dati.<br>
      <strong>EDPB:</strong> European Data Protection Board – Comitato europeo per la Protezione dei Dati. Organismo
      europeo indipendente il cui scopo è garantire un’applicazione coerente del GDPR.<br>
      <strong>GDPR:</strong> General Data Protection Regulation – Regolamento Generale sulla Protezione dei Dati, n.
      2016/679.<br>
      <strong>IT</strong> Information Technology.<br>
      <strong>WP29:</strong> Working Group 29: gruppo istituito ai sensi dell’art. 29 della direttiva 95/46 CE. Dal 25
      Maggio 2018 prende il nome di European Data Protection Board.
    </p>
    <p><strong>DEFINIZIONI</strong><br>
      <strong>Archivio: </strong>Qualsiasi insieme strutturato di dati personali accessibili secondo criteri
      determinati, indipendentemente dal fatto che tale insieme sia centralizzato, decentralizzato o ripartito in modo
      funzionale o geografico (art. 4, n. 6, GDPR).<br>
      <strong>Applicazione: </strong>Lo strumento hardware o software mediante il quale sono raccolti e trattati i dati
      personali degli Utenti.<br>
      <strong>Autenticazione informatica:</strong> L’insieme degli strumenti elettronici e delle procedure per la
      verifica, anche indiretta, dell’identità dell’utente.<br>
      <strong>Autorità Garante per la Protezione dei Dati Personali:</strong> Autorità istituita dalla legge 31 dicembre
      1996, n. 675. Ha sede a Roma.<br>
      <strong>Banca dati:</strong> Qualsiasi complesso organizzato di dati (archivio informatico), riguardanti uno
      stesso argomento o più argomenti correlati tra loro, strutturato in modo tale da consentire la gestione dei dati
      stessi (l’inserimento, la ricerca, la cancellazione ed il loro aggiornamento) da parte di un applicazione,
      ripartito in uno o più elaboratori elettronici (ad es. server, postazioni lavorative, ecc.) dislocati all’interno
      della rete LAN del Titolare.<br>
      <strong>Cancellazione sicura:</strong> Modalità di cancellazione che consiste nell’eliminazione irreversibile dei
      dati contenuti in un supporto elettronico in modo che essi non siano più accessibili a terzi o risultino comunque
      inintelligibili impedendo così il recupero degli stessi.<br>
      <strong>Categorie particolari di dati personali:</strong> Dati personali che rivelano l’origine razziale o etnica,
      le opinioni politiche, le convinzioni religiose o filosofiche, o l’appartenenza sindacale, nonché trattare dati
      genetici, dati biometrici intesi a identificare in modo univoco una persona fisica, dati relativi alla salute o
      alla vita sessuale o all’orientamento sessuale della persona (art. 9, c. 1, GDPR).<br>
      <strong>Comunicazione:</strong> Il dare conoscenza dei dati personali a uno o più soggetti determinati diversi
      dall’Interessato, dal rappresentante del Titolare nel territorio dello Stato, dal Responsabile e dagli Incaricati,
      in qualunque forma, anche mediante la loro messa a disposizione o consultazione.<br>
      <strong>Comunicazione elettronica:</strong> Ogni informazione scambiata o trasmessa tra un numero finito di
      soggetti tramite un servizio di comunicazione elettronica accessibile al pubblico. Sono escluse le informazioni
      trasmesse al pubblico tramite una rete di comunicazione elettronica, come parte di un servizio di radiodiffusione,
      salvo che le stesse informazioni siano collegate ad un abbonato o utente ricevente, identificato o
      identificabile.<br>
      <strong>Consenso dell’Interessato:</strong> Qualsiasi manifestazione di volontà libera, specifica, informata e
      inequivocabile dell’Interessato, con la quale lo stesso manifesta il proprio assenso, mediante dichiarazione o
      azione positiva inequivocabile, che i dati personali che lo riguardano siano oggetto di trattamento (art. 4, n.
      11, GDPR).<br>
      <strong>Cookie: </strong>Piccola porzione di dati conservata all’interno del dispositivo dell’Utente.<br>
      <strong>Credenziali di autenticazione:</strong> I dati ed i dispositivi in possesso di una persona, da questa
      conosciuti o ad essa univocamente correlati, utilizzati per l’autenticazione informatica.<br>
      <strong>Dati personali relativi a condanne penali o reati:</strong> Dati personali relativi alle condanne penali e
      ai reati o a connesse misure di sicurezza (art. 10 GDPR).<br>
      <strong>Dati di utilizzo:</strong> Sono le informazioni raccolte automaticamente attraverso questo Sito Web (anche
      da applicazioni di parti terze integrate in questo Sito Web), tra cui: gli indirizzi IP o i nomi a dominio dei
      computer utilizzati dall’Utente che si connette con questo Sito Web, gli indirizzi in notazione URI (Uniform
      Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la
      dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon
      fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal
      visitatore, le varie connotazioni temporali della visita (ad es., il tempo di permanenza su ciascuna pagina) e i
      dettagli relativi all’itinerario seguito all’interno del portale, con particolare riferimento alla sequenza delle
      pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.<br>
      <strong>Dato personale: </strong>Qualsiasi informazione riguardante una persona fisica identificata o
      identificabile (“Interessato”); si considera identificabile la persona fisica che può essere identificata,
      direttamente o indirettamente, con particolare riferimento a un identificativo come il nome, un numero di
      identificazione, dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici
      della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale (art. 4, n. 1,
      GDPR).<br>
      <strong>Destinatario:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o un altro
      organismo che riceve comunicazione di dati personali, che si tratti o meno di terzi. Tuttavia, le autorità
      pubbliche che possono ricevere comunicazione di dati personali nell’ambito di una specifica indagine conformemente
      al diritto dell’Unione o degli Stati membri non sono considerate destinatari; il trattamento di tali dati da parte
      di dette autorità pubbliche è conforme alle norme applicabili in materia di protezione dei dati secondo le
      finalità del trattamento.<br>
      <strong>Diffusione:</strong> Il dare conoscenza dei dati personali a soggetti indeterminati, in qualunque forma,
      anche mediante la loro messa a disposizione o consultazione.<br>
      <strong>Documento cartaceo:</strong> L’insieme aggregato di informazioni su supporti cartacei.<br>
      <strong>Documento elettronico:</strong> L’insieme aggregato di informazioni su supporti informatici, ovvero file
      generati attraverso l’utilizzo delle applicazioni informatiche del Titolare.<br>
      <strong>Incaricato del trattamento:</strong> Persona fisica autorizzata a compiere operazioni di trattamento dal
      Titolare o dal Responsabile.<br>
      <strong>Informazione: </strong>La rappresentazione di dati, atti o fatti rilevanti per il Titolare.<br>
      <strong>Interessato del trattamento:</strong> Persona fisica cui si riferiscono i dati personali.<br>
      <strong>Minimizzazione dei dati:</strong> Ogni Titolare deve trattare solo i dati di cui ha realmente bisogno per
      raggiungere le finalità del trattamento, nel rispetto dei principi di adeguatezza (proporzionalità rispetto alle
      finalità) e pertinenza dei dati e limitazione dei trattamenti solo per il raggiungimento delle finalità
      previste.<br>
      <strong>Parola chiave:</strong> Componente di una credenziale di autenticazione associata ad una persona ed a
      questa nota, costituita da una sequenza di caratteri o altri dati in forma elettronica.<br>
      <strong>Profilo di autorizzazione:</strong> L’insieme delle informazioni, univocamente associate ad una persona,
      che consente di individuare a quali dati essa può accedere, nonché i trattamenti ad essa consentiti.<br>
      <strong>Pseudonimizzazione:</strong> Il trattamento dei dati personali in modo tale che i dati personali non
      possano più essere attribuiti a un Interessato specifico senza l’utilizzo di informazioni aggiuntive, a condizione
      che tali informazioni aggiuntive siano conservate separatamente e soggette a misure tecniche e organizzative
      intese a garantire che tali dati personali non siano attribuiti a una persona fisica identificata o identificabile
      (art. 4, n. 5, GDPR).<br>
      <strong>Responsabile del trattamento:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o
      altro organismo che tratta dati personali per conto del Titolare del trattamento (art. 4, n. 8, GDPR).<br>
      <strong>Servizio: </strong>Il Servizio fornito dal Sito Web così come definito nei relativi termini (se presenti)
      su questo sito/applicazione.<br>
      <strong>Sito Web:</strong> Lo strumento hardware o software mediante il quale sono raccolti e trattati i dati
      personali degli Utenti.<br>
      <strong>Sistema di autorizzazione:</strong> L’insieme degli strumenti e delle procedure che abilitano l’accesso ai
      dati e alle modalità di trattamento degli stessi, in funzione del profilo di autorizzazione del richiedente.<br>
      <strong>Sistema informativo:</strong> L’insieme di dispositivi, programmi ed infrastruttura di rete.<br>
      <strong>Titolare del trattamento:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o altro
      organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati
      personali; quando le finalità e i mezzi di tale trattamento sono determinati dal diritto dell’Unione o degli Stati
      membri, il Titolare del trattamento o i criteri specifici applicabili alla sua designazione possono essere
      stabiliti dal diritto dell’Unione o degli Stati membri (art. 4, n. 7, GDPR).<br>
      <strong>Trattamento:</strong> Qualsiasi operazione o insieme di operazioni, compiute con o senza l’ausilio di
      processi automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la
      registrazione, l’organizzazione, la strutturazione, la conservazione, l’adattamento o la modifica, l’estrazione,
      la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a
      disposizione, il raffronto o l’interconnessione, la limitazione, la cancellazione o la distruzione (art. 4, n. 2,
      GDPR).<br>
      <strong>Utente: </strong>L’individuo che utilizza il Sito Web che, salvo ove diversamente specificato, coincide
      con l’Interessato.<br>
      <strong>Violazione dei dati personali:</strong> Violazione di sicurezza che comporta accidentalmente o in modo
      illecito la distruzione, la perdita, la modifica, la divulgazione non autorizzata o l’accesso ai dati personali
      trasmessi, conservati o comunque trattati (art. 4, n. 12, GDPR).
    </p>
    <p><strong>NORMATIVA DI RIFERIMENTO</strong><br>
      <strong>D.lgs. n. 101/2018:</strong> Disposizioni per l’adeguamento della normativa nazionale alle disposizioni
      del Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio, del 27 aprile 2016 (GDPR).<br>
      <strong>D.lgs. n. 196/2003:</strong> Decreto Legislativo n. 196 del 30 giugno 2003, contenente il “Codice in
      materia di protezione dei dati personali”, n. c. “Codice Privacy”.<br>
      <strong>Regolamento UE n. 2016/679:</strong> Regolamento del Parlamento Europeo e del Consiglio, del 27 aprile
      2016, relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla
      libera circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei
      dati).
    </p>
    <h2><strong>COOKIE POLICY</strong></h2>
    <p>In conformità alla normativa vigente, si informano gli Utenti sui cookie utilizzati dal sito web dell’Azienda
      Ospedaliera Universitaria “Luigi Vanvitelli” (di seguito, il “Sito”).</p>
    <p>Le seguenti informazioni integrano quanto riportato nella politica sulla privacy pubblicata sul Sito.</p>
    <p>Per quanto non direttamente esplicitato qui, si prega di fare riferimento ai contenuti della Privacy Policy.</p>
    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito Web.</p>
    <p><strong>COSA SONO I COOKIE</strong></p>
    <p>I cookie sono piccoli file di testo, in genere lettere o numeri, che possono essere utilizzati dai siti web per
      rendere più efficiente l’esperienza per l’Utente.</p>
    <p>Sono costituiti da porzioni di codice installate all’interno del browser che assistono il Titolare
      nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie
      potrebbero, inoltre, necessitare del consenso dell’Utente.</p>
    <p>Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente
      in ogni momento seguendo le istruzioni contenute in questo documento.</p>
    <p><strong>PERCHÉ SI UTILIZZANO I COOKIE</strong></p>
    <p>L’uso di cookie offre numerosi vantaggi nella fornitura di servizi della società dell’informazione, tra i quali:
    </p>
    <ul>
      <li>facilita la navigazione dell’Utente sul sito web e l’accesso ai vari servizi offerti;</li>
      <li>evita che l’Utente debba configurare nuovamente le caratteristiche generali ogni volta che accede al Sito;
      </li>
      <li>promuove il miglioramento delle prestazioni e dei servizi forniti attraverso il Sito, in seguito alla
        corrispondente analisi delle informazioni ottenute attraverso i cookie installati;</li>
      <li>promuovere iniziative commerciali personalizzate, proprie o di terzi, fondate sulle scelte operate
        dall’Utente.</li>
    </ul>
    <p><strong>TIPI DI COOKIE</strong></p>
    <p><strong>Cookie tecnici</strong></p>
    <p>Taluni cookie soddisfano mere esigenze di natura tecnica che contribuiscono a rendere fruibile un sito web
      abilitando le funzioni di base come la navigazione della pagina e l’accesso alle aree protette del Sito.</p>
    <p>Il Sito web potrebbe non funzionare correttamente senza questi cookie.</p>
    <p>Talvolta vengono utilizzati anche cookie che consentono a un sito web di ricordare le informazioni che
      influenzano il modo in cui il sito si comporta o si presenta, come la lingua preferita o la regione in cui
      l’Utente si trova oppure per capire come i visitatori interagiscono con il sito raccogliendo e trasmettendo
      informazioni in forma aggregata e non suscettibile di individuare direttamente l’Utente (analytics).</p>
    <p>Questi tipi di cookie sono anche definiti “<em>tecnici</em>”. La legge afferma che i cookie tecnici possono
      essere installati sul dispositivo dell’Utente previa informativa, cui si provvede con questo documento.</p>
    <p><strong>Cookie di profilazione</strong></p>
    <p>Tutti gli altri cookie, insieme ai cookie statistici gestiti da terzi, sono considerati cookie “<em>di
        profilazione</em>”.</p>
    <p>Rientrano in questa categoria soprattutto i cookie utilizzati per monitorare e analizzare l’attività svolta
      dall’Utente in rete, al fine di apportare miglioramenti e fornire i nostri servizi in modo più efficiente e
      personalizzato. L’intento è quello di proporre al visitatore la visualizzazione di annunci pertinenti e
      coinvolgenti per il singolo Utente. Per l’utilizzo di questi cookie occorre il consenso preventivo dell’Utente: al
      primo accesso, si considera che l’Utente abbia acconsentito all’installazione dei cookie di profilazione se
      prosegue nella navigazione dopo la presentazione dell’avviso sui cookie contenuto nell’apposito banner.
      Successivamente, l’Utente potrà negare il consenso selezionando le apposite caselle contenute nei moduli
      descrittivi presenti in questo documento.</p>
    <p><strong>Altre tipologie di cookie o strumenti terzi che potrebbero installarne</strong></p>
    <p>Alcuni servizi raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso
      dell’Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza
      l’ausilio di terzi.</p>
    <p>Qualora fra gli strumenti utilizzati fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a
      quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Per
      informazioni dettagliate in merito, si consiglia di consultare le privacy policy dei servizi presenti.</p>
    <p><strong>DISTINZIONI TRA COOKIE</strong></p>
    <p>I cookie possono distinguersi nelle seguenti categorie secondo la caratteristica presa in esame: il soggetto di
      appartenenza, la durata di conservazione, la finalità d’uso.</p>
    <p><strong>Distinzione per soggetto di appartenenza</strong></p>
    <ul>
      <li><strong>Cookie di prima parte</strong>: Questi sono inviati al terminale del computer dell’Utente da un
        computer o dominio gestito dal gestore del Sito, e dal quale viene fornito il servizio richiesto dall’Utente.
      </li>
    </ul>
    <p>Nel caso questo Sito, si tratta di i cookie impostati da, o per conto di, Azienda Ospedaliera Universitaria
      “Luigi Vanvitelli”.</p>
    <ul>
      <li><strong>Cookie di terze parti</strong>: Questi sono inviati al terminale dell’Utente da una macchina o un
        dominio che non è gestito dal gestore del Sito, ma da un altro soggetto che è colui che elabora i dati ottenuti
        tramite cookie: cioè i cookie che sono impostati da siti web diversi da raipubblicita.it per finalità proprie.
      </li>
    </ul>
    <p>I cookie installati tramite il Sito sono sia di prima parte sia cookie di terze parti.</p>
    <p><strong>Distinzione per durata di conservazione</strong></p>
    <ul>
      <li><strong>Cookie di sessione</strong>: Questi cookie sono progettati per raccogliere e memorizzare i dati mentre
        l’Utente accede a una pagina web. Essi sono spesso utilizzati per memorizzare informazioni solo per il servizio
        richiesto dall’Utente in qualsiasi momento e sono automaticamente cancellati al termine della sessione (ad
        esempio, un elenco dei prodotti acquistati).</li>
      <li><strong>Cookie persistenti</strong>: I dati vengono memorizzati in questi cookie nel terminale dell’Utente e
        ad essi vi si può accedere e possono essere utilizzati per un periodo di tempo definito dall’Azienda Ospedaliera
        Universitaria “Luigi Vanvitelli”, che può durare da pochi minuti a periodi considerevolmente più estesi (come
        precisato nelle tabelle di riferimento presenti in questo documento).</li>
    </ul>
    <p><strong>Distinzione per finalità d’uso</strong></p>
    <ul>
      <li><strong>Cookie essenziali</strong>: consentono di navigare nel Sito e di utilizzarne tutte le funzionalità.
        Senza questi cookie non potrebbero essere forniti alcuni servizi o funzioni e la navigazione sul Sito non
        sarebbe agevole e funzionale.</li>
      <li><strong>Cookie di funzionalità</strong>: consentono di ricordare le scelte dell’Utente sul Sito e/o i servizi
        che espressamente l’Utente richiede, allo scopo di fornirgli servizi più avanzati e più personalizzati, come ad
        esempio personalizzare una certa pagina o ricordare se e cosa è stato proposto all’Utente in precedenza.</li>
      <li><strong>Cookie di analisi</strong>: raccolgono informazioni circa l’utilizzo del Sito da parte dell’Utente
        (come l’indicazione delle pagine più frequentemente visitate, la verifica degli schemi ricorrenti d’utilizzo del
        Sito, ecc.) e consentono di migliorarne il funzionamento, aiutando a comprendere ogni difficoltà incontrata
        nell’utilizzo del Sito.</li>
      <li><strong>Cookie di targeting e advertising</strong>: per pubblicare pubblicità che si ritiene di interesse per
        l’Utente, ad esempio limitando il numero di volte in cui viene presentata una pubblicità sul Sito (in quanto il
        cookie ricorda cosa l’Utente ha visto sul Sito) ed aiutare a misurare l’efficacia delle campagne pubblicitarie.
      </li>
    </ul>
    <p><strong>MODALITÀ DI GESTIONE DEL CONSENSO</strong></p>
    <p>L’Utente può revocare il consenso all’utilizzo dei cookie su questo Sito selezionando le apposite caselle
      contenute nei moduli descrittivi di questo documento; l’Utente può anche configurare il browser per accettare o
      rifiutare tutti i cookie, o selezionare quelli alla cui installazione l’Utente acconsente e quelli che invece
      rifiuta.</p>
    <p><strong>COME SI ESPRIME IL CONSENSO</strong></p>
    <p>In aggiunta a quanto indicato in questo documento, l’Utente può gestire le preferenze relative ai Cookie
      direttamente all’interno del proprio browser ed impedire, ad esempio, che terze parti possano installarne.</p>
    <p>Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie
      in cui venga eventualmente salvato il consenso all’installazione di Cookie da parte di questo Sito.</p>
    <p>L’Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai
      seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer.</p>
    <p>Con riferimento a Cookie installati da terze parti, l’Utente può inoltre gestire le proprie impostazioni e
      revocare il consenso visitando il relativo link di opt-out (qualora disponibile), utilizzando gli strumenti
      descritti nella privacy policy della terza parte o contattando direttamente la stessa.</p>
    <p><strong>MODALITÀ DI CONFIGURAZIONE DEL BROWSER</strong></p>
    <p>In merito alla configurazione del browser, l’Utente potrà far ricorso ad una delle seguenti procedure, a seconda
      del tipo di browser utilizzato:</p>
    <ul>
      <li><strong>Google Chrome</strong>: Strumenti/Impostazioni &gt; Mostra impostazioni avanzate &gt; Privacy
        (Impostazioni contenuti) &gt; Cookie</li>
      <li><strong>Microsoft Internet Explorer</strong>: Strumenti Menù &gt; Opzioni Internet &gt; Privacy &gt; Avanzate
      </li>
      <li><strong>Safari</strong>: Preferiti &gt; Privacy</li>
      <li><strong>Firefox</strong>: Strumenti Menù &gt; Opzioni &gt; Privacy &gt; Cookie</li>
      <li><strong>Opera</strong>: Configurazione &gt; Opzioni &gt; Avanzate &gt; Cookie</li>
    </ul>
    <p><strong>COOKIE UTILIZZATI</strong></p>
    <p>Le seguenti tabelle descrittive dei cookie installati tramite il Sito, specificano il soggetto di appartenenza,
      la finalità di ciascuno, la durata di conservazione nonché l’esercizio del consenso o dell’opposizione.</p>
    <p><strong>Nome cookie: </strong> cb-enabled<br>
      <strong>Proprietario: </strong><br>
      <strong>Durata: </strong> Scade dopo 12 mesi<br>
      <strong>Scopo: </strong> Registra l’accettazione da parte dell’Utente dell’utilizzo dei cookie da parte del sito,
      accettazione resa possibile nell’informativa breve della home page o nella sezione che ospita la cookie
      policy.<br>
      <strong>Consenso: </strong>
    </p>
    <p><strong>Nome cookie: </strong> cb-settings<br>
      <strong>Proprietario: </strong><br>
      <strong>Durata: </strong> 10 minuti<br>
      <strong>Scopo: </strong> Registra l’accettazione da parte dell’Utente dell’utilizzo dei cookie da parte del sito,
      accettazione resa possibile nell’informativa breve della home page o nella sezione che ospita la cookie
      policy.<br>
      <strong>Consenso: </strong>
    </p>
    <p><strong>Nome cookie: </strong> _ga<br>
      <strong>Proprietario: </strong> Google Analytics<br>
      <strong>Durata: </strong> Scade 2 anni<br>
      <strong>Scopo: </strong> Cookie di tracciamento di terze parti utilizzato per l’analisi delle visite.<br>
      <strong>Consenso: </strong> SI/NO
    </p>
    <p><strong>Nome cookie: </strong> _gat<br>
      <strong>Proprietario: </strong> Google Analytics<br>
      <strong>Durata: </strong> Scade 2 anni<br>
      <strong>Scopo: </strong> Cookie di tracciamento di terze parti utilizzato per l’analisi delle visite.<br>
      <strong>Consenso: </strong> SI/NO
    </p>
    <p><strong>Nome cookie: </strong> _gid<br>
      <strong>Proprietario: </strong> Google Analytics<br>
      <strong>Durata: </strong> 24 Ore<br>
      <strong>Scopo: </strong> Used to distinguish users.<br>
      <strong>Consenso: </strong> SI/NO
    </p>
    <p><strong>Cookie di analisi</strong><br>
      I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i
      dati di traffico e servono a tener traccia del comportamento dell’Utente.</p>
    <p><strong>Google Analytics (Google Ireland Limited)</strong><br>
      Google Analytics è un servizio di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i Dati
      Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e
      condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per
      contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
    <ul>
      <li>Dati Personali raccolti: Cookie e Dati di utilizzo.</li>
      <li>Luogo del trattamento: Irlanda – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.</li>
    </ul>
    <p><strong>AGGIORNAMENTI DI QUESTO DOCUMENTO</strong><br>
      La politica sui cookie del Sito può essere periodicamente aggiornata, per cui si consiglia di consultare questo
      documento ogni volta che si accede al Sito, al fine di essere correttamente informati su come e perché usiamo i
      cookie.</p>
    <p><strong>ACRONIMI E ABBREVIAZIONI</strong><br>
      DPO Data Protection Officer – Responsabile della Protezione dei Dati<br>
      EDPB European Data Protection Board – Comitato europeo per la Protezione dei Dati.<br>
      Organismo europeo indipendente il cui scopo è garantire un’applicazione coerente del GDPR.<br>
      GDPR General Data Protection Regulation – Regolamento Generale sulla Protezione dei Dati, n. 2016/679<br>
      IT Information Technology<br>
      WP29 Working Group 29: gruppo istituito ai sensi dell’art. 29 della direttiva 95/46 CE. Dal 25 Maggio 2018 prende
      il nome di European Data Protection Board.</p>
    <p><strong>DEFINIZIONI</strong><br>
      <strong>Archivio:</strong> Qualsiasi insieme strutturato di dati personali accessibili secondo criteri
      determinati, indipendentemente dal fatto che tale insieme sia centralizzato, decentralizzato o ripartito in modo
      funzionale o geografico (art. 4, n. 6, GDPR).<br>
      <strong> Applicazione:</strong> Lo strumento hardware o software mediante il quale sono raccolti e trattati i dati
      personali degli Utenti.<br>
      <strong> Autenticazione informatica:</strong> L’insieme degli strumenti elettronici e delle procedure per la
      verifica, anche indiretta, dell’identità dell’Utente.<br>
      <strong> Autorità Garante per la Protezione dei Dati Personali:</strong> Autorità istituita dalla legge 31
      dicembre 1996, n. 675. Ha sede a Roma.<br>
      <strong> Banca dati:</strong> Qualsiasi complesso organizzato di dati (archivio informatico), riguardanti uno
      stesso argomento o più argomenti correlati tra loro, strutturato in modo tale da consentire la gestione dei dati
      stessi (l’inserimento, la ricerca, la cancellazione ed il loro aggiornamento) da parte di un applicazione,
      ripartito in uno o più elaboratori elettronici (ad es. server, postazioni lavorative, ecc.) dislocati all’interno
      della rete LAN del Titolare.<br>
      <strong> Cancellazione sicura:</strong> Modalità di cancellazione che consiste nell’eliminazione irreversibile dei
      dati contenuti in un supporto elettronico in modo che essi non siano più accessibili a terzi o risultino comunque
      inintelligibili impedendo così il recupero degli stessi.<br>
      <strong> Categorie particolari di dati personali:</strong> Dati personali che rivelano l’origine razziale o
      etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l’appartenenza sindacale, nonché trattare
      dati genetici, dati biometrici intesi a identificare in modo univoco una persona fisica, dati relativi alla salute
      o alla vita sessuale o all’orientamento sessuale della persona (art. 9, c. 1, GDPR).<br>
      <strong> Comunicazione:</strong> Il dare conoscenza dei dati personali a uno o più soggetti determinati diversi
      dall’interessato, dal rappresentante del Titolare nel territorio dello Stato, dal Responsabile e dagli Incaricati,
      in qualunque forma, anche mediante la loro messa a disposizione o consultazione.<br>
      <strong> Comunicazione elettronica:</strong> Ogni informazione scambiata o trasmessa tra un numero finito di
      soggetti tramite un servizio di comunicazione elettronica accessibile al pubblico. Sono escluse le informazioni
      trasmesse al pubblico tramite una rete di comunicazione elettronica, come parte di un servizio di radiodiffusione,
      salvo che le stesse informazioni siano collegate ad un abbonato o Utente ricevente, identificato o
      identificabile.<br>
      <strong> Consenso dell’interessato:</strong> Qualsiasi manifestazione di volontà libera, specifica, informata e
      inequivocabile dell’interessato, con la quale lo stesso manifesta il proprio assenso, mediante dichiarazione o
      azione positiva inequivocabile, che i dati personali che lo riguardano siano oggetto di trattamento (art. 4, n.
      11, GDPR).<br>
      <strong> Cookie:</strong> Piccola porzione di dati conservata all’interno del dispositivo dell’Utente.<br>
      <strong> Credenziali di autenticazione:</strong> I dati ed i dispositivi in possesso di una persona, da questa
      conosciuti o ad essa univocamente correlati, utilizzati per l’autenticazione informatica.<br>
      <strong> Dati personali relativi a condanne penali o reati:</strong> Dati personali relativi alle condanne penali
      e ai reati o a connesse misure di sicurezza (art. 10 GDPR).<br>
      <strong> Dati di utilizzo:</strong> Sono le informazioni raccolte automaticamente attraverso questo Sito Web
      (anche da applicazioni di parti terze integrate in questo Sito Web), tra cui: gli indirizzi IP o i nomi a dominio
      dei computer utilizzati dall’Utente che si connette con questo Sito Web, gli indirizzi in notazione URI (Uniform
      Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la
      dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon
      fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal
      visitatore, le varie connotazioni temporali della visita (ad es., il tempo di permanenza su ciascuna pagina) e i
      dettagli relativi all’itinerario seguito all’interno del portale, con particolare riferimento alla sequenza delle
      pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.<br>
      <strong> Dato personale:</strong> Qualsiasi informazione riguardante una persona fisica identificata o
      identificabile (“interessato”); si considera identificabile la persona fisica che può essere identificata,
      direttamente o indirettamente, con particolare riferimento a un identificativo come il nome, un numero di
      identificazione, dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici
      della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale (art. 4, n. 1,
      GDPR).<br>
      <strong> Destinatario:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o un altro
      organismo che riceve comunicazione di dati personali, che si tratti o meno di terzi. Tuttavia, le autorità
      pubbliche che possono ricevere comunicazione di dati personali nell’ambito di una specifica indagine conformemente
      al diritto dell’Unione o degli Stati membri non sono considerate destinatari; il trattamento di tali dati da parte
      di dette autorità pubbliche è conforme alle norme applicabili in materia di protezione dei dati secondo le
      finalità del trattamento.<br>
      <strong> Diffusione:</strong> Il dare conoscenza dei dati personali a soggetti indeterminati, in qualunque forma,
      anche mediante la loro messa a disposizione o consultazione.<br>
      <strong> Documento cartaceo:</strong> L’insieme aggregato di informazioni su supporti cartacei.<br>
      <strong> Documento elettronico:</strong> L’insieme aggregato di informazioni su supporti informatici, ovvero file
      generati attraverso l’utilizzo delle applicazioni informatiche del Titolare.<br>
      <strong> Incaricato del trattamento:</strong> Persona fisica autorizzata a compiere operazioni di trattamento dal
      Titolare o dal Responsabile.<br>
      <strong> Informazione:</strong> La rappresentazione di dati, atti o fatti rilevanti per il Titolare.<br>
      <strong> Interessato del trattamento:</strong> Persona fisica cui si riferiscono i dati personali.<br>
      <strong> Minimizzazione dei dati:</strong> Ogni Titolare deve trattare solo i dati di cui ha realmente bisogno per
      raggiungere le finalità del trattamento, nel rispetto dei principi di adeguatezza (proporzionalità rispetto alle
      finalità) e pertinenza dei dati e limitazione dei trattamenti solo per il raggiungimento delle finalità
      previste.<br>
      <strong> Parola chiave:</strong> Componente di una credenziale di autenticazione associata ad una persona ed a
      questa nota, costituita da una sequenza di caratteri o altri dati in forma elettronica.<br>
      <strong> Profilo di autorizzazione:</strong> L’insieme delle informazioni, univocamente associate ad una persona,
      che consente di individuare a quali dati essa può accedere, nonché i trattamenti ad essa consentiti.<br>
      <strong> Pseudonimizzazione:</strong> Il trattamento dei dati personali in modo tale che i dati personali non
      possano più essere attribuiti a un interessato specifico senza l’utilizzo di informazioni aggiuntive, a condizione
      che tali informazioni aggiuntive siano conservate separatamente e soggette a misure tecniche e organizzative
      intese a garantire che tali dati personali non siano attribuiti a una persona fisica identificata o identificabile
      (art. 4, n. 5, GDPR).<br>
      <strong> Responsabile del trattamento:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o
      altro organismo che tratta dati personali per conto del titolare del trattamento (art. 4, n. 8, GDPR).<br>
      <strong> Servizio:</strong> Il Servizio fornito dal Sito Web così come definito nei relativi termini (se presenti)
      su questo sito/applicazione.<br>
      <strong> Sito Web:</strong> Lo strumento hardware o software mediante il quale sono raccolti e trattati i dati
      personali degli Utenti.<br>
      <strong> Sistema di autorizzazione:</strong> L’insieme degli strumenti e delle procedure che abilitano l’accesso
      ai dati e alle modalità di trattamento degli stessi, in funzione del profilo di autorizzazione del
      richiedente.<br>
      <strong> Sistema informativo:</strong> L’insieme di dispositivi, programmi ed infrastruttura di rete.<br>
      <strong> Titolare del trattamento:</strong> La persona fisica o giuridica, l’autorità pubblica, il servizio o
      altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati
      personali; quando le finalità e i mezzi di tale trattamento sono determinati dal diritto dell’Unione o degli Stati
      membri, il titolare del trattamento o i criteri specifici applicabili alla sua designazione possono essere
      stabiliti dal diritto dell’Unione o degli Stati membri (art. 4, n. 7, GDPR).<br>
      <strong> Trattamento:</strong> Qualsiasi operazione o insieme di operazioni, compiute con o senza l’ausilio di
      processi automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la
      registrazione, l’organizzazione, la strutturazione, la conservazione, l’adattamento o la modifica, l’estrazione,
      la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a
      disposizione, il raffronto o l’interconnessione, la limitazione, la cancellazione o la distruzione (art. 4, n. 2,
      GDPR).<br>
      <strong> Utente:</strong> L’individuo che utilizza il Sito Web che, salvo ove diversamente specificato, coincide
      con l’Interessato.<br>
      <strong> Violazione dei dati personali:</strong> Violazione di sicurezza che comporta accidentalmente o in modo
      illecito la distruzione, la perdita, la modifica, la divulgazione non autorizzata o l’accesso ai dati personali
      trasmessi, conservati o comunque trattati (art. 4, n. 12, GDPR).
    </p>
    <p><strong>NORMATIVA DI RIFERIMENTO</strong><br>
      D.lgs. n. 101/2018 Disposizioni per l’adeguamento della normativa nazionale alle disposizioni del Regolamento (UE)
      2016/679 del Parlamento europeo e del Consiglio, del 27 aprile 2016 (GDPR).<br>
      D.lgs. n. 196/2003 Decreto Legislativo n. 196 del 30 giugno 2003, contenente il “Codice in materia di protezione
      dei dati personali”, n. c. “Codice Privacy”.<br>
      Regolamento UE<br>
      n. 2016/679 Regolamento del Parlamento Europeo e del Consiglio, del 27 aprile 2016, relativo alla protezione delle
      persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che
      abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei dati).</p>
    <div class="cl"></div>
  </div>
</div>