<a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" class="btn-icon anchor-banner">
  <span class="rounded-icon span-banner">
    <svg class="icon icon-primary">
      <use xlink:href="../../../assets/sprite.svg#it-user"></use>
    </svg>
  </span>
  <span *ngIf="user" class="d-none d-lg-block" style="cursor: pointer">{{ "MESSAGE.WELCOME" | translate }} {{ getName()
    }}
  </span>
  <svg class="icon d-none d-lg-block">
    <use style="position: relative; top: 1px; cursor: pointer" xlink:href=" ../../../assets/sprite.svg#it-expand"></use>
  </svg>
</a>
<div class="dropdown-menu" style="margin: 5%">
  <div class="row">
    <div class="col-12">
      <div class="link-list-wrapper">
        <ul class="link-list">
          <li>
            <a class="list-item" style="cursor: pointer" (click)="doLogout()"><span>
                <b> {{ "MESSAGE.LOGOUT" | translate }} </b></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>