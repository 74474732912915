<app-banner-info [page]="'company'" [title]="'HOME_SUBVIEWS.HOSPITAL_AGENCY'" [subtitle]="'COMPANY.INFO_SUBTITLE'">
</app-banner-info>

<!-- * COMPANY PRESENTATION  -->
<app-in-evidence-body [bodyList]="cardsArray" [titleText]="'COMPANY.WHO_WE_ARE'"
  [typology]="'company-presentation'"></app-in-evidence-body>


<!-- * COMPANY MANAGEMENT -->
<!-- ALL -->
<ng-container *ngIf="companyResponse && companyResponse.length > 0">
  <app-main-all-body *ngIf="!loaderPage" id="all-company-management" [typology]="'company-management'"
    [mainTitle]="'COMPANY_MANAGEMENT.COMPANY_MANAGEMENT_TITLE'" [bodyList]="companyResponse">
  </app-main-all-body>
</ng-container>
<!-- END ALL -->