<table class="table" *ngIf="data && data.length > 0">
  <!-- * DESKTOP * -->

  <thead class="table-header">
    <tr>
      <th scope="col">{{ (postTypeData === 'atti_not' ? "TENDERS.NAME" : "MESSAGE_TABLE.TABLE_TITLE") | translate }}
      </th>
      <th *ngIf="showDate" scope="col-sm-4 ">
        {{ "MESSAGE_TABLE.TABLE_PUBLICATION_DATE" | translate }}
      </th>
      <th scope="col" *ngIf="postTypeData == 'com_etic'" class="table-body-font text-align-right">
        {{ "MESSAGE_TABLE.TABLE_PUBLICATION_DATE" | translate }}
      </th>
      <th scope="col" *ngIf="postTypeData == 'prod_az'" class="table-body-font text-align-right">
        {{ "MESSAGE_TABLE.TABLE_PUBLICATION_DATE" | translate }}
      </th>
      <th scope="col-sm-1" style="text-align: right">
        {{ "MESSAGE_TABLE.ATTACHED" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of data" [attr.aria-label]="getTitleName(element)">
      <td [attr.aria-label]="getTitleName(element)" class="table-body-font bold text-align-left"
        style="font-weight: 600">
        {{ getTitleName(element) }}
      </td>
      <td *ngIf="showDate" class="table-body-font text-align-right">
        {{ retrieveDate(element.DataIns) }}
      </td>

      <td *ngIf="postTypeData == 'com_etic'" class="table-body-font text-align-right">
        {{ element.DataIns | date : "dd/MM/yyyy" }}
      </td>

      <td *ngIf="postTypeData == 'prod_az'" class="table-body-font text-align-right">
        {{ element.DataIns | date : "dd/MM/yyyy" }}
      </td>

      <td class="table-body-font text-align-right" style="padding: 0">
        <button aria-label="download" class="btn download_button_attachment" (click)="download(element)">
          <div class="col-12 col-md-6 col-lg-4">
            <svg class="icon icon-sm icon-primary">
              <use xlink:href="../../../assets/sprite.svg#it-download"></use>
            </svg>
          </div>
        </button>
      </td>
    </tr>
  </tbody>
</table>