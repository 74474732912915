<app-banner-info [page]="'cam_pancreas'" [title]="'HEADER.PANCREAS'" [subtitle]="'PANCREAS.INFO_SUBTITLE'">
</app-banner-info>

<div class="bg-color" role="region" aria-label="tabella">
  <app-table-info [data]="campaniaPancreasList" [postTypeData]="'cam_pancreas'" (downloadHandler)="downloadFile($event)"
    [showDate]="false">
  </app-table-info>
  <p *ngIf="
      !(campaniaPancreasList && campaniaPancreasList.length > 0) &&
      loaderPage === false
    " style="text-align: center; margin-bottom: 2%">
    {{ "MESSAGE.NO_RESULTS" | translate }}
  </p>
</div>

<div class="footer-component" role="region" aria-label="footer">
  <p class="shared_paragraph_title">{{ "PANCREAS.INFO" | translate }}</p>
  <app-departments-detail-person [showNumber]="true" [data]="sharedData.CampaniaPancreasInfoCard">
  </app-departments-detail-person>
</div>