<div class="it-header-center-wrapper theme-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="it-header-center-content-wrapper">
          <div class="it-brand-wrapper">
            <div class="it-brand-text">
              <app-logo-container></app-logo-container>
            </div>
          </div>
          <div class="it-right-zone">
            <div
              role="region"
              aria-label="Seguici sui social"
              class="it-socials d-none d-md-flex"
            >
              <span>{{ "HEADER.FOLLOW_US" | translate }}</span>
              <ul>
                <li>
                  <a
                    href="{{ sharedData.facebook_Link }}"
                    aria-label="Facebook"
                    target="_blank"
                  >
                    <svg-icon-sprite
                      src="../../../assets/sprite.svg#it-facebook"
                    ></svg-icon-sprite>
                  </a>
                </li>
                <li>
                  <a
                    href="{{ sharedData.youtube_Link }}"
                    aria-label="Youtube"
                    target="_blank"
                  >
                    <svg-icon-sprite
                      src="../../../assets/sprite.svg#it-youtube"
                    ></svg-icon-sprite>
                  </a>
                </li>
                <li>
                  <a
                    href="{{ sharedData.twitter_Link }}"
                    aria-label="Twitter"
                    target="_blank"
                  >
                    <svg-icon-sprite
                      src="../../../assets/sprite.svg#it-twitter"
                    ></svg-icon-sprite>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="{{ sharedData.instagram_Link }}"
                    aria-label="Instagram"
                    target="_blank"
                  >
                    <svg-icon-sprite
                      src="../../../assets/sprite.svg#it-instagram"
                    ></svg-icon-sprite>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
