<div class="shared_detail_body">
  <div class="shared_index_column">
    <div class="sidebar-wrapper">
      <h3 class="no_toc">{{ "NOTICES.INDEX_PAGE" | translate }}</h3>
      <div class="sidebar-linklist-wrapper">
        <div class="link-list-wrapper">
          <ul class="link-list">
            <li>
              <a
                fragment="description"
                class="list-item large medium right-icon"
                (click)="navigateToSection('description')"
                ><span>{{ "NOTICES.DESCRIPTION" | translate }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="shared_information_column">
    <h4 id="description" class="paragraph-title">
      {{ "NOTICES.DESCRIPTION" | translate }}
    </h4>
    <div class="content" [innerHTML]="detailInformation.post_content"></div>
  </div>
</div>
