import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentsService } from 'src/app/api/services';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { departmentResponseType1 } from 'src/app/shared/messageType';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private departmentService: DepartmentsService
  ) {}

  // vars
  loaderPage = false;
  departmentsList: departmentResponseType1[] = [];

  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('DEPARTMENTS.DEPARTMENTS', ''),
  ];

  ngOnInit(): void {
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);

    this.departmentService.departmentSearch({ published: '1' }).subscribe(
      (res) => {
        this.departmentsList = res.rows;
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: {},
        });
      }
    );
  }
}
