import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/api/services/company.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';
import { IS_MOBILE } from 'src/app/shared/models/constants';

@Component({
  selector: 'app-company-read-more',
  templateUrl: './company-read-more.component.html',
  styleUrls: ['./company-read-more.component.scss'],
})
export class CompanyReadMoreComponent implements OnInit {
  mobile = IS_MOBILE;
  addID = 'A';
  IDStruttura: any;
  loaderPage: boolean = false;

  strutture: any;
  NomeStruttura: any;
  constructor(
    private route: ActivatedRoute,
    private cs: CompanyService,
    private loaderService: LoaderService
  ) {}
  searchExt() {}
  breadcrumbItems: BreadcrumbItem[];

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    // leggo l`id proveniente dalla rotta
    this.IDStruttura = this.route.snapshot.params.id;
    this.NomeStruttura = this.route.snapshot.params.title;
    this.cs
      .companyStructureSearch({
        ID_Direzione: this.IDStruttura,
        published: '1',
      })
      .subscribe((res) => {
        this.strutture = res.rows;
        this.breadcrumbItems = [
          new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
          new BreadcrumbItem('Azienda', '/azienda'),
          new BreadcrumbItem(this.NomeStruttura, ''),
        ];

        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      });

    // this.cs.structureMoreInfoSearch(this.IDStruttura);
  }
}
