import { Component, Input, OnInit } from '@angular/core';
import { TransparentAdministrationService } from 'src/app/api/services/transparent-administration.service';
import {
  transparentAdministrationDetailResponseType1,
  transparentAdministrationResponseType1,
} from 'src/app/shared/messageType';
import { DownloadService } from 'src/app/api/services/download.service';

import { Router } from '@angular/router';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { TranslateService } from '@ngx-translate/core';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-transparent-administration-detail-body',
  templateUrl: './transparent-administration-detail-body.component.html',
  styleUrls: [
    './transparent-administration-detail-body.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class TransparentAdministrationDetailBodyComponent implements OnInit {
  @Input() parentData: transparentAdministrationResponseType1;

  @Input() detailInformation: transparentAdministrationDetailResponseType1[] =
    [];
  accordions: boolean[];
  @Input()
  subDetailInformation: transparentAdministrationDetailResponseType1[] = [];
  isLoadingDocument: boolean[][] = [[], []];
  visitCounter: number;
  isContrilev: boolean = false;
  contrilev: transparentAdministrationDetailResponseType1[];
  storico: transparentAdministrationDetailResponseType1[];
  historyIsVisible: boolean = false;
  @Input() isRecursiveChild = false;
  constructor(
    private downloadService: DownloadService,
    private router: Router,
    public tas: TransparentAdministrationService,
    public translateService: TranslateService,
    public sharedData: SharedData
  ) {}

  ngOnDestroy() {}
  ngOnChanges() {}
  ngOnInit(): void {
    // se entra nella sezione Organismi Indipendenti di valutazione, nuclei di valutazione o altri organismi con funzioni analoghe deve prendere i primi due elementi

    if (this.parentData.CodicePadre == 'CONRILIEV') {
      let tempArray = [];
      this.isContrilev = true;
      this.contrilev = [];
      this.detailInformation.map((a, index) => {
        if (
          a.Nome ===
            "Documento dell'OIV di validazione della Relazione sulla Performance" ||
          a.Nome ===
            "Attestazione dell'OIV o di altra struttura analoga nell'assolvimento degli obblighi di pubblicazione"
        ) {
          this.contrilev.push(a);
        } else {
          tempArray.push(a);
        }
      });

      this.detailInformation = tempArray;
    }

    if (this.parentData.Nome == 'Personale') {
      this.getHistoryInformation();
    }

    // Gestione accordion
    //-------------------------------------
    // Nel caso entriamo in una sezione che ha solo un elemento
    //l'accordion deve essere aperto, altrimenti devono essere tutti chiusi
    if (this.detailInformation.length == 1) {
      this.accordions = new Array(this.detailInformation.length).fill(false);
      this.accordions[0] = true;
    } else {
      this.accordions = new Array(this.detailInformation.length).fill(false);
    }

    // * "Storico" disposto sempre in fondo.
    for (let index = 0; index < this.detailInformation.length; index++) {
      const info = this.detailInformation[index];
      if (
        info.Files &&
        info.Files.length > 0 &&
        info.Files[0].CodPadreSezione === 'strc'
      ) {
        this.detailInformation.push(this.detailInformation.splice(index, 1)[0]);
        //this.accordions[this.accordions.length - 1] = false; // ! Deprecated:  All default closed
      }
    }

    this.tas
      .visitcounter()
      .subscribe((res) => (this.visitCounter = res.rows[0].counter));

    for (let index = 0; index < this.detailInformation.length; index++) {
      const element = this.detailInformation[index];
      this.isLoadingDocument[index] = [];
      if (element.Files) {
        for (let j = 0; j < element.Files.length; j++) {
          this.isLoadingDocument[index][j] = false;
        }
      }
    }
  }

  public downloadFile(doc) {
    const postType = DocumentPostType.GENERICS;
    const postID = doc.ID_Generico;
    const parentCodeDoc = doc.CodicePadreDocumento;
    const url = parentCodeDoc
      ? this.downloadService.rootUrl +
        DownloadService.PostDownload1Path +
        postType +
        DownloadService.PostDownload2Path +
        parentCodeDoc +
        '/' +
        postID
      : this.downloadService.rootUrl +
        DownloadService.PostDownload1Path +
        postType +
        DownloadService.PostDownload2Path +
        postType +
        '/' +
        postID;
    this.downloadService.downloadBlob(
      doc.NomeDocumentoFile ? doc.NomeDocumentoFile : doc.Filename,
      url
    );
  }

  goToWhistleblowing() {
    this.router.navigate([
      'azienda/amministrazione-trasparente',
      'Whistleblowing',
    ]);
  }

  goToDetail(data) {
    if (data.SubCodPadre === 'wstl') {
      this.scrollToTop();
      this.goToWhistleblowing();
      return;
    }

    var parameters: any = {
      CodicePadre: '',
      NomeFile: data.NomeFile,
      published: '1',
      filter: 'Custom',
      order: 'ASC',
    };
    parameters.ID = data.ID;
    parameters.Nome = data.Nome;
    parameters.subdirectory = data.subdirectory;

    this.redirectTo2(
      [
        'azienda/amministrazione-trasparente',
        'amministrazione-trasparente-detail',
      ],
      parameters
    );
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  // navigazione verso le determine
  goToDetermine() {
    this.router.navigate(['azienda/amministrazione-trasparente', 'determine'], {
      queryParams: { years: true, type: 1 },
      skipLocationChange: true,
    });
  }
  goToDelibere() {
    this.router.navigate(['azienda/amministrazione-trasparente', 'delibere'], {
      queryParams: { years: true, type: 0 },
      skipLocationChange: true,
    });
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
  redirectTo2(uri, param) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(uri, { state: param }));
  }

  getHistoryInformation() {
    let requestBody = {
      CodicePadre: '',
      NomeFile: '/trasparenza/personale/strc',
      filter: 'Custom',
      order: 'ASC',
      published: '1',
    };
    this.tas
      .postTransparentAdministrationDetails(requestBody)
      .subscribe((res) => {
        this.storico = res.rows || [];
      });
  }

  showMandatoryInfo(code: string) {
    /* 
      Enti pubblici vigilati
      Società partecipate
      Pianificazione e governo del territorio
      Strutture sanitarie private accreditate
      Enti di diritto privati controllati 
    */
    if (
      code === 'ssa' ||
      code === 'Piaegdt' ||
      code === 'entipubvig' ||
      code === 'SocPart' ||
      code === 'Endpco' ||
      code === 'contimp' ||
      code === 'badg' ||
      code === 'assenza' ||
      code === 'contraint' ||
      code === 'bipia' ||
      code === 'nucver'
    ) {
      return true;
    }
    return false;
  }

  getMandatoryText(code: string) {
    switch (code) {
      case 'contimp':
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO_1'
        );
      case 'Piaegdt':
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO_2'
        );
      case 'badg':
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO_3'
        );
      case 'bipia':
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO_4'
        );
      case 'nucver':
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO_5'
        );
      // case 'wstl':
      //   return this.translateService.instant('WHISTLEBLOWING.TEXT');
      case 'assenza':
      case 'contraint':
        return this.translateService.instant('TRANSPARENT_ADMINSTRATION.TBD');
      default:
        return this.translateService.instant(
          'TRANSPARENT_ADMINSTRATION.MANDATORY_INFO'
        );
    }
  }

  showButtonAction(code: string) {
    if (code === 'bandiconcorsi' || code === 'badg') {
      return true;
    }
    return false;
  }
  showCells(code: string) {
    if (code === 'incnsg' || code === 'TICC') {
      return true;
    }
    return false;
  }

  redirectButtonAction(code: string) {
    if (code === 'badg') {
      this.router.navigate(['bandi-e-gare'], {
        skipLocationChange: true,
      });
    } else if (code === 'bandiconcorsi') {
      this.router.navigate(['concorsi-e-avvisi'], {
        skipLocationChange: true,
      });
    }
  }

  getRedirectButtonText(code: string) {
    if (code === 'bandiconcorsi') {
      return this.translateService.instant('BUTTON.GO_TO_TENDERS');
    } else if (code === 'badg') {
      return this.translateService.instant('BUTTON.GO_TO_NOTICES');
    } else if (code === 'TICC' || code == 'incnsg') {
      return this.translateService.instant('BUTTON.GO_TO_ASSIGNMENTS');
    }
  }
  // apre  i link in una nuova scheda
  goToLink(link: string) {
    window.open(link, '_blank');
  }

  // recupera i link dallo shared data
  getLinks(SubCodPadre: string) {
    if (SubCodPadre === 'incnsg') {
      return this.sharedData.incnsg_CellLinks;
    }
    if (this.parentData.SubCodPadre === 'TICC') {
      return this.sharedData.TICC_CellLinks;
    }
  }
}
