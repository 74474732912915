import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentsService } from 'src/app/api/services';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-departments-detail',
  templateUrl: './departments_detail.component.html',
  styleUrls: [
    './departments_detail.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsDetailComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private departmentService: DepartmentsService
  ) { }

  loaderPage = false;
  detailInformation: any;

  ngOnInit(): void {
    this.breadcrumbItems = [
      new BreadcrumbItem('DEPARTMENTS_DETAIL.HOME', '/home'),
      new BreadcrumbItem('DEPARTMENTS_DETAIL.DEPARTMENTS', '/dipartimenti'),
    ];

    // for hide the id in the url
    this.location.replaceState('dipartimenti/dettaglio');
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);

    this.departmentService
      .departmentSearch(this.route.snapshot.params.id)
      .subscribe(
        (res) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.detailInformation = res.rows.filter(
            (el) => el.ID == this.route.snapshot.params.id
          );

          this.breadcrumbItems.push(
            new BreadcrumbItem(
              this.detailInformation[0] ? this.detailInformation[0].Titolo : '',
              ''
            )
          );
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => { },
          });
        }
      );
  }
}
