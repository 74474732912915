import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploaderService } from './uploader.service';

@Component({
  selector: 'file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() buttonText: string;
  @Input() singleImageUpload: boolean;
  @Input() singleFileUpload: boolean;
  @Input() requiredFileType;
  @Output() isUploaded = new EventEmitter();
  @Output() imgUploaded = new EventEmitter();

  progress: number;
  infoMessage: any;
  isUploading = false;
  file: File;
  type: string;

  n_uploaded_files = 0;
  imageUrl: string[] | ArrayBuffer[] = [];
  imgData: string[] | ArrayBuffer[] = [];
  fileName: string[] = [];
  typeError = false;

  constructor(private uploader: UploaderService) {}

  ngOnInit() {
    this.isUploaded.emit(false);
  }

  removeImage(element) {
    const index = this.imageUrl.indexOf(element);
    if (index > -1) {
      this.imageUrl.splice(index, 1);
      this.imgData.splice(index, 1);
      this.fileName.splice(index, 1);
      this.n_uploaded_files--;
      this.imgUploaded.emit(this.imgData);
      if (this.n_uploaded_files <= 0) {
        this.isUploaded.emit(false);
      }
    }
  }

  getExtension(filename) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  onChange(file: File, input) {
    if (
      !(
        this.getExtension(file.name).toLowerCase() === 'jpeg' ||
        this.getExtension(file.name).toLowerCase() === 'jpg' ||
        this.getExtension(file.name).toLowerCase() === 'png' ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'pdf') ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'csv') ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'xlsx') ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'xls') ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'xlsb') ||
        (!this.singleImageUpload &&
          this.getExtension(file.name).toLowerCase() === 'xlsm')
      )
    ) {
      this.typeError = true;
      setTimeout(() => {
        this.typeError = false;
      }, 1000);
      input.value = '';
      return;
    }

    if (file) {
      if (this.singleImageUpload) {
        this.fileName = [];
        this.fileName[0] = file.name;
        this.imageUrl = [];
        this.n_uploaded_files = 0;
      } else {
        this.fileName[this.n_uploaded_files] = file.name;
      }
      this.file = file;
      this.type = file.type;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        if (this.type === 'application/pdf') {
          this.imageUrl
            ? (this.imageUrl[this.n_uploaded_files] = '/assets/PDF_icon.svg')
            : null;
        } else if (
          this.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          this.type === 'text/csv'
        ) {
          this.imageUrl
            ? (this.imageUrl[this.n_uploaded_files] = '/assets/ms-excel.svg')
            : null;
        } else {
          this.imageUrl
            ? (this.imageUrl[this.n_uploaded_files] =
                reader.result || '/assets/file.svg')
            : null;
        }
        this.imgData[this.n_uploaded_files] = event.target.result;
        if (!this.singleImageUpload) {
          this.n_uploaded_files++;
        }
        this.isUploaded.emit(true);
        this.imgUploaded.emit(
          this.singleImageUpload || this.singleFileUpload
            ? { base64String: this.imgData[0], fileName: file.name }
            : this.imgData
        );

        input.value = '';
      };
    }
  }

  // tslint:disable-next-line: typedef
  getAcceptedFile() {
    if (this.requiredFileType) {
      return this.requiredFileType;
    } else if (this.singleImageUpload) {
      return '.csv, application/pdf, text/csv, .jpg, .png, .jpeg, image /*;capture=camera';
    } else {
      return 'application/pdf,	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, .csv .jpg, .png, .jpeg, image /*;capture=camera';
    }
  }
}
