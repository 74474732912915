import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input() data;
  @Input() title;
  @Input() subtitle;
  @Input() postTypeData;
  @Input() showSubText = false;
  @Input() showDetail = false;
  @Input() minHeight = '120px';
  @Output() actionFunction = new EventEmitter<any>();
  @Input() detailText = this.translate.instant('BUTTON.DOWNLOAD_DOC');
  constructor(
    private translate: TranslateService
  ) { }
  isLoadingDocument = false;

  ngOnInit(): void {
  }

  callAction() {
    this.actionFunction.emit(this.data);
  }
}
