import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../api/services/login.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.deviceService.isDesktop()) {
      this.router.navigateByUrl('/home');
    }
    if (!this.loginService.logged) {
      this.router.navigateByUrl('/management/login');
    }
    return this.loginService.logged;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (route.url.toString().includes('risk-management')) {
      this.router.navigateByUrl('/wip');
    } else {
      if (!this.deviceService.isDesktop()) {
        this.router.navigateByUrl('/home');
      }
      if (!this.loginService.logged) {
        this.router.navigateByUrl('/management/login');
      }
      return this.loginService.logged;
    }
  }
}
