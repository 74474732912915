<div class="shared_detail_body">
  <div class="shared_index_column">
    <nav class="">
      <div>
        <div class="menu-wrapper">
          <div class="link-list-wrapper">
            <p class="shared_index_column_title">
              {{ "DEPARTMENTS_DETAIL.PAGE_INDEX" | translate }}
            </p>
            <ul class="link-list">
              <li class="nav-item">
                <div *ngIf="departments_Afferent_persons">
                  <a class="nav-link active" (click)="redirectTo('name')">
                    <p class="shared_index_column_element">
                      {{ this.departments_Afferent_persons.TPN_DES }} -
                      {{ this.departments_Afferent_persons.PER_SIGTIT }}
                      {{ this.departments_Afferent_persons.PER_NOM }}
                      {{ this.departments_Afferent_persons.PER_COG }}
                    </p>
                  </a>
                </div>
                <ul class="link-list">
                  <li class="nav-link">
                    <a class="nav-link">
                      <p class="shared_index_column_element"></p>
                    </a>

                    <ul class="tertiary link-list">
                      <li class="nav-link">
                        <a
                          *ngIf="departments_Afferent_persons"
                          class="nav-link"
                          (click)="redirectTo('contatti')"
                        >
                          <p class="shared_index_column_element">
                            {{ "DEPARTMENTS_DETAIL.CONTACTS" | translate }}
                          </p>
                        </a>
                      </li>
                      <li class="nav-link">
                        <a
                          class="nav-link"
                          *ngIf="departmentsUoc && departmentsUoc.length > 0"
                        >
                          <p
                            (click)="redirectTo('all-uoc')"
                            class="shared_index_column_element"
                          >
                            {{ "DEPARTMENTS_DETAIL.UOC" | translate }}
                          </p>
                        </a>
                      </li>
                      <li
                        class="nav-link"
                        *ngIf="departmentsUosd && departmentsUosd.length > 0"
                      >
                        <a class="nav-link">
                          <p
                            (click)="redirectTo('all-uoc')"
                            class="shared_index_column_element"
                          >
                            {{ "DEPARTMENTS_DETAIL.UOSD" | translate }}
                          </p>
                        </a>
                      </li>

                      <li
                        class="nav-link"
                        *ngIf="
                          programsDepartments && programsDepartments.length > 0
                        "
                      >
                        <a class="nav-link">
                          <p
                            (click)="redirectTo('all-programs-departments')"
                            class="shared_index_column_element"
                          >
                            {{ "DEPARTMENTS_DETAIL.PROGRAMS" | translate }}
                          </p>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div class="shared_information_column">
    <div role="region" aria-label="NomeDirettore">
      <p
        *ngIf="departments_detail.Nome_Direttore != ''"
        class="shared_paragraph_title"
        id="name"
      >
        {{ departments_detail.titolo }} Direttore
        {{ departments_detail.Nome_Direttore }}
      </p>
      <p
        *ngIf="
          departments_detail.CV_Direttore != 'NULL' &&
          departments_detail.CV_Direttore != 'null'
        "
        class="shared_text"
      >
        {{ departments_detail.CV_Direttore }}
      </p>

      <div
        *ngIf="departments_detail.Nome_Direttore != ''"
        id="contatti"
        class="contact_Section"
      >
        <p class="shared_paragraph_title">
          {{ "DEPARTMENTS_DETAIL.CONTACTS" | translate }}
        </p>

        <div
          *ngIf="departments_detail.Nome_Direttore != ''"
          class="shared_information_cards_container"
        >
          <app-departments-detail-person
            [data]="departments_detail"
            [email]="departments_detail.Email_Direttore"
          >
          </app-departments-detail-person>
          <app-departments-detail-location
            *ngIf="departments_detail"
            [details]="departments_detail"
          >
          </app-departments-detail-location>
        </div>
      </div>
    </div>

    <!-- ALL -->
    <!-- questo contiene le unita operative complesse -->
    <app-main-all-body
      *ngIf="filterRes('U.O.C.')"
      id="all-uoc"
      [typology]="'departments_uoc'"
      [departments_detail]="departments_detail"
      [mainTitle]="'DEPARTMENTS_DETAIL.UOC'"
      [bodyList]="filterRes('U.O.C.').Nome_Struttura"
    ></app-main-all-body>
    <!-- END ALL -->
    <!-- ALL -->
    <!-- questo contiene le unita operative semplici dipartimentali -->
    <app-main-all-body
      *ngIf="filterRes('U.O.S.D.')"
      id="all-uosd"
      [departments_detail]="departments_detail"
      [typology]="'departments_uosd'"
      [mainTitle]="'DEPARTMENTS_DETAIL.UOSD'"
      [bodyList]="filterRes('U.O.S.D.')?.Nome_Struttura"
    >
    </app-main-all-body>
    <!-- questo contiene le unitá operative semplici -->
    <app-main-all-body
      *ngIf="filterRes('U.O.S.')"
      id="all-uosd"
      [departments_detail]="departments_detail"
      [typology]="'departments_uosd'"
      [mainTitle]="'DEPARTMENTS_DETAIL.UOS'"
      [bodyList]="filterRes('U.O.C.')?.Nome_Struttura"
    >
    </app-main-all-body>
    <!-- questo contiene i programmi -->
    <app-main-all-body
      *ngIf="filterRes('Programmi')"
      id="all-uosd"
      [departments_detail]="departments_detail"
      [typology]="'departments_uosd'"
      [mainTitle]="'DEPARTMENTS_DETAIL.PROGRAMS'"
      [bodyList]="filterRes('Programmi')?.Nome_Struttura"
    >
    </app-main-all-body>
  </div>
</div>
