import { Component, Input, OnInit } from '@angular/core';
import { departmentResponseType1 } from 'src/app/shared/messageType';

@Component({
  selector: 'app-departments-card',
  templateUrl: './departments_card.component.html',
  styleUrls: ['./departments_card.component.scss'],
})
export class DepartmentsCardComponent implements OnInit {
  @Input() data: departmentResponseType1;

  constructor() {}

  ngOnInit(): void {}
}
