<div class="departments">
  <p
    role="region"
    aria-label="HOME_SUBVIEWS.REPARTS_AND_PRESTATION"
    class="text1 mt-4"
  >
    {{ "HOME_SUBVIEWS.REPARTS_AND_PRESTATION" | translate }}
  </p>
  <p role="region" aria-label="HOME_SUBVIEWS.DEPARTMENTS" class="text2 mb-0">
    {{ "HOME_SUBVIEWS.DEPARTMENTS" | translate }}
  </p>
  <div class="department_Cards">
    <app-departments-card
      *ngFor="let element of departmentsList"
      [data]="element"
    ></app-departments-card>
  </div>
  <div class="see-all-btn-container" tabindex="0">
    <a
      role="navigation"
      aria-label="HOME_SUBVIEWS.SHOW_ALL"
      style="background: white"
      [routerLink]="'/dipartimenti'"
      class="btn btn-outline-primary"
      >{{ "HOME_SUBVIEWS.SHOW_ALL" | translate }}</a
    >
  </div>
</div>
