import { Component, Input, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from '../models/breadcrumbItem.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, DoCheck {
  @Input() breadcrumbList: [];
  @Input() activeMoreText: '';
  lastItem: BreadcrumbItem;
  constructor(private router: Router) {}

  ngOnInit(): void {}
  ngDoCheck(): void {
    this.lastItem = this.breadcrumbList.slice(-1)[0];
  }

  itemDidPress(elem) {
    if (elem && elem.link !== '') {
      this.router.navigate([elem.link]);
    }
    return;
  }
}
