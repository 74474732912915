<div class="main_head">
  <div class="sub_main_head left">
    <app-breadcrumb [breadcrumbList]="breadcrumbItems"></app-breadcrumb>
    <p role="region" aria-label="titolo" class="title_main_head" tabindex="0">
      {{ mainTitle | translate }}
    </p>
    <search
      *ngIf="isSearchRequired"
      [textPlaceholder]="textPlaceholderSearch"
      (searchEvent)="search($event)"
    >
    </search>

    <app-search-filter
      id="search-filter"
      [textPlaceholder]="textPlaceholderSearch"
      *ngIf="isSearchFilterRequired"
      style="width: 150%"
      [visible]="isSearchFilterRequired"
      [(selectedTempYear)]="selectedTempYear"
      [showNumber]="showNumber"
      [(tempSearchText)]="tempSearchText"
      [(tempSearchNumber)]="tempSearchNumber"
      [years]="years"
      [showYear]="false"
      (resetFilter)="resetFilter()"
      (doSearch)="doSearch()"
    >
    </app-search-filter>
  </div>

  <div
    class="sub_main_head right"
    *ngIf="isRightPanelVisible"
    role="region"
    aria-label="(rightListPanelText | translate)"
  >
    <p class="title_right_tabel main_list">
      {{ rightListPanelText | translate }}
    </p>

    <!-- CONTESTS -->
    <div
      *ngIf="(typology === 'tenders' || typology === 'notices') && !isPermalink"
    >
      <a
        class="btn main_button main_list"
        *ngFor="let element of bodyList"
        (click)="navigateTo([routerLinkText, element.ID])"
        >{{ element.titolo }}</a
      >
    </div>

    <!-- NEWS -->
    <div *ngIf="typology === 'news' && isPermalink">
      <a
        *ngFor="let element of bodyList"
        (click)="navigateTo([element.post_permalink])"
        role="navigation"
        class="btn main_button main_list"
        innerHTML="{{ element.post_title }}"
      ></a>
    </div>

    <!-- DEPARTMENTS -->
    <div *ngIf="typology === 'departments'">
      <a
        class="btn main_button main_list"
        *ngFor="let element of bodyList"
        (click)="navigateTo(['/departments/dettaglio', element.ORG_COD])"
        >{{ element.ORG_DES }}</a
      >
    </div>

    <!-- TRANSPARENT ADMINISTRATION -->
    <div *ngIf="typology === 'transparentAdministration'">
      <a
        class="btn main_button main_list"
        *ngFor="let element of bodyList"
        (click)="goToDetail(element)"
        >{{ element.Nome }}</a
      >
    </div>

    <a class="btn all_main_button" (click)="redirectTo(redirectText)"
      >{{ rightAllPanelText | translate }}
    </a>
  </div>
</div>
