<nav *ngIf="!loader && list && list.length > 0 && isIntoPaginationLimit" class="pagination-wrapper"
    aria-label="paginator">
    <ul class="pagination justify-content-center">
        <li class="page-item" [disabled]="isZero()">
            <a class="page-link">
                <span (click)="onClickPrevious()" [ngClass]="isZero() ? 'disable_view': 'view' ">{{"PAGINATOR.PREVIOUS"
                    | translate}}</span>
            </a>
        </li>

        <li class="page-item d-none d-sm-flex" *ngIf="!(currentPage - 2 <= 0)">
            <a class="page-link" (click)="onClickNumber(currentPage - 2)">
                <span ngClass="d-inline-block d-sm-none">Pagina </span>{{currentPage - 2 }} </a>
        </li>
        <li class="page-item d-none d-sm-flex" *ngIf="!(currentPage - 1 <= 0)">
            <a class="page-link" (click)="onClickNumber(currentPage - 1)">
                <span ngClass="d-inline-block d-sm-none">Pagina </span>{{ currentPage - 1}} </a>
        </li>
        <li class="page-item active">
            <a class="page-link selected" (click)="onClickNumber(currentPage)">
                <span ngClass="d-inline-block d-sm-none">Pagina </span>{{currentPage}} </a>
        </li>
        <li class="page-item d-none d-sm-flex" *ngIf="!(currentPage + 1 > totalNumberOfPages)">
            <a class="page-link" (click)="onClickNumber(currentPage +1)">
                <span ngClass="d-inline-block d-sm-none">Pagina </span>{{currentPage +1}} </a>
        </li>
        <li class="page-item d-none d-sm-flex" *ngIf="!(currentPage + 2 > totalNumberOfPages)">
            <a class="page-link" (click)="onClickNumber(currentPage + 2)">
                <span ngClass="d-inline-block d-sm-none">Pagina </span>{{currentPage +2}} </a>
        </li>


        <li class="page-item" [disabled]="isMax()">
            <a class="page-link">

                <span (click)="onClickNext()" [ngClass]="isMax() ? 'disable_view': 'view' ">{{"PAGINATOR.NEXT" |
                    translate}}</span>
            </a>
        </li>
    </ul>
    <div>
        <p *ngIf="list && list.length > 0"><span class="sr-only">{{"PAGINATOR.PAGE" |
                translate}}</span>
            {{currentPage }} di {{totalNumberOfPages}}</p>
    </div>
</nav>