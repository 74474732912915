import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { EthicsCommitteeType } from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root',
})
export class EthicsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation PostEthicsCommiteePath
   */
  static readonly PostEthicsCommiteePath = '/api/v1/com_etic';
  static readonly PostEthicsCommiteePathSearch =
    EthicsService.PostEthicsCommiteePath + '/_search';
  static readonly PostEthicsCommiteePathInsertPath =
    EthicsService.PostEthicsCommiteePath + '/_insert';
  static readonly PostEthicsCommiteePathDeletePath =
    EthicsService.PostEthicsCommiteePath + '/_delete';
  static readonly PutPublishEthicsCommiteeStatusPath =
    EthicsService.PostEthicsCommiteePath + '/_update_publish';
  static readonly PostEthicsCommiteeSearchFoldersPath =
    EthicsService.PostEthicsCommiteePath + '/_search_folders';
  static readonly PostEthicsCommiteePathUpdatePath =
    EthicsService.PostEthicsCommiteePath + '/_update';
  static readonly PostEthicsCommiteeSearchSubFoldersPath =
    EthicsService.PostEthicsCommiteePath + '/_search_sub_folders';
  /**
   * Comitato Etico:  Invocato il servizio restituisce le informazioni riguardante il comitato etico
   */
  postEthicsCommiteeSearch$Response(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<EthicsCommitteeType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathSearch,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>;
        })
      );
  }

  postEthicsCommiteeSearch(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EthicsCommitteeType>;
  }> {
    return this.postEthicsCommiteeSearch$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }
      )
    );
  }

  /*
   * INSERT
   */
  postInsertEthicsCommitee$Response(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<EthicsCommitteeType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathInsertPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>;
        })
      );
  }
  postInsertEthicsCommitee(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EthicsCommitteeType>;
  }> {
    return this.postInsertEthicsCommitee$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }
      )
    );
  }

  /*
   * UPDATE
   */
  postUpdateEthicsCommitee$Response(
    params?
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<EthicsCommitteeType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathUpdatePath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>;
        })
      );
  }
  postUpdateEthicsCommitee(
    params?
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EthicsCommitteeType>;
  }> {
    return this.postUpdateEthicsCommitee$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<EthicsCommitteeType>;
          }
      )
    );
  }

  /*
   * DELETE
   */
  DeleteEthicsCommitee$Response(params?: {
    etic_id: number;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathDeletePath,
      'delete'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  DeleteEthicsCommitee(params?: {
    etic_id: number;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.DeleteEthicsCommitee$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /*
   * UPDATE PUBLISH
   */
  UpdatePublishEthicsCommitee$Response(params?: {
    etic_id: string;
    published: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PutPublishEthicsCommiteeStatusPath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  UpdatePublishEthicsCommitee(params?: {
    etic_id: string;
    published: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.UpdatePublishEthicsCommitee$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH FOLDERS
   */
  postSearchFoldersEthicsCommitee$Response(): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<EthicsCommitteeType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteeSearchFoldersPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }
  postSearchFoldersEthicsCommitee(): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postSearchFoldersEthicsCommitee$Response().pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH SPECIFIC FOLDERS
   */
  postSearchSpecificFoldersEthicsCommitee$Response(params?: {
    folder_id?: string;
    Sottotipologia?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathInsertPath,
      'post'
    );
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }
  postSearchSpecificFoldersEthicsCommitee(params?: {
    folder_id?: string;
    Sottotipologia?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postSearchSpecificFoldersEthicsCommitee$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  /**
   * SEARCH FROM SPECIFIC FOLDER
   */
  postEthicsCommiteeSpecificFolderSearch$Response(params?: {
    folder_id?: string;
    Sottotipologia?: string;
    published?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteePathSearch,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  postEthicsCommiteeSpecificFolderSearch(params?: {
    folder_id?: string;
    Sottotipologia?: string;
    published?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postEthicsCommiteeSpecificFolderSearch$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }

  //postEthicsCommiteeSubFolderSearch

  postEthicsCommiteeSubFolderSearch$Response(params?: {
    sub_id?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      EthicsService.PostEthicsCommiteeSearchSubFoldersPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  postEthicsCommiteeSubFolderSearch(params?: {
    sub_id?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postEthicsCommiteeSubFolderSearch$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }
}
