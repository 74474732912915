import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { transparentAdministrationResponseType1 } from 'src/app/shared/messageType';

@Component({
  selector: 'app-transparent-administration-evidence-card',
  templateUrl: './transparent-administration-evidence-card.component.html',
  styleUrls: ['./transparent-administration-evidence-card.component.scss']
})
export class TransparentAdministrationEvidenceCardComponent implements OnInit {

  @Input() data: transparentAdministrationResponseType1;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  goToDetail() { 
    
    if (this.data.ID !== 1 && this.data.ID !== 158) {
      this.router.navigateByUrl('/company/transparent-administration', {
        state: this.data
      });
    } else if (this.data.ID === 1) {
      
      this.router.navigate(['/resolutions/delibere'], { queryParams: { years: true, type: 0 }, skipLocationChange: true });
    } else {
      
      this.router.navigate(['/resolutions/determine'], { queryParams: { years: true, type: 1 }, skipLocationChange: true });
    }
  }

}