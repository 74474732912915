import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of, forkJoin, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DepartmentsService, NoticesService } from 'src/app/api/services';
import { HomeService } from 'src/app/api/services/home.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PermalinksService } from 'src/app/api/services/permalinks.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public sharedData: SharedData,
    public homeServive: HomeService,
    private loaderService: LoaderService,
    private permalinkService: PermalinksService,
    private departmentService: DepartmentsService,
    private noticesService: NoticesService,
    private infoModal: GenericInfoModalService
  ) {}

  carouselList = [];
  newsList = [];
  departmentsList = [];
  noticesList = [];
  tendersList = [];

  currentPage = 1;
  loaderPage = false;
  limitPagination = true;
  totalNumberOfPages: number;
  numberOfResults = 0;

  ngOnInit(): void {
    this.homeServive.homeCompletedSubject.next(false);
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);

    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';

    const bannerHomeData = this.homeServive
      .postSearchHome(body)
      .pipe(catchError((error) => of(error)));
    const newsHomeData = this.permalinkService
      .postPermanentLinksSearch$Response(
        'posts',
        { page: 1, limit: 3 },
        { only_preferred: true, post_status: 'publish' }
      )
      .pipe(catchError((error) => of(error)));
    const departmentsHomeData = this.departmentService
      .departmentSearch({ published: '1' })
      .pipe(catchError((error) => of(error)));

    const noticesHomeData = this.noticesService
      .postSearchNoticesDetails(
        {
          published: '1',
          filter: 'entity.iniziovalidita',
          order: 'DESC',
          tipobando: '0',
        },
        { page: 1, limit: 10 }
      )
      .pipe(catchError((error) => of(error)));

    const tendersHomeData = this.noticesService
      .postSearchNoticesDetails(
        {
          published: '1',
          filter: 'entity.iniziovalidita',
          order: 'DESC',
          tipobando: '1',
        },
        { page: 1, limit: 10 }
      )
      .pipe(catchError((error) => of(error)));

    forkJoin([
      bannerHomeData,
      newsHomeData,
      departmentsHomeData,
      noticesHomeData,
      tendersHomeData,
    ]).subscribe((results) => {
      this.carouselList = results[0].rowCount > 0 ? results[0].rows : [];
      this.newsList = results[1].body.rows;
      this.newsList.forEach((resource) => {
        if (resource.post_featured_media) {
          resource.post_image_url =
          PermalinksService.attachmentPermalinkPath +
          'attachments/' +
          resource.post_featured_media;
        }
      });
      this.departmentsList =
        results[2].rowCount > 0 ? results[2].rows.slice(0, 3) : [];
      this.noticesList =
        results[3].rowCount > 0 ? results[3].rows.slice(0, 3) : [];

      this.tendersList =
        results[4].rowCount > 0 ? results[4].rows.slice(0, 3) : [];

      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
      this.homeServive.homeCompletedSubject.next(true);
    });
  }
}
