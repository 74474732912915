<div class="col">
  <div class="element_Spacer">
    <p role="region" aria-label="Novitá" class="news mt-4">
      {{ "HOME_SUBVIEWS.NEWS" | translate }}
    </p>
    <p role="region" aria-label="In primo piano" class="in-primo-piano mb-0">
      {{ "HOME_SUBVIEWS.FEATURED" | translate }}
    </p>
  </div>
  <div class="row_News">
    <app-news-card
      *ngFor="let element of newsList"
      [data]="element"
    ></app-news-card>
  </div>
  <div class="element_Spacer">
    <a
      role="navigation"
      aria-label="HOME_SUBVIEWS.SHOW_ALL"
      [routerLink]="'/news'"
      style="margin-top: 10%"
      class="btn btn-primary"
      >{{ "HOME_SUBVIEWS.SHOW_ALL" | translate }}</a
    >
  </div>
</div>
