import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GenericDualChoiceModalService } from './generic-dual-choice-modal.service';
declare var $: any;
@Component({
  selector: 'app-generic-dual-choice-modal',
  templateUrl: './generic-dual-choice-modal.component.html',
  styleUrls: ['./generic-dual-choice-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericDualChoiceModalComponent implements OnInit, OnDestroy {
  show: boolean = false;
  titleHeader: string;
  bodyText: string;
  action1Title: string;
  action2Title: string;
  callback1Function: Function;
  callback2Function: Function;
  modalSubscription: Subscription;

  constructor(private modalService: GenericDualChoiceModalService) {}

  ngOnInit() {
    this.modalSubscription = this.modalService.modalStatus
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.show = value.show;
        this.titleHeader = value.title;
        this.action1Title = value.action1Title;
        this.action2Title = value.action2Title;
        this.bodyText = value.bodyText;
        this.callback1Function = value.callback1Function;
        this.callback2Function = value.callback2Function;
        if (this.show) {
          $('#genericDualChoiceModal').modal('show').scrollTop(0);
        }
      });
  }

  doAction1() {
    $('#genericDualChoiceModal').modal('toggle');
    this.callback1Function ? this.callback1Function() : undefined;
  }

  doAction2() {
    this.callback2Function ? this.callback2Function() : undefined;
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }
}
