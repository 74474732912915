/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AmbulatoriesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }
  // PATHS ---------------------------------------------------
  static readonly PostSearchAmbulatoriesDetailsPath =
    '/api/v1/ambulatories/_search';

  static readonly PostSearchAmbulatoriesEmailsPath =
    '/api/v1/ambulatories/{amb_id}/emails/_search';

  static readonly PostSearchAmbulatoriesAfferentPersonsPath =
    '/api/v1/ambulatories/{amb_id}/afferent-persons/_search';

  static readonly PostSearchAmbulatoriesAppointPersonsPath =
    '/api/v1/ambulatories/{amb_id}/appoint-persons/_search';

  static readonly GetAmbulatoriesDeliveriesByIdPath =
    '/api/v1/ambulatories/{amb_id}/deliveries/{id}';

  static readonly GetAmbulatoriesAppointPersonsByIdPath =
    '/api/v1/ambulatories/{amb_id}/appoint-persons/{id}';

  static readonly PostSearchAmbulatoriesDeliveriesPath =
    '/api/v1/ambulatories/{amb_id}/deliveries/_search';

  static readonly GetAmbulatoriesAfferentPersonsByIdPath =
    '/api/v1/ambulatories/{amb_id}/afferent-persons/{id}';

  static readonly GetAmbulatoriesDetailsByIdPath =
    '/api/v1/ambulatories/{amb_id}';
  static readonly GetAmbulatoriesEmailsByIdPath =
    '/api/v1/ambulatories/{amb_id}/emails/{id}';
  // PATHS ---------------------------------------------------

  postSearchAmbulatoriesDetails$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        ID_LOC?: string;
        LOC_SED?: string;
        LOC_LOC?: string;
        LOC_IND?: string;
        STR_AFFCOD?: string;
        STR_AFFDES?: string;
        STR_MPV?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.PostSearchAmbulatoriesDetailsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }>;
        })
      );
  }

  postSearchAmbulatoriesDetails(params?: {
    page?: number;
    limit?: number;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      ID_LOC?: string;
      LOC_SED?: string;
      LOC_LOC?: string;
      LOC_IND?: string;
      STR_AFFCOD?: string;
      STR_AFFDES?: string;
      STR_MPV?: string;
    }>;
  }> {
    return this.postSearchAmbulatoriesDetails$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }
      )
    );
  }

  postSearchAmbulatoriesEmails$Response(params: {
    page?: number;
    limit?: number;

    amb_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        STR_TIP?: string;
        EMA_DES?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.PostSearchAmbulatoriesEmailsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('amb_id', params.amb_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }>;
        })
      );
  }

  postSearchAmbulatoriesEmails(params: {
    page?: number;
    limit?: number;

    amb_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      STR_TIP?: string;
      EMA_DES?: string;
    }>;
  }> {
    return this.postSearchAmbulatoriesEmails$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }
      )
    );
  }

  postSearchAmbulatoriesAfferentPersons$Response(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        STR_TIP?: string;
        TPN_DES?: string;
        PER_COG?: string;
        PER_NOM?: string;
        TPN_COD?: string;
        PER_FOTO?: string;
        PER_CUR?: string;
        PER_SIGTIT?: string;
        PER_EMA?: string;
        PER_TEL?: string;
        ID_PER?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.PostSearchAmbulatoriesAfferentPersonsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('amb_id', params.amb_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }>;
        })
      );
  }

  postSearchAmbulatoriesAfferentPersons(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      STR_TIP?: string;
      TPN_DES?: string;
      PER_COG?: string;
      PER_NOM?: string;
      TPN_COD?: string;
      PER_FOTO?: string;
      PER_CUR?: string;
      PER_SIGTIT?: string;
      PER_EMA?: string;
      PER_TEL?: string;
      ID_PER?: string;
    }>;
  }> {
    return this.postSearchAmbulatoriesAfferentPersons$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }
      )
    );
  }

  postSearchAmbulatoriesAppointPersons$Response(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        TPN_DES?: string;
        PER_COG?: string;
        PER_NOM?: string;
        TPN_COD?: string;
        PER_FOTO?: string;
        PER_CUR?: string;
        PER_SIGTIT?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.PostSearchAmbulatoriesAppointPersonsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('amb_id', params.amb_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }>;
        })
      );
  }

  postSearchAmbulatoriesAppointPersons(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      TPN_DES?: string;
      PER_COG?: string;
      PER_NOM?: string;
      TPN_COD?: string;
      PER_FOTO?: string;
      PER_CUR?: string;
      PER_SIGTIT?: string;
    }>;
  }> {
    return this.postSearchAmbulatoriesAppointPersons$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }
      )
    );
  }

  postSearchAmbulatoriesDeliveries$Response(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        ID_TEL?: string;
        TEL_CLI?: string;
        TEL_COD?: string;
        TEL_FLG?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.PostSearchAmbulatoriesDeliveriesPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('amb_id', params.amb_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }>;
        })
      );
  }

  postSearchAmbulatoriesDeliveries(params: {
    page?: number;
    limit?: number;
    amb_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      ID_TEL?: string;
      TEL_CLI?: string;
      TEL_COD?: string;
      TEL_FLG?: string;
    }>;
  }> {
    return this.postSearchAmbulatoriesDeliveries$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }
      )
    );
  }

  getAmbulatoriesDetailsById$Response(params: { amb_id: string }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        ID_LOC?: string;
        LOC_SED?: string;
        LOC_LOC?: string;
        LOC_IND?: string;
        STR_AFFCOD?: string;
        STR_AFFDES?: string;
        STR_MPV?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.GetAmbulatoriesDetailsByIdPath,
      'get'
    );
    if (params) {
      rb.path('amb_id', params.amb_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }>;
        })
      );
  }

  getAmbulatoriesDetailsById(params: { amb_id: string }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      ID_LOC?: string;
      LOC_SED?: string;
      LOC_LOC?: string;
      LOC_IND?: string;
      STR_AFFCOD?: string;
      STR_AFFDES?: string;
      STR_MPV?: string;
    }>;
  }> {
    return this.getAmbulatoriesDetailsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_LOC?: string;
              LOC_SED?: string;
              LOC_LOC?: string;
              LOC_IND?: string;
              STR_AFFCOD?: string;
              STR_AFFDES?: string;
              STR_MPV?: string;
            }>;
          }
      )
    );
  }

  getAmbulatoriesEmailsById$Response(params: {
    amb_id: string;
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        STR_TIP?: string;
        EMA_DES?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.GetAmbulatoriesEmailsByIdPath,
      'get'
    );
    if (params) {
      rb.path('amb_id', params.amb_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }>;
        })
      );
  }

  getAmbulatoriesEmailsById(params: {
    amb_id: string;
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      STR_TIP?: string;
      EMA_DES?: string;
    }>;
  }> {
    return this.getAmbulatoriesEmailsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              EMA_DES?: string;
            }>;
          }
      )
    );
  }

  getAmbulatoriesAfferentPersonsById$Response(params: {
    amb_id: string;
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        STR_TIP?: string;
        TPN_DES?: string;
        PER_COG?: string;
        PER_NOM?: string;
        TPN_COD?: string;
        PER_FOTO?: string;
        PER_CUR?: string;
        PER_SIGTIT?: string;
        PER_EMA?: string;
        PER_TEL?: string;
        ID_PER?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.GetAmbulatoriesAfferentPersonsByIdPath,
      'get'
    );
    if (params) {
      rb.path('amb_id', params.amb_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }>;
        })
      );
  }

  getAmbulatoriesAfferentPersonsById(params: {
    amb_id: string;
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      STR_TIP?: string;
      TPN_DES?: string;
      PER_COG?: string;
      PER_NOM?: string;
      TPN_COD?: string;
      PER_FOTO?: string;
      PER_CUR?: string;
      PER_SIGTIT?: string;
      PER_EMA?: string;
      PER_TEL?: string;
      ID_PER?: string;
    }>;
  }> {
    return this.getAmbulatoriesAfferentPersonsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              STR_TIP?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
              PER_EMA?: string;
              PER_TEL?: string;
              ID_PER?: string;
            }>;
          }
      )
    );
  }

  getAmbulatoriesAppointPersonsById$Response(params: {
    amb_id: string;

    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        TPN_DES?: string;
        PER_COG?: string;
        PER_NOM?: string;
        TPN_COD?: string;
        PER_FOTO?: string;
        PER_CUR?: string;
        PER_SIGTIT?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.GetAmbulatoriesAppointPersonsByIdPath,
      'get'
    );
    if (params) {
      rb.path('amb_id', params.amb_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }>;
        })
      );
  }

  getAmbulatoriesAppointPersonsById(params: {
    amb_id: string;
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      TPN_DES?: string;
      PER_COG?: string;
      PER_NOM?: string;
      TPN_COD?: string;
      PER_FOTO?: string;
      PER_CUR?: string;
      PER_SIGTIT?: string;
    }>;
  }> {
    return this.getAmbulatoriesAppointPersonsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              TPN_DES?: string;
              PER_COG?: string;
              PER_NOM?: string;
              TPN_COD?: string;
              PER_FOTO?: string;
              PER_CUR?: string;
              PER_SIGTIT?: string;
            }>;
          }
      )
    );
  }

  getAmbulatoriesDeliveriesById$Response(params: {
    amb_id: string;

    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        ID_STR?: string;
        STR_DES?: string;
        STR_COD?: string;
        ID_TEL?: string;
        TEL_CLI?: string;
        TEL_COD?: string;
        TEL_FLG?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AmbulatoriesService.GetAmbulatoriesDeliveriesByIdPath,
      'get'
    );
    if (params) {
      rb.path('amb_id', params.amb_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }>;
        })
      );
  }

  getAmbulatoriesDeliveriesById(params: {
    amb_id: string;

    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      ID_STR?: string;
      STR_DES?: string;
      STR_COD?: string;
      ID_TEL?: string;
      TEL_CLI?: string;
      TEL_COD?: string;
      TEL_FLG?: string;
    }>;
  }> {
    return this.getAmbulatoriesDeliveriesById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              ID_STR?: string;
              STR_DES?: string;
              STR_COD?: string;
              ID_TEL?: string;
              TEL_CLI?: string;
              TEL_COD?: string;
              TEL_FLG?: string;
            }>;
          }
      )
    );
  }
}
