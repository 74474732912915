import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/api/services/download.service';
import { EthicsService } from 'src/app/api/services/ethics.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-ethics-committee',
  templateUrl: './ethics-committee.component.html',
  styleUrls: [
    './ethics-committee.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class EthicsCommitteeComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
    new BreadcrumbItem('NAVBAR_TAB.ETHICS_COMMITEE', ''),
  ];

  constructor(
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private downloadService: DownloadService,
    private comitatoEticoService: EthicsService
  ) {}

  public loaderPage = false;
  public ethicsCommiteeList = [];
  public ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.callSearchFoldersEthicsCommitee();
  }

  callSearchFoldersEthicsCommitee() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.comitatoEticoService.postSearchFoldersEthicsCommitee().subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.ethicsCommiteeList = res.rows;
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => {},
        });
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      }
    );
  }

  public downloadFile(doc) {
    const postType = DocumentPostType.COM_ETIC;
    const postID = doc.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(doc.Filename, url);
  }

  public redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
