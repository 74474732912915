import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  // Services paths COMPANY CRUD

  // * CRUD first level
  static readonly companySearchPath = '/api/v1/direction/_search';
  static readonly companyInsertPath = '/api/v1/direction/_insert';
  static readonly companyUpdatePath = '/api/v1/direction/_update';
  static readonly companyPublishPath = '/api/v1/direction/_put_published';
  static readonly companyDeletePath = '/api/v1/direction/_delete';

  companyManagementSearch(
    body?: {
      ID?: string;
      published?: string;
      Titolo?: String;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + CompanyService.companySearchPath;

    return this.http.post<any>(fullUrl, { ...body, ...params });
  }
  companyPutPublished(params?: { ID?: string; published: string }) {
    let fullUrl = this.rootUrl + CompanyService.companyPublishPath;

    const headers = this.getHeader();

    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }
  companyDelete(params?: { ID?: string; published?: string }) {
    let fullUrl = this.rootUrl + CompanyService.companyDeletePath;

    const headers = this.getHeader();
    return this.http.request<any>('delete', fullUrl, {
      body: { ...params },
      headers: headers,
    });
  }
  companyInsert(params?: {
    ID?: string;
    Titolo?: string;
    More_info?: string;
    published?: string;
  }) {
    let fullUrl = this.rootUrl + CompanyService.companyInsertPath;
    const headers = this.getHeader();
    return this.http.post<any>(fullUrl, { ...params }, { headers });
  }
  companyUpdate(params?: {
    ID?: string;
    Titolo?: string;
    More_info?: string;
    published?: string;
  }) {
    let fullUrl = this.rootUrl + CompanyService.companyUpdatePath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }

  // * CRUD second level
  static readonly structureSearchPath = '/api/v1/direction/strctures/_search';
  static readonly structureAssociateSearchPath =
    '/api/v1/direction/strctures/_associate_search';
  static readonly structureInsertPath = '/api/v1/direction/strctures/_insert';
  static readonly structureUpdatePath = '/api/v1/direction/strctures/_update';
  static readonly structurePublishPath =
    '/api/v1/direction/strctures/_put_published';

  static readonly structureDeletePath = '/api/v1/direction/strctures/_delete';

  companyStructureSearch(
    body?: {
      ID_Direzione?: string;
      published?: string;
      Titolo?: String;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + CompanyService.structureSearchPath;

    return this.http.post<any>(fullUrl, { ...body, ...params });
  }
  structureAssociateSearch(params?: {
    ID?: string;
    published?: string;
    Titolo?: String;
    Tipologia_Struttura?: string;
    ID_Direzione?: string;
  }) {
    let fullUrl = this.rootUrl + CompanyService.structureAssociateSearchPath;
    return this.http.post<any>(fullUrl, { ...params });
  }
  structureInsert(params?: {
    ID?: string;
    Nome_Struttura?: string;
    Luogo_Struttura?: string;
    ID_Direttore?: string;
    ID_Referente?: string;
    Info_Generale?: string;
    Descrizione?: string;
    LastUpdate?: string;
    Tipologia_Struttura?: string;
    published?: string;
    ID_Direzione?: string;
  }) {
    let fullUrl = this.rootUrl + CompanyService.structureInsertPath;
    const headers = this.getHeader();
    return this.http.post<any>(fullUrl, { ...params }, { headers });
  }
  structureUpdate(params?: {
    ID: string;
    Nome_Struttura: string;
    Luogo_Struttura: string;
    ID_Direttore: string;
    ID_Referente: string;
    Info_Generale: string;
    Descrizione: string;
    LastUpdate: string;
    Tipologia_Struttura: string;
    published: string;
    ID_Direzione: string;
  }) {
    let fullUrl = this.rootUrl + CompanyService.structureUpdatePath;

    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }
  structurePublish(params?: { ID?: string; published: string }) {
    let fullUrl = this.rootUrl + CompanyService.structurePublishPath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }
  structureDelete(params?: { ID?: string; published?: string }) {
    let fullUrl = this.rootUrl + CompanyService.structureDeletePath;
    const headers = this.getHeader();
    return this.http.request<any>('delete', fullUrl, {
      body: { ...params },
      headers: headers,
    });
  }

  // Al momento viene Utilizzato solo la search del terzo livello

  // CRUD third level
  static readonly structureMoreInfoSearchPath =
    '/api/v1/direction/strctures/more_info/_search';
  static readonly structureMoreInfoInsertPath =
    '/api/v1/direction/strctures/more_info/_insert';
  static readonly structureMoreInfoUpdatePath =
    '/api/v1/direction/strctures/more_info/_update';
  static readonly structureMoreInfoDeletePath =
    '/api/v1/direction/strctures/more_info/_delete';

  structureMoreInfoSearch(
    body?: {
      ID: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + CompanyService.structureMoreInfoSearchPath;
    return this.http.post<any>(fullUrl, { ...body, ...params });
  }

  getHeader() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    return headers;
  }
}
