import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PuntiRitiroService } from 'src/app/api/services/punti-ritiro.service';
import { puntoRitiroType } from 'src/app/shared/messageType';
import { GenericInfoModalService } from '../generic-info-modal/generic-info-modal.service';

@Component({
  selector: 'app-withdrawal-container',
  templateUrl: './withdrawal-container.component.html',
  styleUrls: ['./withdrawal-container.component.scss'],
})
export class WithdrawalContainerComponent implements OnInit {
  constructor(
    public router: Router,
    public prs: PuntiRitiroService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService
  ) {}
  IdRitiro: number;

  loaderPage = false;
  @Input() dettagliRitiro: puntoRitiroType[];
  personalDataForm: FormGroup = new FormGroup({});
  imgUploaded: string[] | ArrayBuffer[] = [];

  today = new Date().toISOString().split('T')[0];
  
  pdfRitiroCartelle = [
    { type: 'Regolamento rilascio cartelle cliniche', name: 'Regolamento rilascio cartelle cliniche' },
    { type: 'Tariffario', name: 'Tariffario' },
    { type: 'moduloA', name: 'Modulo per la richiesta della Documentazione Sanitaria' },
    { type: 'moduloB', name: 'Dichiarazione Sostitutiva' },
    { type: 'moduloC', name: 'Dichiarazione Sostituiva Rich. documentazione genitori per Minorenni' },
    { type: 'moduloD', name: 'Delega al Ritiro' },
  ];

  // pdfRegTariff = [
  //   { type: 'Regolamento rilascio cartelle cliniche', name: 'Regolamento rilascio cartelle cliniche' },
  //   { type: 'Tariffario', name: 'Tariffario' }
  // ]

  ngOnInit(): void {
    this.personalDataForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      birthdate: new FormControl('', [Validators.required]),
      recovery_date: new FormControl('', [Validators.required]),
      reparto: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  @Input() currentStep;
  @Input() totalSteps;
  @Output() currentStepChange = new EventEmitter();

  isSelectedPuntoRitiro(index): boolean {
    return this.IdRitiro === index;
  }
  handleForward() {
    if (this.currentStep >= this.totalSteps) {
      const requestBody = {
        idRitiro: this.IdRitiro.toString() || '',
        name: this.personalDataForm.get('name').value || '',
        reparto: this.personalDataForm.get('reparto').value || '',
        birthdate: this.personalDataForm.get('birthdate').value || '',
        recovery_date: this.personalDataForm.get('recovery_date').value || '',
        email: this.personalDataForm.get('email').value || '',
        bufferImage: this.imgUploaded || [''],
      };
      this.loaderPage = true;
      this.loaderService.setloader(this.loaderPage);
      this.prs.PostEmailSend(requestBody).subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.currentStepChange.emit(this.currentStep + 1);
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {}.bind(this),
            });
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {}.bind(this),
          });
        }
      );
    } else {
      this.currentStepChange.emit(this.currentStep + 1);
    }
  }
  handleBack() {
    if (this.currentStep - 1 <= 0) {
      this.router.navigate(['home']);
    } else {
      this.currentStepChange.emit(this.currentStep - 1);
    }
  }

  backToHome() {
    this.router.navigate(['home']);
  }

}
