<!-- HEAD -->
<app-main-head [isSearchRequired]="true" [breadcrumbItems]="breadcrumbItems" [bodyList]="headTendersList"
  [mainTitle]="'TENDERS.TENDERS'" [textPlaceholderSearch]="'TENDERS.TENDERS'"
  [rightListPanelText]="'TENDERS.TENDERS_IN_EVIDENCE'" [rightAllPanelText]="'TENDERS.ALL_TENDERS'"
  [redirectText]="'all-tenders'" [routerLinkText]="'/bandi-e-gare'" [isRightPanelVisible]="false"
  [typology]="'tenders'">
</app-main-head>
<!-- END HEAD -->
<!-- wrapper per prevenire scattering -->
<div class="search-container">
  <search [textPlaceholder]="'Bandi e gare'" (searchEvent)="searchExt($event)"></search>
</div>

<div class="wrapper center">
  <div *ngIf="mobile">
    <app-main-accordion-table [dataSource]="tendersList" [typology]="typologyName" [hasAttachment]="false"
      [hasDetail]="true" (detailHandler)="goToDetails($event)">
    </app-main-accordion-table>
  </div>
  <!-- Versione Mobile -->
  <!-- <div *ngIf="mobile" id="accordionDiv1" class="collapse-div" role="tablist">
    <div *ngFor="let element of tendersList">
      <div class="collapse-header" id="headingA{{ addID }}{{ element.ID }}">
        <button
          data-toggle="collapse"
          [attr.data-target]="'#' + addID + element.ID"
          aria-expanded="false"
          [attr.aria-controls]="addID + element.ID"
        >
          <p class="text_title">{{ element.titolo }}</p>
        </button>
      </div>
      <div
        [id]="addID + element.ID"
        class="collapse"
        role="tabpanel"
        [attr.aria-labelledby]="'#' + addID + element.ID"
        data-parent="#accordionDiv1"
      >
        <div class="collapse-body">
          <div class="element_row">
            <p class="element_title">{{ "TENDERS.NAME" | translate }}</p>
            <p class="element_text">{{ element.titolo }}</p>
          </div>
          <div class="element_row">
            <p class="element_title">{{ "TENDERS.VALID_FROM" | translate }}</p>
            <p class="element_text">
              {{ element.iniziovalidita | date : "d MMMM YYYY" : "" : "it" }}
            </p>
          </div>
          <button
            class="btn btn-primary"
            [routerLink]="['/concorsi-e-avvisi', element.ID]"
          >
            {{ "TENDERS.DETAILS" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <!-- (downloadHandler)
    (detailHandler)="goToDetails($event)"
   -->

  <!-- versione Desktop -->
  <div class="center" *ngIf="!mobile">
    <table class="table">
      <tr class="table-header">
        <th class="colonna1">
          {{ "TENDERS.NAME" | translate }}
        </th>
        <th class="colonna2">
          {{ "TENDERS.VALID_FROM" | translate }}
        </th>
        <th class="colonna3">
          {{ "TENDERS.DETAILS" | translate }}
        </th>
      </tr>
      <tbody>
        <tr *ngFor="let elem of tendersList">
          <td>
            {{ elem.titolo }}
          </td>
          <td>
            {{ elem.iniziovalidita | date : "d MMMM YYYY" : "" : "it" }}
          </td>
          <td>
            <button class="btn detail-button" [routerLink]="['/bandi-e-gare', elem.ID]">
              {{ "TENDERS.DETAILS" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<p class="no_result_wrapper" *ngIf="tendersList?.length == 0">
  {{ "ALERT_MESSAGE.NO_RESULTS_FOUND" | translate }}
</p>

<!-- Paginator for tenders all-->
<div>
  <app-paginator [list]="tendersList" [isIntoPaginationLimit]="limitPagination" [currentPage]="currentPage"
    [totalNumberOfPages]="totalNumberOfPages" (didClickedOnPage)="updateList($event)">
  </app-paginator>
</div>