import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from 'src/app/api/services/download.service';

@Component({
  selector: 'app-main-all-body',
  templateUrl: './main-all-body.component.html',
  styleUrls: ['./main-all-body.component.scss'],
})
export class MainAllBodyComponent implements OnInit {
  @Input() bodyList: [];
  @Input() typology = '';
  @Input() mainTitle = '';
  @Input() departments_detail;
  constructor(
    private router: Router,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {}

  goToDetail(data) {
    if (
      this.typology === 'departments_uosd' ||
      this.typology === 'departments_uoc' ||
      this.typology === 'programs-departments'
    ) {
      this.router.navigateByUrl('departments/details/info', {
        state: { ...data, departments_detail: this.departments_detail },
      });
    } else if (this.typology === 'transparentAdministration') {
      if (data.ID !== 1 && data.ID !== 158) {
        this.router.navigateByUrl('/company/transparent-administration', {
          state: data,
        });
      } else if (data.ID === 1) {
        this.router.navigate(['/resolutions/delibere'], {
          queryParams: { years: true, type: 0 },
          skipLocationChange: false,
        });
      } else {
        this.router.navigate(['/resolutions/determine'], {
          queryParams: { years: true, type: 1 },
          skipLocationChange: false,
        });
      }
    } else if (this.typology === 'com_etic') {
      this.router.navigateByUrl('/azienda/comitato-etico' + '/' + data.ID, {
        state: data,
      });
    } else if (this.typology === 'risk_manager') {
      this.router.navigateByUrl('/azienda/risk-management' + '/' + data.ID, {
        state: data,
      });
    } else if (this.typology === 'prod_az') {
    this.router.navigateByUrl('/azienda/procedure-aziendali' + '/' + data.ID, {
      state: data,
    });
  } 
  }

  // isDetailEnabled(element) {
  //   return element.ID === '7' && element.dai_id === '690' ||
  //     element.ID === '10' && element.dai_id === 'L01' ||
  //     element.ID === '3' && element.dai_id === 'L01' ||
  //     element.ID === '11' && element.dai_id === 'L01' ||
  //     element.ID === '1P' && element.dai_id === 'L01' ||
  //     element.ID === '1D' && element.dai_id === '261' ||
  //     element.ID === '1' && element.dai_id === '690' ||
  //     element.ID === '1' && element.dai_id === 'L01';
  // }

  isDetailEnabled(element) {
    if (element.More_Info == '1') {
      return true;
    }
  }

  public downloadFile(doc) {
    let postType =
      this.typology === 'risk_manager_detail' ? 'risk_manager' : 'com_etic';

    if (this.typology == 'atti_not') {
      postType = 'atti_not';
    }

    if (this.typology == 'prod_az_detail') {
      postType = 'prod_az';
    }

    const postID = doc.ID;
    const fileName = doc.NomeFile;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(fileName, url);
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
