import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo-container',
  templateUrl: './logo-container.component.html',
  styleUrls: ['./logo-container.component.scss']
})
export class LogoContainerComponent implements OnInit {

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  backToHome() {
    this.router.navigateByUrl('/home');
  }

}
