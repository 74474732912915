<!-- HEAD -->
<app-main-head [breadcrumbItems]="breadcrumbItems" [isSearchRequired]="false" [mainTitle]="'NAVBAR_TAB.MANUALE_QUALITA'"
  [isRightPanelVisible]="false">
</app-main-head>
<!-- END HEAD -->

<div class="bg-color">
  <app-table-info [data]="detailInfo" [postTypeData]="'manual_qa'" (downloadHandler)="downloadFile($event)"
        [showDate]="false">
    </app-table-info>
    <p *ngIf="!(detailInfo && detailInfo.length > 0) && loaderPage === false"
        style="text-align: center;margin-bottom: 2%;">
        {{"MESSAGE.NO_RESULTS" | translate}}
    </p>
</div>