<div class="shared_detail_head header header-body">
  <div class="shared_detail_head_left">
    <div class="d-flex flex-row">
      <div class="back-button" (click)="backToHome()">
        <b>
          <svg-icon-sprite
            classes="icon icon-white"
            src="../../../assets/sprite.svg#it-arrow-left"
          >
          </svg-icon-sprite>
        </b>

        <span> {{ "BUTTON.BACK" | translate }} </span>
      </div>
    </div>

    <div>
      <div class="steppers">
        <ul class="steppers-header">
          <li
            [ngClass]="{
              confirmed: currentStep !== 1,
              active: currentStep === 1
            }"
          >
            {{ "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.TITLE" | translate }}
            <svg-icon-sprite
              *ngIf="currentStep > 1"
              classes="icon icon-white steppers-success"
              src="../../../assets/sprite.svg#it-check"
            >
            </svg-icon-sprite>
          </li>
          <li
            [ngClass]="{
              confirmed: currentStep !== 2 && currentStep > 2,
              active: currentStep === 2
            }"
          >
            {{ "WITHDRAWAL_MEDICAL_RECORDS.STEPS.SECOND.TITLE" | translate }}
            <svg-icon-sprite
              *ngIf="currentStep > 2"
              classes="icon icon-white steppers-success"
              src="../../../assets/sprite.svg#it-check"
            >
            </svg-icon-sprite>
          </li>
          <li
            [ngClass]="{
              confirmed: currentStep !== 3 && currentStep > 3,
              active: currentStep === 3
            }"
          >
            {{ "WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.TITLE" | translate }}
            <svg-icon-sprite
              *ngIf="currentStep > 3"
              classes="icon icon-white steppers-success"
              src="../../../assets/sprite.svg#it-check"
            >
            </svg-icon-sprite>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-withdrawal-container
  [dettagliRitiro]="detailInfo"
  [totalSteps]="totalSteps"
  [(currentStep)]="currentStep"
>
</app-withdrawal-container>
