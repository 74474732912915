<!-- HEAD -->

<app-main-head
  [isSearchRequired]="false"
  [breadcrumbItems]="breadcrumbItems"
  [bodyList]="headNoticesList"
  [mainTitle]="'RPP.NOTICES'"
  [isRightPanelVisible]="false"
  [typology]="'notices'"
>
</app-main-head>

<!-- END HEAD -->

<div class="wrapper center">
  <!-- versione Mobile -->
  <div *ngIf="mobile">
    <div id="accordionDiv1" class="collapse-div" role="tablist">
      <div *ngFor="let element of rppList">
        <div class="collapse-header" id="headingA{{ addID }}{{ element.id }}">
          <button
            data-toggle="collapse"
            [attr.data-target]="
              '#' + addID + (element.ID ? element.ID : element.id)
            "
            aria-expanded="false"
            [attr.aria-controls]="
              addID + (element.ID ? element.ID : element.id)
            "
          >
            <p class="text_title">
              {{ element.titolo ? element.titolo : element.Titolo }}
            </p>
          </button>
        </div>
        <div
          [id]="addID + (element.ID ? element.ID : element.id)"
          class="collapse"
          role="tabpanel"
          [attr.aria-labelledby]="
            '#' + addID + (element.ID ? element.ID : element.id)
          "
          data-parent="#accordionDiv1"
        >
          <div class="collapse-body">
            <!-- <div
              class="element_row"
              *ngFor="let obj of createInformation(element)"
            >
              <p class="element_title">{{ obj.title | translate }}:</p>
              <p class="element_text">
                {{ obj.value }}
              </p>
            </div> -->
            <div class="buttonWrapper">
              <a href="{{ element.href }}" target="_blank">
                <button class="btn btn-primary">
                  {{ "ALBO_PRETORIO.DETAILS" | translate }}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- versione Desktop -->
  <div class="center" *ngIf="!mobile">
    <table class="table">
      <tr class="table-header">
        <th class="colonna1">{{ "NOTICES_DETAIL.NAME" | translate }}</th>

        <th class="colonna2">{{ "NOTICES_DETAIL.DETAILS" | translate }}</th>
      </tr>
      <tbody>
        <tr *ngFor="let elem of rppList">
          <td [ariaLabel]="elem.titolo">
            {{ elem.titolo }}
          </td>

          <td>
            <a href="{{ elem.href }}" target="_blank">
              <button class="btn detail-button">
                {{ "NOTICES_DETAIL.DETAILS" | translate }}
              </button>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="no_result_wrapper" *ngIf="rppList.length == 0">
    {{ "ALERT_MESSAGE.NO_RESULTS_FOUND" | translate }}
  </p>
</div>
