import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() loader = false;
  @Input() list = [];
  @Input() isIntoPaginationLimit = false;
  @Input() currentPage = 1;
  @Input() totalNumberOfPages = 0;
  @Output() didClickedOnPage = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onClickNext() {
    if (this.isMax()) {
      return;
    }
    this.currentPage = this.currentPage + 1;
    this.didClickedOnPage.emit(this.currentPage);
  }
  onClickPrevious() {
    if (this.isZero()) {
      return;
    }
    this.currentPage = this.currentPage - 1;
    this.didClickedOnPage.emit(this.currentPage);
  }

  onClickNumber(page: number) {
    this.currentPage = page;
    this.didClickedOnPage.emit(this.currentPage);
  }

  isZero() {
    return this.currentPage - 1 <= 0;
  }
  isMax() {
    return this.currentPage + 1 > this.totalNumberOfPages;
  }
}
