import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-booking-and-contacts',
  templateUrl: './booking-and-contacts.component.html',
  styleUrls: ['./booking-and-contacts.component.scss'],
})
export class BookingAndContactsComponent implements OnInit {
  // Booking-and-contacts
  cards = [
    {
      Title: 'CONTACTS_SUBVIEWS.BY_TRAIN',
      CardContent: [
        {
          Text: 'CONTACTS_SUBVIEWS.TRAIN_STATION_1',
          subInfo: [
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_1_TEXT_1',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_1_TEXT_2',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_1_TEXT_3',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_1_TEXT_4',
            },
          ],
        },
        {
          Text: 'CONTACTS_SUBVIEWS.TRAIN_STATION_2',
          subInfo: [
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_2_TEXT_1',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_2_TEXT_2',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_2_TEXT_3',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_2_TEXT_4',
            },
            {
              Text: 'CONTACTS_SUBVIEWS.LINE_2_TEXT_5',
            },
          ],
        },
        {
          Text: '<i>Suggerimento:</i> Verifica gli orari di funzionamento delle metropolitane sul sito della <a  Target= "_blank" href="https://www.anm.it/">ANM Napoli</a> ',
        },
      ],
    },
    {
      Title: 'CONTACTS_SUBVIEWS.BY_BUS',
      CardContent: [
        {
          Text: '<b>Fermate principali:</b> Piazza Dante (servita dalle linee bus [numero delle linee bus], circa 7 minuti a piedi dal policlinico).',
          subInfo: [
            {
              Text: 'Fermata Bus: Piazza Dante',
            },
            {
              Text: 'Dalla fermata del bus in Piazza Dante, procedi verso nord-est in direzione di Via Pessina.',
            },
            {
              Text: 'Svolta a destra in Via Santa Maria di Costantinopoli.',
            },
            {
              Text: 'Prosegui dritto fino a raggiungere Piazza Bellini e poi Piazza Miraglia.',
            },
            {
              Text: 'Prosegui dritto fino a raggiungere Piazza Bellini e poi Piazza Miraglia.',
            },
            {
              Text: 'Il policlinico è situato sulla sinistra.',
            },
          ],
        },
        {
          Text: '<i>Suggerimento:</i> Consulta gli orari e le linee sul sito dell <a  Target= "_blank" href="https://www.anm.it/">ANM Napoli</a> ',
        },
      ],
    },
    {
      Title: 'CONTACTS_SUBVIEWS.BY_CAR',
      CardContent: [
        {
          Text: 'CONTACTS_SUBVIEWS.CAR_1',
        },
        {
          Text: 'CONTACTS_SUBVIEWS.CAR_2',
        },
        {
          Text: 'CONTACTS_SUBVIEWS.CAR_3',
        },
      ],
    },
    {
      Title: 'CONTACTS_SUBVIEWS.GENERALS',
      CardContent: [
        {
          Text: '<b>Indicazioni Stradali: </b><a  Target= "_blank" href="https://www.google.com/maps/dir/piazza+miraglia+5+napoli">Qui</a>',
        },
        {
          Text: 'CONTACTS_SUBVIEWS.GENERALS_2',
        },
      ],
    },
  ];
  firstCardRow: any = [];
  secondCardRow: any = [];
  constructor(
    public translate: TranslateService,
    public sharedData: SharedData
  ) {}

  ngOnInit(): void {
    this.secondCardRow = this.cards.splice(2, 3);
    this.firstCardRow = this.cards.splice(0, 2);
  }

  ngAfterViewInit(): void {
    // this.redirectTo('book-visit');
  }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
