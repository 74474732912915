import { Component, OnInit } from '@angular/core';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-URP-body',
  templateUrl: './URP_body.component.html',
  styleUrls: ['./URP_body.component.scss', '../../shared/sharedStyle.scss'],
})
export class URPBodyComponent implements OnInit {
  constructor(public sharedData: SharedData) { }

  ngOnInit(): void { }

  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
