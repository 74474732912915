import { Component, Input, OnInit } from '@angular/core';
import { PermalinksResponseType } from 'src/app/shared/messageType';

@Component({
  selector: 'app-news-detail-body',
  templateUrl: './news_detail_body.component.html',
  styleUrls: [
    './news_detail_body.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class NewsDetailBodyComponent implements OnInit {
  @Input() detailInformation: PermalinksResponseType;
  activeSection = 'description';
  constructor() {}

  ngOnInit(): void {}
  navigateToSection(section: string) {
    document.getElementById(section).scrollIntoView();
  }
}
