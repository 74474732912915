import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { puntoRitiroType } from 'src/app/shared/messageType';

@Component({
  selector: 'app-place-departments-card',
  templateUrl: './place-departments-card.component.html',
  styleUrls: ['./place-departments-card.component.scss']
})
export class PlaceDepartmentsCardComponent implements OnInit {

  constructor() { }
  @Output() idRitiro = new EventEmitter();
  @Input() puntoRitiro: puntoRitiroType;
  @Input() isSelected = false;


  ngOnInit(): void {
  }

  confirmDepartment() {
    this.idRitiro.emit(this.puntoRitiro.Files[0].IdRitiro);
  }

}
