import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import {
  NavbarItemType,
  NavigatorsPermalinksResponseType,
} from 'src/app/shared/messageType';
import { IS_MOBILE } from 'src/app/shared/models/constants';
import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isAccessiblityOpen = false;
  mobile = IS_MOBILE;
  isOpenMenu = false;
  selectedTab = 'home';
  riskManagements: any[] = [];
  @Input() navItems: NavbarItemType;

  constructor(
    public sharedData: SharedData,
    public router: Router,
    public translate: TranslateService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        if (
          val.urlAfterRedirects.indexOf('homeTransparentAdministration') > -1 ||
          val.urlAfterRedirects.indexOf('amministrazione-trasparente-detail') >
            -1 ||
          val.urlAfterRedirects.indexOf('Whistleblowing') > -1
        ) {
          this.selectedTab = 'amministrazione-trasparente';
        } else if (val.urlAfterRedirects.indexOf('home') > -1) {
          this.selectedTab = 'home';
        } else if (
          val.urlAfterRedirects.indexOf('azienda') > -1 ||
          val.urlAfterRedirects.indexOf('company') > -1
        ) {
          this.selectedTab = 'azienda';
        } else if (
          val.urlAfterRedirects.indexOf('dipartimenti') > -1 ||
          val.urlAfterRedirects.indexOf('departments') > -1
        ) {
          this.selectedTab = 'dipartimenti';
        } else if (val.urlAfterRedirects.indexOf('news') > -1) {
          this.selectedTab = 'news';
        } else if (val.urlAfterRedirects.indexOf('resolutions') > -1) {
          if (this.route.snapshot.queryParams['years']) {
            this.selectedTab = 'azienda';
          } else {
            this.selectedTab = 'albo-pretorio';
          }
        } else if (val.urlAfterRedirects.indexOf('concorsi-e-avvisi') > -1) {
          this.selectedTab = 'concorsi-e-avvisi';
        } else if (val.urlAfterRedirects.indexOf('bandi-e-gare') > -1) {
          this.selectedTab = 'bandi-e-gare';
        } else if (val.urlAfterRedirects.indexOf('atti-di-notifica') > -1) {
          this.selectedTab = 'atti-di-notifica';
        } else if (val.urlAfterRedirects.indexOf('campania-pancreas') > -1) {
          this.selectedTab = 'campania-pancreas';
        } else if (val.urlAfterRedirects.indexOf('prenota') > -1) {
          this.selectedTab = '';
        }

        if (this.isOpenMenu) {
          this.isOpenMenu = false;
        }
      });
  }

  ngOnInit(): void {}

  onChangeTab(event, companyTab, openMenu?) {
    if (companyTab.innerText == 'Cup e ritiro cartelle') {
      this.selectedTab = 'Cup e ritiro cartelle';
    } else this.selectedTab = companyTab?.id;
  }

  hasSubMenu(navItem) {
    // nel caso si tratta del link malattie rare aprire una nuova scheda
    if (navItem.nav_id === 999) {
      window.open('https://malattierare.policliniconapoli.it', '_blank');
    } else
      return (
        (navItem.links && navItem.links.length) ||
        (navItem.subMenu && navItem.subMenu.length)
      );
  }

  // non piu` usato
  // logFunction() {}

  // setEnLanguage() {
  //   this.translate.use('en');
  // }
  // setItLanguage() {
  //   this.translate.use('it');
  // }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
