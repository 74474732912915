import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-add-documental',
  templateUrl: './button-add-documental.component.html',
  styleUrls: ['./button-add-documental.component.scss']
})
export class ButtonAddDocumentalComponent implements OnInit {

  @Input() typeDocumental;
  @Input() typeName;
  @Input() isDisabled = false;
  @Input() buttonText = "DOCUMENTS.ADD"

  @Output() actionHandler = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  buttonDidPress() {
    const value = {
      isReadOnly: false,
      showBodyModal: false,
      document: this.typeName
    };
    this.actionHandler.emit(value);
  }

}
