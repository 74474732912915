<div class="in_evidence" role="region" [attr.aria-label]="titleText | translate">
  <p class="body-in-evid">{{ titleText | translate }}</p>
  <div *ngIf="bodyList" class="cards">
    <ng-container *ngIf="typology === 'departments'">
      <app-departments-card *ngFor="let element of bodyList" [data]="element"></app-departments-card>
    </ng-container>
    <ng-container *ngIf="typology === 'notices' || typology === 'tenders'">
      <app-notices-card [typology]="typology" *ngFor="let element of bodyList" [data]="element">
      </app-notices-card>
    </ng-container>
    <ng-container *ngIf="typology === 'news'">
      <app-news-card *ngFor="let element of bodyList" [data]="element"></app-news-card>
    </ng-container>
    <ng-container *ngIf="typology === 'transparentAdministration'">
      <app-transparent-administration-evidence-card *ngFor="let element of bodyList" [data]="element">
      </app-transparent-administration-evidence-card>
    </ng-container>
    <ng-container *ngIf="typology === 'com_etic_detail'">
      <div class="row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3" style="width: 100%">
        <ng-container *ngFor="let element of bodyList">
          <app-info-card [minHeight]="'200px'" [data]="element" [title]="element.Nome" [postTypeData]="com_etic"
            [detailText]="'BUTTON.READ_MORE'" (actionFunction)="goToDetail($event)" [showDetail]="false">
          </app-info-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="typology === 'prod_az_detail'">
      <div class="row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3" style="width: 100%">
        <ng-container *ngFor="let element of bodyList">
          <app-info-card [minHeight]="'200px'" [data]="element" [title]="element.Nome" [postTypeData]="prod_az"
            [detailText]="'BUTTON.READ_MORE'" (actionFunction)="goToDetailProcedure($event)" [showDetail]="false">
          </app-info-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="typology === 'company-presentation'">
      <div class="disposition row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3" style="width: 100%">
        <ng-container *ngFor="let element of bodyList">
          <app-company-presentation-card [data]="element"></app-company-presentation-card>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>