<!-- HEAD -->
<app-main-head [breadcrumbItems]="breadcrumbItems" [isSearchRequired]="false" [mainTitle]="'NAVBAR_TAB.PRIVACY'"
  [isRightPanelVisible]="false">
</app-main-head>
<!-- END HEAD -->

<div class="bg-color">
  <ng-container *ngFor="let mp of mapPrivacy | keyvalue; index as i">
    <p class="shared_paragraph_title" role="region" [id]="mp.key + i" [attr.aria-label]="mp.key + i">
      {{ mp.key }}
    </p>
    <app-table-info [data]="mp.value" [postTypeData]="'privacy'" (downloadHandler)="downloadFile($event)"
      [showDate]="false">
    </app-table-info>
  </ng-container>

  <p *ngIf="!mapPrivacy && loaderPage === false" style="text-align: center; margin-bottom: 2%">
    {{ "MESSAGE.NO_RESULTS" | translate }}
  </p>
</div>