<div [class.it-dialog-scrollable]="documentType.value" data-keyboard="false" data-backdrop="static"
  class="modal it-dialog-scrollable fade" tabindex="-1" role="dialog" [id]="idModalName">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="margin: 0 auto">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ getModalTitle() }}
        </h5>
      </div>
      <div class="modal-body" *ngIf="showBodyModal">
        <form [formGroup]="documentForm">
          <div class="row select-container">
            <!-- Tipologia-->
            <div class="col-4" *ngIf="showType">
              <label><b>{{ "DOCUMENTS.TYPE" | translate }} </b></label>
              <!-- Select BANDO/CONCORSI -->
              <div class="bootstrap-select-wrapper" *ngIf="
                  getTypologyNameFromNameAvailability() === 'select_notices'
                ">
                <div class="row">
                  <ng-container>
                    <select class="typeSelect" [ngModelOptions]="{ standalone: true }"
                      (change)="onChangeSelectNotices($event)">
                      <option [ngValue]="" [disabled]="true">
                        {{
                        "DOCUMENTS.DOCUMENTS_MODAL_SELECT_TYPOLOGY"
                        | translate
                        }}
                      </option>
                      <option [selected]="documentToShow === 'CONCORSI'" value="CONCORSI">
                        {{ getNameTitle("CONCORSI") }}
                      </option>
                      <option [selected]="documentToShow === 'BANDO'" value="BANDO">
                        {{ getNameTitle("BANDO") }}
                      </option>
                    </select>
                  </ng-container>
                </div>
              </div>
              <!-- DEFAULT -->
              <div class="bootstrap-select-wrapper" *ngIf="getTypologyNameFromNameAvailability() === 'readonly'">
                <div class="row">
                  <ng-container>
                    <div class="col-12 select-container">
                      <label>
                        {{ getNameTitle(documentForm.controls["type"].value) }}
                      </label>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- -->
          </div>
          <div formArrayName="attributes" *ngFor="let attribute of attributes.controls; let i = index">
            <div class="row" [formGroupName]="i">
              <div class="col-12 form-control-container" [class.checkbox-container]="control.type === 'checkbox'"
                *ngFor="let control of getControls(i)">
                <ng-container *ngIf="
                    control.type !== 'select' && control.type !== 'checkbox'
                  ">
                  <label [for]="control.name" [ngClass]="{
                      'required-marker':
                        !(control.type === 'file' && isReadOnly) &&
                        control.required
                    }">
                    <b>
                      {{
                      control.type === "file" && isReadOnly
                      ? ""
                      : control.title
                      }}
                    </b></label>

                  <input *ngIf="control.type !== 'checkbox'" accept="{{
                      documentService.getAcceptedFile(documentToShow)
                    }}" [formControlName]="control.name" [maxLength]="documentService.getMaxLength(control, i)"
                    [id]="control.name" [type]="control.type" [min]="documentService.getMinDate(attributes, control, i)"
                    class="form-control mb-4" [placeholder]="'Inserisci' + ' ' + control.title"
                    [ngClass]="{ upload: control.type === 'file' }" (change)="onChange($event, control, i)"
                    [class.disabled]="
                      documentService.isInputDisabled(attributes, control, i)
                    " />
                  <p *ngIf="isCounterAvailable(control)" class="counter-input">
                    {{ control.value.length }} /<i>{{
                      documentService.getMaxLength(control, i)
                      }}</i>
                  </p>
                  <!-- * MAIL VALIDATOR LABEL * -->
                  <p *ngIf="
                      control.name === 'mail' &&
                      !documentService.getMailIsEmpty(attributes, i)
                    " class="counter-input" [ngClass]="{
                      invalid_mail: !documentService.getMailIsValid(
                        attributes,
                        i
                      ),
                      valid_mail: documentService.getMailIsValid(attributes, i)
                    }">
                    {{
                    documentService.getMailIsValid(attributes, i)
                    ? ("DOCUMENTS.VALID_MAIL" | translate)
                    : ("DOCUMENTS.NOT_VALID_MAIL" | translate)
                    }}
                  </p>
                  <!-- * PATH VALIDATOR LABEL * -->
                  <p *ngIf="
                      control.name === 'Link' &&
                      modalMode !== 'readonly' &&
                      !documentService.getPathlinkIsEmpty(attributes, i)
                    " class="counter-input" [ngClass]="{
                      invalid_mail: !documentService.getPathlinkIsValid(
                        attributes,
                        i
                      ),
                      valid_mail: documentService.getPathlinkIsValid(
                        attributes,
                        i
                      )
                    }">
                    {{
                    documentService.getPathlinkIsValid(attributes, i)
                    ? ("DOCUMENTS.VALID_PATH" | translate)
                    : ("DOCUMENTS.NOT_VALID_PATH" | translate)
                    }}
                  </p>

                  <ng-container *ngIf="control.type === 'file' && !isReadOnly">
                    <label *ngIf="
                    !checkAddFilesIsDisabled(filesUploaded, documentToShow) &&
                        !isReadOnly &&
                        showButtonFile &&
                        !(checkTrashbinAvailable() && modalMode === 'edit')
                      " [class.disabled]="
                        checkAddFilesIsDisabled(filesUploaded, documentToShow)
                      " [for]="control.name" style="
                        float: right;
                        font-size: 0.875em;
                        padding: 0.3125em 0.9375em;
                      ">
                      <svg-icon-sprite src="../../../assets/sprite.svg#it-upload" classes="icon icon-sm">
                      </svg-icon-sprite>
                      <span>
                        {{
                        "DOCUMENTS.DOCUMENTS_MODAL_ADD_FILE_LABEL" | translate
                        }}
                      </span>
                    </label>
                    <h5 *ngIf="
                        control.type === 'file' && !isReadOnly && !fileUploading
                      " style="font-size: medium">
                      <b>
                        {{ documentService.getAcceptedText(documentToShow) }}
                      </b>
                    </h5>
                    <h5 class="error-label" *ngIf="errorFileString">
                      <b>
                        {{errorFileString}}
                      </b>
                    </h5>

                    <!-- Read only case -->

                    <ul class="upload-file-list" style="margin-top: 0.9375em">
                      <div *ngIf="filesUploaded && filesUploaded.length === 0" class="no-file-container">
                        <svg-icon-sprite src="../../../assets/sprite.svg#it-files" classes="icon icon-sm">
                        </svg-icon-sprite>
                        <span>
                          {{ "DOCUMENTS.DOCUMENTS_MODAL_NO_FILE" | translate }}
                        </span>
                      </div>

                      <li *ngFor="let file of filesUploaded" class="upload-file success" style="max-width: 100%">
                        <svg-icon-sprite *ngIf="!hasBaseSixtyfourUpload" src="../../../assets/sprite.svg#it-file"
                          classes="icon icon-sm">
                        </svg-icon-sprite>

                        <p style="cursor: pointer !important;" (click)="
                            documentService.didSelectAttachment(
                              file,
                              modalMode,
                              selectedTypeDocument.nome_pubblicazione
                            )
                          ">
                          <img *ngIf="hasBaseSixtyfourUpload && file.imagePath" class="image-path-file"
                            [src]="file.imagePath" />
                          <span class="sr-only">File caricato:</span>
                          {{ hasBaseSixtyfourUpload && selectedTypeDocument.nome_pubblicazione === 'HOME_CAROUSEL' ? ''
                          : documentService.getDocumentName(file) }}
                          <span class="upload-file-weight" *ngIf="file.size">{{ (file.size / 1024 / 1024).toFixed(2) }}
                            MB</span>
                        </p>
                        <button *ngIf="
                            selectedTypeDocument &&
                            !(
                              checkTrashbinAvailable() && modalMode !== 'create'
                            )
                          " (click)="
                            removeFile(
                              file,
                              selectedTypeDocument.nome_pubblicazione
                            )
                          ">
                          <span>
                            <svg-icon-sprite class="icon-danger" src="../../../assets/sprite.svg#it-delete"
                              classes="icon icon-sm">
                            </svg-icon-sprite>
                          </span>
                        </button>
                      </li>
                    </ul>
                  </ng-container>

                  <ng-container *ngIf="control.type === 'file' && isReadOnly">
                    <label *ngIf="!hasBaseSixtyfourUpload" [ngClass]="{ 'required-marker': control.required }"
                      class="title-upload" for="documents"><b>{{ "NOTICES_DETAIL.DOCUMENTS" | translate }}
                      </b></label>
                    <div *ngIf="!hasBaseSixtyfourUpload">
                      <ul class="upload-file-list" *ngFor="let doc of selectedRow.documents">
                        <li class="upload-file">
                          <svg-icon-sprite src="../../../assets/sprite.svg#it-files" classes="icon icon-sm">
                          </svg-icon-sprite>
                          <p style="cursor: pointer !important;" (click)="
                              documentService.didSelectAttachment(
                                doc,
                                modalMode,
                                selectedTypeDocument.nome_pubblicazione
                              )
                            ">
                            <img *ngIf="hasBaseSixtyfourUpload && doc.imagePath" class="image-path-file"
                              [src]="file.imagePath" />
                            {{ documentService.getDocumentName(doc) }}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div style="cursor: pointer;"
                      *ngIf="hasBaseSixtyfourUpload && filesUploaded && filesUploaded.length > 0" (click)="documentService.didSelectAttachment(
                      filesUploaded[0],
                      modalMode,
                      selectedTypeDocument.nome_pubblicazione
                    )">
                      <img class="image-path-file" [src]="filesUploaded[0].imagePath" />
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="control.type === 'checkbox'">
                  <label [for]="control.name" [class.checkbox]="control.type === 'checkbox'">
                    <b style="text-decoration: underline">
                      {{ control.title }}
                    </b></label>
                  <input [formControlName]="control.name" [id]="control.name" type="checkbox" class="form-control mb-4"
                    (change)="onChange($event, control, i)" />
                </ng-container>

                <!-- * SELECT with options PRIVACY * -->
                <ng-container *ngIf="control.type !== 'file' && control.type === 'select'">
                  <div class="select-container">
                    <label [ngClass]="{ 'required-marker': control.required }" class="title-upload"
                      for="{{ control.title }}"><b style="font-size: 15px">{{ control.title }}
                      </b></label>
                    <p *ngIf="isReadOnly">
                      <label> {{ getPrivacyFolderID(control) }} </label>
                    </p>
                    <div *ngIf="!isReadOnly" class="bootstrap-select-wrapper">
                      <select class="typeSelect" [formControlName]="control.name" title="Scegli un'opzione">
                        <option [ngValue]="" disabled>
                          Seleziona categoria
                        </option>
                        <option *ngFor="let category of privacyList" [ngValue]="category.ID">
                          {{ category.Nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button class="btn btn-outline-primary btn-sm" type="button" data-dismiss="modal">
          {{
          !isReadOnly
          ? ("BUTTON.CANCEL" | translate)
          : ("BUTTON.BACK" | translate)
          }}
        </button>
        <button *ngIf="!isReadOnly" class="btn btn-primary btn-sm" type="button" [disabled]="!checkDisableFormModal()"
          (click)="
            modalMode === 'create' ? onSubmitModalAdd() : onSubmitModalEdit()
          ">
          {{
          (modalMode === "create"
          ? confirmText
          : isReadOnly
          ? "DOCUMENTS.READ"
          : confirmText
          ) | translate
          }}
        </button>
      </div>
    </div>
  </div>
</div>