import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HomeService } from 'src/app/api/services/home.service';

import { SharedData } from 'src/app/shared/sharedData';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() riskManagementNavItems: any[] = [];
  @Input() companyNavItems: any[] = [];
  @Input() administrationNavItems: any[] = [];

  homeCompletedResult$: Observable<any>;
  private homeCompletedSubject = new Subject<any>();

  showFooter = false;

  constructor(public sharedData: SharedData, public homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.homeCompletedResult$.subscribe((data) => {
      this.showFooter = data;
    });
  }

  ngOnChanges(): void {}

  redirectTo(elementId) {
    setTimeout(() => {
      document.getElementById(elementId)?.scrollIntoView();
    }, 200);
  }
}
