import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cell-info',
  templateUrl: './cell-info.component.html',
  styleUrls: ['./cell-info.component.scss', '../../shared/sharedStyle.scss'],
})
export class CellInfoComponent implements OnInit {
  @Input() data;
  @Input() tipology;
  @Input() title;
  @Input() showDetail = false;
  @Input() linkRouter = undefined;
  @Input() isArrowHidden: boolean = false;
  @Output() actionFunction = new EventEmitter<any>();

  minHeight = '50px';

  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    this.minHeight = this.isLinkVisible() ? '50px' : '1em';
  }

  isLinkVisible() {
    return this.showDetail;
  }

  callAction() {
    if (this.linkRouter) {
      this.router.navigate([this.linkRouter]);
    } else {
      this.actionFunction.emit(this.data);
    }
  }
}
