import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/api/services/loader.service';
import { LoginService } from 'src/app/api/services/login.service';
import { User } from 'src/app/shared/messageType';
import jwt_decode from 'jwt-decode';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { ManagementService } from 'src/app/api/services/management.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hidePassword = true;
  hideResetPassword = [true, true];
  constructor(
    private loginService: LoginService,
    private loaderService: LoaderService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private location: Location,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private managementService: ManagementService
  ) { }

  loaderPage = false;
  credentials: FormGroup = new FormGroup({});
  errorMessage: string;
  isLogged = false;
  isResetPassword = false;
  isNotExpiredToken = false;
  user: User;


  token = '';



  ngOnInit(): void {
    if (!this.deviceService.isDesktop()) {
      this.router.navigateByUrl('/home');
    }

    this.setUserRole();



    this.credentials = new FormGroup({
      username: new FormControl({ value: '', disabled: this.isResetPassword }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      password: new FormControl({ value: '', disabled: this.isResetPassword }, [Validators.required, Validators.minLength(8)]),
      resetPassword: new FormControl('', [Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.minLength(8)])
    });

  }

  didPressLogin() {
    this.loaderPage = true;
    this.errorMessage = undefined;
    this.loaderService.setloader(this.loaderPage);
    const requestBody = {
      email: this.credentials.get('username').value || '',
      password: this.credentials.get('password').value || ''
    };
    this.loginService.PostLogin(requestBody).subscribe(res => {

      if (res) {
        if (res.rows[0] && res.rows[0].token) {
          if (this.credentials.get('password').value === 'cambiami') {
            setTimeout(() => {
              this.credentials.get('username').disable();
              this.credentials.get('password').disable();
              this.hidePassword = true;
              this.isResetPassword = true;
              this.loaderPage = false;
              this.token = res.rows[0].token;
              this.loaderService.setloader(this.loaderPage);
            }, 2000);
          }
          else {
            this.token = res.rows[0].token;
            this.storeLogin(this.token);
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        }
        else {
          this.errorMessage = res.message;
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }


      }
    }, err => {
      //this.isLogged = true;
      this.clearLogin();
      this.errorMessage = this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR');
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
    })
  }


  didPressReset() {
    this.loaderPage = true;
    this.errorMessage = undefined;
    this.loaderService.setloader(this.loaderPage);
    const requestBody = {
      email: this.credentials.get('username').value || '',
      password: this.credentials.get('resetPassword').value || ''
    };
    this.loginService.PostResetPassword(requestBody).subscribe(res => {

      if (res && res.rowCount >= 0) {
        this.storeLogin(this.token);
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.loginService.logged = true;
        this.infoModal.setModal({
          show: true,
          type: 'success',
          title: this.translate.instant('USER_MANAGEMENT.RESET_PASSWORD'),
          bodyText: 'Il reset della password dell\'utenza ' + (this.credentials.get('username').value || '') + ' é stata effettuata con successo',
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => { }
        });
        // document.getElementById("password-reset-button").click();
      }
      else {
        this.errorMessage = res.message;
        this.clearLogin();
        this.isLogged = false;
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      }
    }, err => {
      // this.isLogged = true;
      this.errorMessage = this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR');
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
      this.clearLogin();
    });
  }

  checkReset() {
    return this.credentials.get('resetPassword').value !== '' &&
      this.credentials.get('confirmPassword').value !== '' &&
      this.credentials.get('resetPassword').value === this.credentials.get('confirmPassword').value &&
      this.credentials.get('resetPassword').value !== this.credentials.get('password').value &&
      !this.credentials.invalid;
  }

  storeLogin(token) {
    this.isLogged = true;
    this.isNotExpiredToken = true;
    localStorage.setItem('token', token);
    this.user = jwt_decode(localStorage.getItem('token'));
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  clearLogin() {
    this.isLogged = false;
    localStorage.removeItem('token');
    this.user = null;
    localStorage.removeItem('user');
    this.location.replaceState('/management/login');
  }

  doLogout() {
    this.clearLogin();
    window.location.reload();
  }


  setUserRole() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user?.email;
    this.managementService.Typebit(email).subscribe(res => {
      if (res && res.rowCount > 0) {
        if (this.loginService.logged) {
          this.isLogged = true;
          this.isNotExpiredToken = true;
          return;
        }
        this.token = '';
      }
      else {
        this.clearLogin();
      }
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);

    }, err => {

      console.error(err);
      this.loaderPage = false;
      this.loaderService.setloader(this.loaderPage);
      this.clearLogin();
    });
  }

}
