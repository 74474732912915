import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewsService } from 'src/app/api/services';
import { LoaderService } from 'src/app/api/services/loader.service';
import { PermalinksService } from 'src/app/api/services/permalinks.service';
import { IS_MOBILE } from 'src/app/shared/models/constants';
import { GenericInfoModalService } from '../generic-info-modal/generic-info-modal.service';

@Component({
  selector: 'app-home-news',
  templateUrl: './home_news.component.html',
  styleUrls: ['./home_news.component.scss']
})
export class HomeNewsComponent implements OnInit {
  loaderPage = false;
  constructor(

  ) { }

  @Input() newsList = [];

  mobile = IS_MOBILE;

  ngOnInit(): void {


  }

}
