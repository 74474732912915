<!-- HEAD -->
<app-main-head
  *ngIf="detailInformation && detailInformation[0]"
  [breadcrumbItems]="breadcrumbItems"
  [isSearchRequired]="false"
  [mainTitle]="detailInformation ? detailInformation[0].Titolo : ''"
  [isRightPanelVisible]="false"
>
</app-main-head>
<!-- END HEAD -->

<app-departments-detail-body
  *ngIf="detailInformation"
  [departments_detail]="detailInformation[0]"
  [dai_id]="detailInformation[0].ORG_COD"
  [id]="detailInformation[0].ID"
></app-departments-detail-body>
