<div id="main-wrapper" class="accessibility-wrapper">
  <div (click)="onClickAnim()" class="accessibility-container">
    <img
      class="accessibility-icon"
      alt="accessibility-icon"
      src="../../../assets/Accessibility.svg"
    />
  </div>

  <div class="link-list-wrapper">
    <ul class="link-list" aria-label="Opzioni Accessibilitá">
      <li role="region" aria-label="Aumenta testo">
        <div
          class="list-item"
          (click)="setIncreasedFontSize()"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('f-zoom-in')
          }"
        >
          <img
            src="../../../assets/zoom-in-icon.svg"
            class="width-16"
            alt="zoom in"
          />
          <span class="text-style">{{
            "ACCESSIBILITY_MENU.INCREASE_TEXT_SIZE" | translate
          }}</span>
        </div>
      </li>
      <li>
        <!---->
      </li>

      <li role="region" aria-label="Diminiuisci testo">
        <div
          class="list-item"
          (click)="setDecreasedFontSize()"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('f-zoom-out')
          }"
        >
          <img
            src="../../../assets/zoom-out.svg"
            class="width-16"
            alt="zoom out"
          />
          <span class="text-style">{{
            "ACCESSIBILITY_MENU.DECREASE_TEXT_SIZE" | translate
          }}</span>
        </div>
      </li>
      <li>
        <!----->
      </li>

      <li role="region" aria-label="Scala di grigi">
        <div
          class="list-item"
          (click)="setGrayscale()"
          id="grayscale-option"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('grayscale')
          }"
        >
          <img
            src="../../../assets/Bars.svg"
            class="vertical-align-baseline width-16"
            alt="grayscale"
          />
          <span class="text-style">{{
            "ACCESSIBILITY_MENU.GRAY_SCALE" | translate
          }}</span>
        </div>
      </li>
      <li>
        <!---->
      </li>

      <li role="region" aria-label="Link sottolineati">
        <div
          class="list-item"
          (click)="setLinksUnderlined()"
          id="links-underline"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('links-underline')
          }"
        >
          <img
            src="../../../assets/linkIcon.svg"
            class="width-16"
            alt="Link Icon"
          />

          <span class="text-style">{{
            "ACCESSIBILITY_MENU.UNDERLINED_LINKS" | translate
          }}</span>
        </div>
      </li>
      <!---->
      <li role="region" aria-label="Contrasto negativo">
        <div
          class="list-item"
          (click)="setNegativeContrast()"
          id="negative-contrast"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('negative-contrast')
          }"
        >
          <img
            src="../../../assets/Eye.svg"
            class="vertical-align-baseline width-16"
            alt="negative-contrast"
          />
          <span class="text-style">{{
            "ACCESSIBILITY_MENU.NEGATIVE_CONTRAST" | translate
          }}</span>
        </div>
      </li>
      <!---->
      <li role="region" aria-label="Alto Contrasto">
        <div
          class="list-item"
          (click)="setHighContrast()"
          id="high-contrast-accessibility"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility(
              'high-contrast-accessibility'
            )
          }"
        >
          <img
            src="../../../assets/HighContrast.svg"
            class="vertical-align-baseline width-16"
            alt="high-contrast-accessibility"
          />
          <span class="text-style">
            {{ "ACCESSIBILITY_MENU.HIGH_CONTRAST" | translate }}</span
          >
        </div>
      </li>
      <!---->
      <li role="region" aria-label="Sfondo bianco">
        <div
          class="list-item"
          (click)="setLightBackground()"
          id="light-background-accessibilityy"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility(
              'light-background-accessibility'
            )
          }"
        >
          <img
            src="../../../assets/Light.svg"
            class="vertical-align-baseline width-16"
            alt="light-background-accessibility"
          />
          <span class="text-style">
            {{ "ACCESSIBILITY_MENU.WHITE_BACKGROUND" | translate }}
          </span>
        </div>
      </li>
      <!---->
      <li role="region" aria-label="Font leggibile">
        <div
          class="list-item"
          (click)="setReadableFont()"
          id="readable-font"
          [ngClass]="{
            'highlighted-accessibility': checkAccessibility('readable-font')
          }"
        >
          <img
            src="../../../assets/ReadableFont.svg"
            class="vertical-align-baseline width-16"
            alt="readable-font"
          />
          <span class="text-style">
            {{ "ACCESSIBILITY_MENU.READABLE_FONT" | translate }}
          </span>
        </div>
      </li>
      <!---->
      <li role="region" aria-label="Reset">
        <div
          class="list-item"
          (click)="restoreAccessibility()"
          id="restore-accessibility"
        >
          <img
            src="../../../assets/restart.svg"
            alt="restart-settings"
            class="width-16"
          />

          <span class="text-style">{{
            "ACCESSIBILITY_MENU.RESET" | translate
          }}</span>
        </div>
      </li>
      <!---->
    </ul>
  </div>
</div>
