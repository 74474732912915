import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable, Subject } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import {
  NavigatorsPermalinksResponseType,
  PermalinksResponseType,
  PostCreatePermalinksBody,
} from 'src/app/shared/messageType';
import { environment } from 'src/environments/environment';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class PermalinksService extends BaseService {
  private landedToPermalink = new Subject<string>();
  $landedToPermalinkEmitted = this.landedToPermalink.asObservable();
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }
  emitLandedToPermalink(typeOfResource: string) {
    this.landedToPermalink.next(typeOfResource);
  }
  /**
   * Path part for operation PostPermalinksPath
   */
  static readonly PostGenericPermalinksPath = '/api/v1/cms';
  static readonly PostGenericPermalinksResolvePath =
    PermalinksService.PostGenericPermalinksPath + '/permalinks/resolve';
  static readonly PostGenericPermalinksSearchPath =
    PermalinksService.PostGenericPermalinksPath;

  /**

  */
  postPermanentLinksResolve$Response(params: { permalink: string }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostGenericPermalinksResolvePath,
      'post'
    );
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<PermalinksResponseType>;
          }>;
        })
      );
  }
  /**

  */
  postPermanentLinksSearch$Response(
    pathParams: string,
    params?: {
      page?: number;
      limit?: number;
    },
    body?: {
      only_preferred?: boolean;
      categories?: string[];
      post_status?: string;
      post_parent?: number;
      filter?: string;
      order?: string;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostGenericPermalinksSearchPath +
        '/' +
        pathParams +
        '/_search',
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<PermalinksResponseType>;
          }>;
        })
      );
  }

  /**
   * Path part for operation GetPermalinksDownloadAttachmentsbyMediaId
   */
  static readonly GetPermalinksDownloadAttachmentsbyMediaId =
    PermalinksService.PostGenericPermalinksPath +
    '​/attachments​/download​/{post_id}';
  static attachmentPermalinkPath =
    environment.rootUrl + '/api/v1/cms/attachments/download/';
  getPermalinksDownloadAttachmentsbyMediaId$Response(params: {
    /**
     * Internal ID for identification.
     */
    post_id: number;
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.GetPermalinksDownloadAttachmentsbyMediaId,
      'get'
    );
    if (params) {
      rb.path('post_id', params.post_id, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Path part for operation GetPermalinksDownloadAttachmentsbyMediaId
   */
  static readonly GetPermalinkPostByTypeAndId =
    PermalinksService.PostGenericPermalinksPath;
  getPermalinkPostByTypeAndId$Response(params: {
    /**
     * Internal TYPE for identification.
     * Internal ID for identification.
     */
    post_type: string;
    post_id: number;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.GetPermalinkPostByTypeAndId +
        '/' +
        params.post_type +
        '/' +
        params.post_id,
      'get'
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any>;
        })
      );
  }

  /**
   * Path part for operation PostPermalinksNavigatorsSearchPath
   */
  static readonly PostPermalinksNavigatorsSearchPath =
    PermalinksService.PostGenericPermalinksPath + '/navigators/_search';
  postPermalinksNavigatorsSearch$Response(params?: {
    page: number;
    limit: number;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<NavigatorsPermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostPermalinksNavigatorsSearchPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<NavigatorsPermalinksResponseType>;
          }>;
        })
      );
  }

  /**
   * Path part for operation GetPermalinksNavigatorsById
   */
  static readonly GetPermalinksNavigatorsByIdPath =
    PermalinksService.PostGenericPermalinksPath + '/navigators/';
  getPermalinksNavigatorsById(params: { nav_id: number }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<NavigatorsPermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.GetPermalinksNavigatorsByIdPath + params.nav_id,
      'get'
    );
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<NavigatorsPermalinksResponseType>;
          }>;
        })
      );
  }

  putPermaLinks(
    params: {
      post_type: string;
      post_id: number;
    },
    body: PostCreatePermalinksBody
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostGenericPermalinksSearchPath +
        '/' +
        params.post_type +
        '/' +
        params.post_id,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    rb.body(body, 'application/json');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<PermalinksResponseType>;
          }>;
        })
      );
  }

  postCreatePermaLinks(
    params: { post_type: string },
    body: PostCreatePermalinksBody
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostGenericPermalinksSearchPath +
        '/' +
        params.post_type +
        '/_create',
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    rb.body(body, 'multipart/form-data');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<PermalinksResponseType>;
          }>;
        })
      );
  }

  deletePermaLinks(params: { post_type: string; post_id: number }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<PermalinksResponseType>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PermalinksService.PostGenericPermalinksSearchPath +
        '/' +
        params.post_type +
        '/' +
        params.post_id,
      'delete'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<PermalinksResponseType>;
          }>;
        })
      );
  }
}
