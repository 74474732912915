import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { transparentAdministrationResponseType1 } from 'src/app/shared/messageType';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class TransparentAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }
  static readonly VisitCounterPath = '/api/v1/generic_document/_counter';
  visitcounter() {
    let fullUrl =
      this.rootUrl + TransparentAdministrationService.VisitCounterPath;
    return this.http.get<any>(fullUrl);
  }

  parentDataList: any;
  // questo event emitter viene utilizzato per resettare il breadcrumb quando si esce da una pagina renderizzata da transparent administration detail
  onTransparentAdministationDetailDestroy: EventEmitter<any> =
    new EventEmitter();

  /**
   * Path part for operation PostTransparentAdministrationPath
   */
  static readonly PostGenericDocumentPath = '/api/v1/generic_document';
  static readonly PostGenericPath = '/api/v1/generic';
  static readonly PostGenericDocumentDownloadPath =
    TransparentAdministrationService.PostGenericDocumentPath + '/download';
  static readonly PostGenericDocumentGrantPath =
    TransparentAdministrationService.PostGenericDocumentPath + '/grant';
  static readonly PostGenericAllCategoriesPath =
    TransparentAdministrationService.PostGenericPath + '/all_categories';

  /**

  */
  postTransparentAdministrationDetails$Response(params?: {
    CodicePadre?: string;
    NomeFile?: string;
    published?: string;
    order?: string;
    filter?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<transparentAdministrationResponseType1>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TransparentAdministrationService.PostGenericDocumentPath,
      'post'
    );
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<transparentAdministrationResponseType1>;
          }>;
        })
      );
  }

  /**

   */
  postTransparentAdministrationDetails(params?: {
    CodicePadre?: string;
    NomeFile?: string;
    published?: string;
    order?: string;
    filter?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<transparentAdministrationResponseType1>;
  }> {
    return this.postTransparentAdministrationDetails$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<transparentAdministrationResponseType1>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<transparentAdministrationResponseType1>;
          }
      )
    );
  }

  /*postTransparentAdministrationDetailsDownload$Response(NomeFile?: string): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> }>> {

    const rb = new RequestBuilder(this.rootUrl, TransparentAdministrationService.PostGenericDocumentDownloadPath, 'post');

    if (NomeFile) {
      rb.body({ "NomeFile": NomeFile }, 'application/json');
    }


    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> }>;
      }),

    );
  }

  postTransparentAdministrationDetailsDownload(NomeFile?: string): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> }> {

    return this.postTransparentAdministrationDetailsDownload$Response(NomeFile).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<downloadedFilesResponseType1> })
    );
  } */

  postTransparentAdministrationGrant$Response(params?: {
    email?: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<transparentAdministrationResponseType1>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TransparentAdministrationService.PostGenericDocumentGrantPath,
      'post'
    );
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  /**

   */
  postTransparentAdministrationGrant(params?: { email?: string }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postTransparentAdministrationGrant$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<transparentAdministrationResponseType1>;
          }
      )
    );
  }

  /**
 
   */
  postTransparentAdministrationAllCategories$Response(): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TransparentAdministrationService.PostGenericAllCategoriesPath,
      'post'
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  /**

   */
  postTransparentAdministrationAllCategories(): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.postTransparentAdministrationAllCategories$Response().pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }
}
