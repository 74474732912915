import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoaderService } from 'src/app/api/services/loader.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit, OnDestroy {
  loader: boolean = true;
  loadingSubscription: Subscription;
  constructor(
    private loadingService: LoaderService
  ) { }

  ngOnInit() {
    this.loadingSubscription = this.loadingService.loadingStatus.pipe(debounceTime(200)
    ).subscribe((value) => {
      this.loader = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
