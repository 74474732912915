<div class="cards" *ngIf="data">
  <p class="role" role="region" aria-label="ruolo">{{ data.role }}</p>
  <p class="title" role="region" aria-label="nome">{{ data.name }}</p>
  <a
    href="mailto:{{ data.email }}"
    class="email"
    role="region"
    aria-label="email"
    >{{ data.email }}</a
  >
  <a
    *ngIf="data.pec"
    href="mailto:{{ data.pec }}"
    class="email"
    role="region"
    aria-label="pec"
    >{{ data.pec }}</a
  >
  <p class="segretary" role="region" aria-label="segreteria">
    {{ "COMPANY.SECRETARIAT" | translate }}
  </p>
  <div style="display: flex">
    <p
      class="text"
      style="margin-right: 0.3125em"
      role="region"
      aria-label="Telefono"
    >
      T
    </p>
    <a
      href="tel:{{ data.phone_number }}"
      class="text"
      role="region"
      aria-label="numero di telefono"
      >{{ data.phone_number }}</a
    >
  </div>
</div>
