import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-news-card',
  templateUrl: './news_card.component.html',
  styleUrls: ['./news_card.component.scss'],
})
export class NewsCardComponent implements OnInit {
  public sanitizerHtml: any;
  constructor( private sanitizer: DomSanitizer) {
    this.sanitizerHtml = sanitizer.bypassSecurityTrustHtml;
  }

  @Input() data;

  routeLinkText = '';

  ngOnInit(): void {}

  onImgError(event) {
    event.target.src = '../../assets/policlinico.png';
  }
}
