import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-in-evidence-body',
  templateUrl: './in-evidence-body.component.html',
  styleUrls: ['./in-evidence-body.component.scss']
})
export class InEvidenceBodyComponent implements OnInit {

  @Input() titleText: '';
  @Input() bodyList: [];

  @Input() typology: '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToDetail(data) {
    const currentUrl = '/azienda/comitato-etico/' + data.ID;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {

      this.router.navigateByUrl(currentUrl.toString(), {
        state: data
      });
    });

  }

  goToDetailProcedure(data){
    const currentUrlProcedure = '/azienda/procedure-aziendali/' + data.ID;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {

      this.router.navigateByUrl(currentUrlProcedure.toString(), {
        state: data
      });
    });
  }

}
