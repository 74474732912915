<div id="accordionDiv1" class="collapse-div" role="tablist">
  <div *ngFor="let element of dataSource">
    <div class="collapse-header" id="headingA{{ addID }}{{ element.id }}">
      <button data-toggle="collapse" [attr.data-target]="
          '#' + addID + (element.ID ? element.ID : element.id)
        " aria-expanded="false" [attr.aria-controls]="addID + (element.ID ? element.ID : element.id)">
        <p class="text_title">
          {{ element.titolo ? element.titolo : element.Titolo }}
        </p>
      </button>
    </div>
    <div [id]="addID + (element.ID ? element.ID : element.id)" class="collapse" role="tabpanel" [attr.aria-labelledby]="
        '#' + addID + (element.ID ? element.ID : element.id)
      " data-parent="#accordionDiv1">
      <div class="collapse-body">
        <div class="element_row" *ngFor="let obj of createInformation(element)">
          <p class="element_title">{{ obj.title | translate }}:</p>
          <p class="element_text">
            {{ obj.value }}
          </p>
        </div>
        <div class="buttonWrapper">
          <button (click)="downloadFile(element)" *ngIf="hasAttachment" class="btn attached_items">
            <div class="">
              <svg-icon-sprite src="../../../assets/sprite.svg#it-download">
              </svg-icon-sprite>
            </div>
            <p class="attached_items">
              {{ "ALBO_PRETORIO.ATTACHMENTS" | translate }}
            </p>
          </button>

          <button (click)="showDetail(element)" *ngIf="hasDetail" class="btn btn-primary">
            {{ "ALBO_PRETORIO.DETAILS" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>