<div class="shared_detail_body">
    <div class="shared_index_column">
        <div class="menu-wrapper">
            <div class="link-list-wrapper">
                <p class="shared_index_column_title">{{"RISK_MANAGER.DETAIL.PAGE_INDEX" |
                    translate}}</p>
                <ul class="link-list">
                    <li class="nav-item">
                        <a class="nav-link active" (click)='redirectTo("unita")'>
                            <p class="shared_index_column_element_main">{{parentData.Nome}}</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="shared_information_column">

        <p class="shared_paragraph_alternative_title" id="unita">{{parentData.Nome}}</p>
        <div *ngIf="subDetailInformation && subDetailInformation.length > 0">
            <div class="row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-2">
                <ng-container *ngFor="let element of subDetailInformation">
                    <app-info-card [minHeight]="'200px'" [data]="element" [title]="element.Nome"
                        [postTypeData]="risk_manager" [detailText]="'BUTTON.READ_MORE'"
                        (actionFunction)="goToDetail($event)" [showDetail]="false">
                    </app-info-card>
                </ng-container>
            </div>
        </div>

        <p *ngIf="detailInformation && detailInformation.length > 0" class="shared_paragraph_title" id="unita">
            {{"RISK_MANAGER.DETAIL.DOCUMENTS" |
            translate}}</p>

        <!-- ALL -->
        <app-main-all-body *ngIf="detailInformation && detailInformation.length > 0" id="risk_manager_detail"
            [typology]="'risk_manager_detail'" [mainTitle]="''" [bodyList]="detailInformation">
        </app-main-all-body>
        <!-- END ALL -->

        <div
            *ngIf="!((detailInformation && detailInformation.length > 0) || (subDetailInformation && subDetailInformation.length > 0))">
            <p class="shared_text m-b-5"> {{"MESSAGE.NO_RESULTS" | translate}} </p>
        </div>
    </div>
</div>