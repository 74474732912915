<div class="it-example-modal">
  <div
    class="modal alert-modal"
    tabindex="-1"
    role="dialog"
    id="genericInfoModal"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <svg class="icon" *ngIf="type === 'info'">
            <use xlink:href="../../../assets/sprite.svg#it-info-circle"></use>
          </svg>
          <svg *ngIf="type === 'error'" class="icon icon-danger">
            <use xlink:href="../../../assets/sprite.svg#it-close-circle"></use>
          </svg>
          <svg *ngIf="type === 'success'" class="icon icon-success">
            <use xlink:href="../../../assets/sprite.svg#it-check-circle"></use>
          </svg>
          <h5 class="modal-title" style="padding-top: 4px">
            {{ titleHeader }}
          </h5>
        </div>
        <div class="modal-body">
          <p>
            <b> {{ bodyText }} </b>
          </p>
        </div>
        <div (click)="doAction1()" class="modal-footer">
          <button
            data-dismiss="modal"
            class="btn btn-primary btn-sm"
            type="button"
          >
            {{ "BUTTON.OK" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
