import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter, } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttiNotificaService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly PostSearchPath = '/api/v1/atti_not/_search';
  static readonly PostInsertPath = '/api/v1/atti_not/_insert';
  static readonly PutUpdatePath = '/api/v1/atti_not/_update';
  static readonly PostDeletePath = '/api/v1/atti_not/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/atti_not/_update_publish';

  /*
  * SEARCH
  */
  postSearchAttiNotifica$Response(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, AttiNotificaService.PostSearchPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }

  postSearchAttiNotifica(
    body?: {
      type?: number,
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postSearchAttiNotifica$Response(body, params).pipe(
      map((r: StrictHttpResponse<{
        'rowCount'?: number, 'message'?: string | null,
        'rows'?: Array<any>
      }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
  * INSERT
  */
  postInsertAttiNotifica$Response(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, AttiNotificaService.PostInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  postInsertAttiNotifica(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postInsertAttiNotifica$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }


  /*
   * UPDATE
   */
  putUpdateAttiNotifica$Response(params?: {
    atti_id: string;
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, AttiNotificaService.PutUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  putUpdateAttiNotifica(params?: {
    atti_id: string;
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.putUpdateAttiNotifica$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }


  /*
 * DELETE
 */
  DeleteAttiNotifica$Response(params?: {
    atti_id: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, AttiNotificaService.PostDeletePath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  DeleteAttiNotifica(params?: {
    atti_id: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteAttiNotifica$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
* UPDATE PUBLISH
*/
  UpdatePublishAttiNotifica$Response(params?: {
    atti_id: string,
    published: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, AttiNotificaService.PutUpdatePublishPath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  UpdatePublishAttiNotifica(params?: {
    atti_id: string,
    published: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.UpdatePublishAttiNotifica$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

}
