import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends BaseService {

  homeCompletedResult$: Observable<any>;
  homeCompletedSubject = new Subject<any>();

  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
    this.homeCompletedResult$ = this.homeCompletedSubject.asObservable();
  }


  static readonly PostSearchPath = '/api/v1/banner/_search';

  static readonly PostInsertPath = '/api/v1/banner/_insert';
  static readonly PutUpdatePath = '/api/v1/banner/_update';
  static readonly PostDeletePath = '/api/v1/banner/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/banner/_update_publish';


  /*
   * SEARCH
   */

  postSearchHome(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + HomeService.PostSearchPath;
    return this.http.post<any>(fullUrl, { ...body, ...params });
  }

  /*
   * INSERT
   */

  postInsertHome$Response(params?: {
    Titolo?: string;
    Source?: string;
    Link?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, HomeService.PostInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );

  }
  postInsertHome(params?: {
    Titolo?: string;
    Source?: string;
    Link?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postInsertHome$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
* UPDATE
*/
  putUpdateHome$Response(params?: {
    ID: string;
    Titolo?: string;
    Source?: string;
    Link?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, HomeService.PutUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      })
    );
  }
  putUpdateHome(params?: {
    ID: string;
    Titolo?: string;
    Source?: string;
    Link?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.putUpdateHome$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }



  /*
* DELETE
*/
  deleteHome$Response(params?: {
    ID: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, HomeService.PostDeletePath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  deleteHome(params?: {
    ID: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.deleteHome$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /*
* UPDATE PUBLISH
*/
  updatePublishHome$Response(params?: {
    ID: string,
    published: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, HomeService.PutUpdatePublishPath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  updatePublishHome(params?: {
    ID: string,
    published: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.updatePublishHome$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }
}
