import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-auth-banner',
  templateUrl: './auth-banner.component.html',
  styleUrls: ['./auth-banner.component.scss'],
})
export class AuthBannerComponent implements OnInit {
  @Output() logout = new EventEmitter();
  user = localStorage.getItem('user');
  constructor() {}

  ngOnInit(): void {}

  doLogout() {
    this.logout.emit();
  }

  getName() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.nome + ' ' + user?.cognome;
  }
}
