<div
  class="card card-bg"
  (click)="!isLinkVisible() ? callAction() : null"
  [ngStyle]="{ cursor: isLinkVisible() ? 'auto' : 'pointer' }"
>
  <div class="card-body" [style.height]="minHeight">
    <div class="wrapper">
      <h5 class="card-title" role="region" [attr.aria-label]="title">
        {{ title | translate }}
      </h5>
      <div class="it-right-zone" *ngIf="!isLinkVisible()">
        <svg-icon-sprite
          *ngIf="!isArrowHidden"
          src="../../../assets/sprite.svg#it-chevron-right"
          classes="icon icon-md icon-white"
        ></svg-icon-sprite>
      </div>
    </div>

    <a
      class="read-more"
      role="region"
      aria-label="Vai alla pagina"
      (click)="callAction()"
      *ngIf="isLinkVisible()"
    >
      <span class="text">{{ "BUTTON.GO_TO_PAGE" | translate }}</span>
    </a>
  </div>
  <div class="bottom-line"></div>
</div>
